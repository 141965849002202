export enum GtmEvent {
  virtual_page_view = 'virtual_page_view',
  login = 'login',
  sign_up = 'sign_up',
  tutorial_begin = 'tutorial_begin',
  tutorial_complete = 'tutorial_complete',
  search = 'search',
  gs_step_retailer = 'gs_step_retailer',
  gs_step_supplier = 'gs_step_supplier',
  cancellation_by_reasons = 'cancellation_by_reasons',
  filter = 'filter',
  catalog_create = 'catalog_create',
  catalog_upload_start = 'catalog_upload_start',
  product_view = 'product_view',
  view_item = 'view_item',
  storefront_view = 'storefront_view',
  web_vitals = 'web_vitals',
  product_to_import_list_recommendation = 'product_to_import_list_recommendation',
}
