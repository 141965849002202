<div fxLayout="column" fxLayoutGap="10px">
  <div
    class="position-relative w-100p"
    [fxLayout]="flexDirection"
    fxLayout.lt-sm="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="32px"
    *ngIf="paymentAccount; else noPaymentAccount"
  >
    <mat-card class="p-24 payment-provider-wrapper" *ngIf="stripeExistsInCountry" fxFlex="1 0 0">
      <app-stripe-connect
        [redirectURL]="redirectStripeUrl"
        (stripeConnected)="stripeConnectStatusEvent($event)"
        [countryRequireTaxSettings]="countryRequireTaxSettings"
      >
      </app-stripe-connect>
    </mat-card>
    <mat-card class="p-24 payment-provider-wrapper" fxFlex="1 0 0">
      <app-login-with-paypal
        *ngIf="paypalConnectStatus && paymentAccount?.paypalConnect?.version === 'v1'; else paypalOnboard"
        [redirectUrl]="redirectPaypalUrl"
        (paypalConnected)="paypalConnectStatusEvent($event)"
      >
      </app-login-with-paypal>
    </mat-card>
  </div>
  <ng-container *ngIf="paypalConnectStatus || stripeConnectStatus">
    <mat-card class="p-24" *ngIf="showTaxSettingsCard">
      <app-supplier-tax-settings (show)="showTaxSettingsCard = $event"></app-supplier-tax-settings>
    </mat-card>
  </ng-container>
</div>

<ng-template #paypalOnboard>
  <app-paypal-onboard
    #paypalOnboard
    [redirectUrl]="redirectPaypalUrl"
    (paypalConnected)="paypalConnectStatusEvent($event)"
    [countryRequireTaxSettings]="countryRequireTaxSettings"
  ></app-paypal-onboard>
</ng-template>

<ng-template #noPaymentAccount>
  <app-no-search-results
    icon="info"
    titleKey="BILLING.SUBSCRIPTION.NO_PAYMENT_ACCOUNT.TITLE"
    descriptionKey="BILLING.SUBSCRIPTION.NO_PAYMENT_ACCOUNT.DESCRIPTION"
    [button]="{
      type: 'inner_redirect',
      textKey: 'BILLING.SUBSCRIPTION.NO_PAYMENT_ACCOUNT.BUTTON',
      buttonClass: 'syncee-blue-button',
      url: '/supplier/storefront-settings/company-billing'
    }"
  ></app-no-search-results>
</ng-template>
