import { Action } from '@ngrx/store';
import { Catalog, CatalogFilter } from '../../vo/catalog';
import { RecentlyAddedProduct } from './rcatalogs.reducer';

export enum RCatalogsActionTypes {
  GET_RCATALOGS_START = '[RCatalogs] Get RCatalogs Start',
  GET_RCATALOGS_SUCCESS = '[RCatalogs] Get RCatalogs Success',
  setCatalogs = '[RCatalogs] setCatalogs',
  addCatalog = '[RCatalogs] addCatalog',
  clearCatalogs = '[RCatalogs] clearCatalogs',
  deleteCatalog = '[RCatalogs] deleteCatalog',
  setSelectedCatalog = '[RCatalogs] setSelectedCatalog',
  clearSelectedCatalog = '[RCatalogs] clearSelectedCatalog',
  addRecentlyAddedProducts = '[RCatalogs] addRecentlyAddedProducts',
  clearRecentlyAddedProducts = '[RCatalogs] clearRecentlyAddedProducts',
  updateCatalogFilter = '[RCatalogs] updateCatalogFilter',
}

export class GetCatalogsStartAction implements Action {
  readonly type = RCatalogsActionTypes.GET_RCATALOGS_START;
}

export class GetCatalogsSuccessAction implements Action {
  readonly type = RCatalogsActionTypes.GET_RCATALOGS_SUCCESS;

  constructor(public payload: Catalog[]) {}
}

export class SetCatalogsAction implements Action {
  readonly type = RCatalogsActionTypes.setCatalogs;

  constructor(public payload: Catalog[]) {}
}

export class AddCatalogAction implements Action {
  readonly type = RCatalogsActionTypes.addCatalog;

  constructor(public payload: Catalog) {}
}

export class ClearCatalogsAction implements Action {
  readonly type = RCatalogsActionTypes.clearCatalogs;
}

export class DeleteCatalogAction implements Action {
  readonly type = RCatalogsActionTypes.deleteCatalog;

  constructor(public payload: number) {}
}

export class SetSelectedCatalogAction implements Action {
  readonly type = RCatalogsActionTypes.setSelectedCatalog;

  constructor(public payload: Catalog) {}
}

export class ClearSelectedCatalogAction implements Action {
  readonly type = RCatalogsActionTypes.clearSelectedCatalog;
}

export class AddRecentlyAddedProductsAction implements Action {
  readonly type = RCatalogsActionTypes.addRecentlyAddedProducts;

  constructor(public payload: RecentlyAddedProduct[]) {}
}

export class ClearRecentlyAddedProductsAction implements Action {
  readonly type = RCatalogsActionTypes.clearRecentlyAddedProducts;
}

export class UpdateCatalogFilterAction implements Action {
  readonly type = RCatalogsActionTypes.updateCatalogFilter;

  constructor(public payload: { catalogId: number; filter: CatalogFilter[] }) {}
}

export type RetailerCatalogsActionsUnion =
  | SetCatalogsAction
  | GetCatalogsStartAction
  | GetCatalogsSuccessAction
  | AddCatalogAction
  | ClearCatalogsAction
  | DeleteCatalogAction
  | SetSelectedCatalogAction
  | ClearSelectedCatalogAction
  | AddRecentlyAddedProductsAction
  | ClearRecentlyAddedProductsAction
  | UpdateCatalogFilterAction;
