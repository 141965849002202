<div class="choice-list-container">
  <div class="search-bar-container" *ngIf="hasSearch">
    <app-searchbar-custom
      [debounceTime]="500"
      [placeholder]="'FILTER_COMMON.SELECTOR.SEARCH_LABELS.SEARCH' | translate"
      (searchTermChange)="searchTerm$.next($event)"
    ></app-searchbar-custom>
  </div>
  <ng-container *ngIf="!!showAllOption">
    <button
      *ngIf="!radio; else radioAllButton"
      mat-flat-button
      fxLayout="row"
      fxLayoutAlign="start center"
      class="choice-button"
      (click)="selectedChoiceChanged.emit({ value: null, label: null })"
    >
      <div fxLayout="row" fxLayoutAlign="start">
        {{ 'RETAILER_MANAGE_ORDERS.LISTING.FILTER_ROW.ALL' | translate }}
      </div>
    </button>
  </ng-container>
  <ng-container *ngIf="!radio; else radioButtonChoices">
    <button
      *ngFor="let choice of choices"
      mat-flat-button
      color="white"
      fxLayout="row"
      fxLayoutAlign="start center"
      class="choice-button"
      (click)="selectedChoiceChanged.emit({ value: choice.value, label: choice.label })"
    >
      <div fxLayout="row" fxLayoutAlign="space-between" class="w-100-p">
        <div>{{ choice.translate ? (choice.label | translate) : choice.label }}</div>
        <app-badge-custom
          style="line-height: initial; margin-left: 8px"
          *ngIf="choice.hasBadge"
          [badgeSize]="'sm'"
          [text]="choice.badgeLabelKey | translate"
          [textColor]="choice.badgeColorKey | statusTextColor"
          [bgColor]="choice.badgeColorKey | statusColor"
        >
        </app-badge-custom>
      </div>
    </button>
  </ng-container>
  <div *ngIf="isLoading" fxLayout="row" fxLayoutAlign="center center" class="px-16 py-12">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
  <div *ngIf="!!fetcher && hasMore" fxLayout="row" fxLayoutAlign="center center" class="load-more-btn w-100-p">
    <button mat-flat-button class="syncee-white-button w-100-p" (click)="handleLoadMore()" [disabled]="isLoading">
      {{ 'FILTER.LOAD_MORE' | translate }}
    </button>
  </div>
  <ng-content></ng-content>
</div>

<ng-template #radioButtonChoices>
  <mat-radio-group fxLayout="column" fxLayoutAlign="start start">
    <mat-radio-button
      *ngFor="let choice of choices"
      class="choice-radio-button"
      [value]="choice.value"
      (click)="selectedChoiceChanged.emit({ value: choice.value, label: choice.label })"
      [checked]="isEqual(selectedChoice, choice.value)"
    >
      <div fxLayout="row" fxLayoutAlign="space-between" class="w-100-p">
        <div>{{ choice.translate ? (choice.label | translate) : choice.label }}</div>
        <app-badge-custom
          style="line-height: initial; margin-left: 8px"
          *ngIf="choice.hasBadge"
          [badgeSize]="'sm'"
          [text]="choice.badgeLabelKey | translate"
          [textColor]="choice.badgeColorKey | statusTextColor"
          [bgColor]="choice.badgeColorKey | statusColor"
        >
        </app-badge-custom>
      </div>
    </mat-radio-button>
  </mat-radio-group>
</ng-template>

<ng-template #radioAllButton>
  <mat-radio-button
    class="all-radio-button"
    [value]="null"
    (click)="selectedChoiceChanged.emit({ value: null, label: null })"
    [checked]="selectedChoice === null"
  >
    <div fxLayout="row" fxLayoutAlign="start">
      {{ 'RETAILER_MANAGE_ORDERS.LISTING.FILTER_ROW.ALL' | translate }}
    </div>
  </mat-radio-button>
</ng-template>
