import { Component, OnInit } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { SaveReviewReplyVo } from '../../model/save-review-reply.vo';
import { SynceeReviewDialogService } from '../../service/syncee-review-dialog.service';
import { SetupGuideService } from '../../../setup-guide/service/setup-guide.service';
import { SnippetEnum } from '../../../setup-guide/enums/snippet-enums';
import { take, tap } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import { SelectRatingComponent } from '../select-rating/select-rating.component';
import { SelectPlatformComponent } from '../select-platform/select-platform.component';
import { SelectFeedbackComponent } from '../select-feedback/select-feedback.component';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarComponent } from '../../../../shared/components/avatar/avatar.component';
import { CustomDialogWrapperComponent } from '../../../../shared/components/dialogs/custom-dialog-wrapper/custom-dialog-wrapper.component';

@Component({
  selector: 'app-syncee-review-dialog',
  templateUrl: './syncee-review-dialog.component.html',
  styleUrls: ['./syncee-review-dialog.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    FlexModule,
    MatDialogModule,
    MatIconModule,
    NgSwitch,
    SelectRatingComponent,
    NgSwitchCase,
    SelectPlatformComponent,
    SelectFeedbackComponent,
    NgTemplateOutlet,
    NgIf,
    TranslateModule,
    AvatarComponent,
    CustomDialogWrapperComponent,
  ],
})
export class SynceeReviewDialogComponent implements OnInit {
  selectedRating: number = null;
  selectedFeedback: SaveReviewReplyVo;
  currentPhase: 'rating' | 'platform' | 'feedback' | 'done' = 'rating';

  constructor(
    private dialogRef: MatDialogRef<SynceeReviewDialogComponent>,
    private reviewDialogService: SynceeReviewDialogService,
    private setupGuideService: SetupGuideService
  ) {
    this.dialogRef.addPanelClass('custom-modal-container');
  }

  ngOnInit(): void {}

  handleRatingChange(value: number): void {
    this.selectedRating = value;
  }

  handleButtonClick(): void {
    switch (this.currentPhase) {
      case 'rating':
        this.switchPhaseByRating(this.selectedRating);
        break;
      case 'feedback':
        this.submitReview();
        break;
      case 'done': {
        this.dialogRef.close(true);
      }
    }
  }

  private submitReview(): void {
    this.reviewDialogService
      .saveFeedback(this.selectedFeedback)
      .pipe(tap(() => this.completeSetupGuideReviewStep()))
      .subscribe((): void => {
        this.currentPhase = 'done';
      });
  }

  handleFeedBackSelection(feedback: SaveReviewReplyVo): void {
    this.selectedFeedback = feedback;
  }

  handlePlatformSelect(platform: string): void {
    this.reviewDialogService
      .savePlatform(platform.toUpperCase(), this.selectedRating)
      .pipe(tap(() => this.completeSetupGuideReviewStep()))
      .subscribe(() => {
        this.currentPhase = 'done';
      });
  }

  completeSetupGuideReviewStep(): void {
    this.setupGuideService
      .observeIfStepIsCompleted(SnippetEnum.REVIEW)
      .pipe(take(1))
      .subscribe((isCompleted) => {
        if (!isCompleted) {
          this.setupGuideService.setCompletedStep(SnippetEnum.REVIEW);
        }
      });
  }

  private switchPhaseByRating(rating: number): void {
    if (rating > 4) {
      this.currentPhase = 'platform';
    } else {
      this.currentPhase = 'feedback';
    }
  }
}
