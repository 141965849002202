<ng-container *ngIf="!error; else errorMarkup">
  <div *conditionLoading="!isCatalogLoading" fxLayout="column" fxLayoutGap="20px">
    <app-custom-panel>
      <div fxLayout="column" fxLayoutGap="15px">
        <div class="typography-body-large-2">{{ 'IMPORT_LIST.RETAILER_IMPORT_LIST.SET_UP_PRICING' | translate }}</div>
        <app-import-list-pricing
          *conditionLoading="!isPricingLoading"
          [collections]="collections"
          [pricing]="pricing"
          (submitValue)="setPricing($event)"
          [ecomCurrency]="ecomCurrency"
          [hasSaveButton]="false"
          [priceType]="priceType"
        ></app-import-list-pricing>
      </div>
    </app-custom-panel>
    <app-custom-panel>
      <app-component-header
        [title]="'IMPORT_LIST.ROUNDING.TITLE'"
        [description]="priceType | getRetailerPriceRoundingDescription"
      >
      </app-component-header>
      <app-import-list-price-rounding
        *conditionLoading="!isRoundingLoading"
        [roundingRule]="roundingRule"
        (submitValue)="setRounding($event)"
        [ecomCurrency]="ecomCurrency"
        [hasSaveButton]="false"
      ></app-import-list-price-rounding>
    </app-custom-panel>
  </div>
</ng-container>

<ng-template #errorMarkup>
  <app-error-message></app-error-message>
</ng-template>
