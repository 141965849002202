<button
  mat-icon-button
  class="syncee-square-icon-button filter-sort-button"
  [ngClass]="{ 'filter-sort-button-active': !!selectedSort }"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  (click)="dropdownOpen = !dropdownOpen"
>
  <mat-icon class="s-20"> swap_vert </mat-icon>
</button>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  cdkConnectedOverlayPush
  [cdkConnectedOverlayPositions]="positionPairs"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="dropdownOpen"
  (backdropClick)="dropdownOpen = false"
>
  <app-index-table-filter-dropdown [showAllOption]="false">
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px" class="p-12">
      <mat-radio-group fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px">
        <mat-radio-button
          *ngFor="let choice of choices"
          class="filter-sort-radio-button"
          [value]="choice.value"
          (click)="handleSortByChange(choice.value)"
          [checked]="selectedSort?.sortBy === choice.value"
        >
          <div class="w-100-p">{{ choice.label | translate }}</div>
        </mat-radio-button>
      </mat-radio-group>
      <app-custom-divider class="w-100-p"></app-custom-divider>
      <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px" class="w-100-p">
        <div
          class="sort-dir-button cursor-pointer"
          (click)="handleSortDirChange('ASC')"
          [ngClass]="{ active: sortDir === 'ASC' }"
        >
          <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
            <mat-icon class="s-20">arrow_upward</mat-icon>
            <span>{{ 'FILTER_COMMON.SELECTOR.SORT.ASC' | translate }}</span>
          </div>
        </div>
        <div
          class="sort-dir-button cursor-pointer"
          (click)="handleSortDirChange('DESC')"
          [ngClass]="{ active: sortDir === 'DESC' }"
        >
          <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
            <mat-icon class="s-20">arrow_downward</mat-icon>
            <span>{{ 'FILTER_COMMON.SELECTOR.SORT.DESC' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
  </app-index-table-filter-dropdown>
</ng-template>
