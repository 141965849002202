<div class="outer-container" [ngClass]="wrapperClass">
  <div
    class="inner-container"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutAlign.lt-md="center stretch"
    [ngClass]="innerWrapperClass"
  >
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="28px" class="white-500-fg cta-header-text">
      <h2 [ngClass]="'typography-headline-2 mt-0'" [ngClass.lt-md]="'typography-headline-3 mt-0 centered-text'">
        {{ title }}
      </h2>
      <div
        *ngIf="!!descriptionInnerHTML && !descriptionTemplateRef"
        class="typography-body-3 cta-description"
        [innerHTML]="descriptionInnerHTML | sanitizeHTML"
      ></div>
      <ng-container *ngIf="!!descriptionTemplateRef" [ngTemplateOutlet]="descriptionTemplateRef"></ng-container>
      <button
        mat-button
        class="syncee-cta-button"
        ngClass.lt-sm="w-100p"
        [routerLink]="redirectURL"
        [queryParams]="queryParams"
        [state]="skipFuseUpdate"
        (click)="!!callback && callback()"
      >
        {{ buttonText }}
      </button>
    </div>
  </div>
</div>
