import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SupplierGatewayService } from '../../../../service/suppliers/supplier-gateway.service';
import { ShippingType } from '../../../../vo/supplier/shipping-type';
import { ShippingDetailsPreferredComponent } from '../../shipping-details-preferred/shipping-details-preferred.component';

@Component({
  selector: 'app-shipping-details-dialog',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatDialogModule, MatIconModule, ShippingDetailsPreferredComponent],
  templateUrl: './shipping-details-dialog.component.html',
  styleUrls: ['./shipping-details-dialog.component.scss'],
})
export class ShippingDetailsDialogComponent implements OnInit {
  isAutoOrder: Observable<boolean>;
  taxIncludedToShipping: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ShippingDetailsDialogData,
    private supplierGatewayService: SupplierGatewayService
  ) {}

  ngOnInit(): void {
    this.isAutoOrder = this.getAutoOrder();
    this.getTaxSettings();
  }

  private getTaxSettings(): void {
    this.supplierGatewayService.getSupplierTaxSettings(this.data.userId).subscribe((elem) => {
      this.taxIncludedToShipping = !elem.shippingHasTax;
    });
  }

  private getAutoOrder(): Observable<boolean> {
    return this.supplierGatewayService.getAutoOrders([this.data.userId]).pipe(
      map((result) => {
        if (isEmpty(result)) {
          return false;
        } else {
          return result[0].automate;
        }
      })
    );
  }
}

export interface ShippingDetailsDialogData {
  userId: number;
  isWorldWide: boolean;
  mainWarehouseLocation: string;
  shippingType: ShippingType;
  supplierCurrency: string;
  taskId?: number;
}
