import { ConnectionPositionPair, OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { CustomDividerComponent } from '../../custom-divider/custom-divider.component';
import { Choice } from '../../filter-factory/filter-factory.component';
import { TableSort } from '../../index-table-search-group/index-table-search-group.component';
import { IndexTableFilterDropdownComponent } from '../index-table-filter-dropdown/index-table-filter-dropdown.component';

@Component({
  selector: 'app-index-table-sort',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    OverlayModule,
    IndexTableFilterDropdownComponent,
    MatRadioModule,
    FlexLayoutModule,
    TranslateModule,
    CustomDividerComponent,
  ],
  templateUrl: './index-table-sort.component.html',
  styleUrls: ['./index-table-sort.component.scss'],
})
export class IndexTableSortComponent implements OnInit {
  @Input() choices: Pick<Choice<TableSort['sortBy']>, 'label' | 'value'>[];
  @Output() selectedSortChange: EventEmitter<TableSort> = new EventEmitter<TableSort>();

  selectedSort: TableSort;
  dropdownOpen = false;
  sortDir: TableSort['sortDir'] = 'ASC';

  positionPairs: ConnectionPositionPair[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
    },
  ];

  constructor() {}

  ngOnInit(): void {
    this.selectedSort = {
      sortBy: this.choices?.[0]?.value,
      sortDir: 'ASC',
    };
  }

  handleSortDirChange(sortDir: TableSort['sortDir']): void {
    this.sortDir = sortDir;

    if (this.selectedSort?.sortBy) {
      this.selectedSort = {
        sortBy: this.selectedSort.sortBy,
        sortDir: sortDir,
      };

      this.selectedSortChange.emit(this.selectedSort);
    }
  }

  handleSortByChange(sortBy: TableSort['sortBy']): void {
    this.selectedSort = {
      sortBy: sortBy,
      sortDir: this.sortDir,
    };

    this.selectedSortChange.emit(this.selectedSort);
  }
}
