import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { UserService } from './service/user/user.service';
import { InitAppService } from './service/shared/init-app.service';
import { ConfirmationDialogsService } from './shared/components/dialogs/confirmation-dialog/confirmation-dialog.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BootstrapService } from './service/bootstrap/bootstrap.service';
import { MenuManagerService } from './service/menu-manager/menu-manager.service';
import { CustomSvgRegistryService } from './service/cutom-svg-registry/custom-svg-registry.service';
import { InitGtmService } from './service/google-tag-manager/init-gtm.service';
import { RoutingMetatagService } from './service/routing-metatag/routing-metatag.service';
import { onCLS, onFID, onLCP, onTTFB, onINP, type Metric } from 'web-vitals';
import { SearchSessionService } from './service/search-session/search-session.service';
import { PwaService } from './service/pwa/pwa.service';
import { GtmManagerService } from './service/google-tag-manager/gtm-manager.service';
import { WebVitalsAction } from './service/google-tag-manager/actions/web_vitals';
import { WebVitalMetric } from './service/google-tag-manager/web-vital-metric';
import { MARKETPLACE_NAVIGATION } from './navigation/navigation-routes/common/marketplace.navigation';
import { RICH_TEXT_AUDIENCE_TYPES } from './utils/RichTextConstants';

// tslint:disable-next-line: ban-types
declare let fbq: Function;

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [InitGtmService],
})
export class AppComponent implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;
  navigation_general: any;
  navigation_agency: any;
  navigation_global_admin: any;
  navigation_global_admin_as_supplier: any;
  navigation_global_admin_as_agency: any;
  navigation_admin_with_collapse: any;
  navigation_global_admin_as_own_supplier: any;
  navigation_global_admin_as_own_retailer: any;
  isMobile = false;

  commonJsonLD = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: 'Syncee - Global Dropshipping & Wholesale',
    url: `https://${location.hostname}`,
    audience: {
      '@type': 'Audience',
      audienceType: RICH_TEXT_AUDIENCE_TYPES,
    },
    potentialAction: {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: `https://${location.hostname}/${MARKETPLACE_NAVIGATION.DEFAULT_PRODUCTS_PATH}?category=1&search={search_term_string}&searchType=PRODUCTS`,
      },
      'query-input': 'required name=search_term_string',
    },
  };

  private _unsubscribeAll: Subject<void>;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _translateService: TranslateService,
    private _platform: Platform,
    private _userService: UserService,
    private initAppService: InitAppService,
    private router: Router,
    private confirmDialog: ConfirmationDialogsService,
    private route: ActivatedRoute,
    private menuManagerService: MenuManagerService,
    private bootstrapService: BootstrapService,
    private initGtmService: InitGtmService,
    private customSvgRegistry: CustomSvgRegistryService,
    private routingMetaTagService: RoutingMetatagService,
    private searchSessionService: SearchSessionService,
    private gtmManagerService: GtmManagerService,
    private pwaService: PwaService
  ) {
    // this._userService.getUserDetailsForceWithoutNavigate().subscribe((user) => {
    //   if (!isNullOrUndefined(user) && !this.initAppService.isInitializedApp) {
    //     this.initAppService.init(user);
    //   }
    // });
    // Set the main navigation as our current navigation
    // this._fuseNavigationService.setCurrentNavigation(this.navigation);
    // this.authManagerService.emitOpenAuthDialog();
    // Add languages
    this._translateService.addLangs(['en', 'hu', 'de', 'es', 'fr', 'pt', 'zh', 'it']);

    /**
     * ------------------------------------------------------------------
     * ngxTranslate Fix Start
     * ------------------------------------------------------------------
     * If you are using a language other than the default one, i.e. Turkish in this case,
     * you may encounter an issue where some of the components are not actually being
     * translated when your app first initialized.
     *
     * This is related to ngxTranslate module and below there is a temporary fix while we
     * are moving the multi language implementation over to the Angular's core language
     * service.
     **/

    // Set the default language to 'en' and then back to 'tr'.
    // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
    // been selected and there is no way to force it, so we overcome the issue by switching
    // the default language back and forth.

    /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('hu');
         });
         **/

    /**
     * ------------------------------------------------------------------
     * ngxTranslate Fix End
     * ------------------------------------------------------------------
     */

    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add('is-mobile');
      this.isMobile = true;
    }

    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    // Init Google Tag Manager
    this.initGtmService.initGtm();
    this.bootstrapService.init();
    // Init web vitals
    this.webVitals();
    // Subscribe to config changes
    this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
      this.fuseConfig = config;

      // Boxed
      if (this.fuseConfig.layout.width === 'boxed') {
        this.document.body.classList.add('boxed');
      } else {
        this.document.body.classList.remove('boxed');
      }

      // Color theme - Use normal for loop for IE11 compatibility
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.document.body.classList.length; i++) {
        const className = this.document.body.classList[i];

        if (className.startsWith('theme-')) {
          this.document.body.classList.remove(className);
        }
      }

      this.document.body.classList.add(this.fuseConfig.colorTheme);
    });

    this.router.events.subscribe((y: NavigationEnd) => {
      if (y instanceof NavigationEnd) {
        fbq('track', 'PageView');
      }
    });
    this.customSvgRegistry.registerCustomSvgIcons();
    this.routingMetaTagService.subscribeToNavigationEnd();
    this.searchSessionService.initSession();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  private onMetric(metric: Metric): void {
    this.transferWebVitalsIntoGtm(metric);
    this.logWebVitals(metric);
  }

  private logWebVitals(metric: Metric): void {
    console.warn(metric);
  }

  private transferWebVitalsIntoGtm(metric: Metric): void {
    this.gtmManagerService.pushTag(
      new WebVitalsAction({
        web_vital_event_label: metric.id as string,
        web_vital_event_action: metric.name as WebVitalMetric,
        web_vital_event_value:
          metric.name === WebVitalMetric.CLS
            ? Math.round((metric['delta'] as number) * 1000)
            : (metric['delta'] as number),
      })
    );
  }

  private webVitals(): void {
    onLCP((metric) => this.onMetric(metric));
    onFID((metric) => this.onMetric(metric));
    onCLS((metric) => this.onMetric(metric));
    onTTFB((metric) => this.onMetric(metric));
    onINP((metric) => this.onMetric(metric));
  }
}
