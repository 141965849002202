import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { fuseAnimations } from '@fuse/animations';
import { TranslateModule } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { Choice, TableViewItem } from '../filter-factory/filter-factory.component';
import { IndexTableSortComponent } from '../index-table-filter/index-table-sort/index-table-sort.component';
import { SearchbarCustomComponent } from '../searchbar-custom/searchbar-custom.component';

@Component({
  selector: 'app-index-table-search-group',
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    MatIconModule,
    MatButtonModule,
    SearchbarCustomComponent,
    TranslateModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    IndexTableSortComponent,
  ],
  templateUrl: './index-table-search-group.component.html',
  styleUrls: ['./index-table-search-group.component.scss'],
  animations: [fuseAnimations],
})
export class IndexTableSearchGroupComponent implements OnInit, OnDestroy {
  @Input() enableExpandButton = false;
  @Input() enableFilterButton = true;
  @Input() enableSearchbar = true;
  @Input() isOpen = false;
  @Input() hasActiveFilter = false;
  @Input() flexAlign: 'space-between' | 'end' = 'end';
  @Input() permanentSearchbar = false;
  @Input() loading = false;
  @Input() views: TableViewItem[];
  @Input() sortArray: Pick<Choice<TableSort['sortBy']>, 'label' | 'value'>[];
  @Output() expandAllChange: EventEmitter<void> = new EventEmitter<void>();
  @Output() filterGroupOpen: EventEmitter<void> = new EventEmitter<void>();
  @Output() searchTermChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() viewChange: EventEmitter<TableViewItem> = new EventEmitter<TableViewItem>();
  @Output() clearAllClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() sortChange: EventEmitter<TableSort> = new EventEmitter<TableSort>();

  searchTerm: Subject<string>;
  selectedView: TableViewItem;

  private unsubscribeAll = new Subject<void>();

  constructor() {
    this.searchTerm = new Subject<string>();
    this.unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.subscribeToSearchTermChange();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  subscribeToSearchTermChange(): void {
    this.searchTerm.pipe(takeUntil(this.unsubscribeAll), distinctUntilChanged(isEqual)).subscribe((term) => {
      this.searchTermChange.emit(term);
    });
  }

  handleViewChange(view: TableViewItem): void {
    this.selectedView = view;
    if (!!view) {
      this.viewChange.emit(view);
    }
  }
}

export interface TableSort {
  sortBy: string;
  sortDir: 'ASC' | 'DESC';
}
