export enum SnippetEnum {
  RETAILER_EXPLORE_PRODUCTS = 136,
  RETAILER_ADD_IMPORT_LIST = 138,
  RETAILER_SYNC_CATALOG = 139,
  REVIEW = 140,
  SUPPLIER_COMPANY_DETAILS_AND_APPEARANCE = 131,
  SUPPLIER_OVERVIEW_CONTACT_DETAILS = 132,
  SUPPLIER_ADD_PRODUCTS = 133,
  SUPPLIER_SHIPPING_AND_CARRIERS = 134,
  SUPPLIER_BILLING_AND_PAYMENT_DETAILS = 135,
}
