import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PaymentService, PaypalConnect } from 'app/service/payment/payment.service';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { ConfirmationDialogsService } from '../../dialogs/confirmation-dialog/confirmation-dialog.service';
import { PaypalOnboardService, TrackOnboardStatus } from '../../../../service/paypal-onboard/paypal-onboard.service';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { PaypalCustomButtonComponent } from './paypal-custom-button/paypal-custom-button.component';
import { CustomIconComponent } from '../../custom-icon/custom-icon.component';
import { SetupGuideService } from '../../../../main/setup-guide/service/setup-guide.service';
import { SnippetEnum } from '../../../../main/setup-guide/enums/snippet-enums';
import { Utils } from '../../../../utils/utils';

@Component({
  selector: 'app-paypal-onboard',
  templateUrl: './paypal-onboard.component.html',
  styleUrls: ['./paypal-onboard.component.scss', '../../payment-connect/shared-payment.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    TranslateModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatSelectModule,
    FormsModule,
    PaypalCustomButtonComponent,
    CustomIconComponent,
  ],
})
export class PaypalOnboardComponent implements OnInit {
  @Input() redirectUrl = environment.paypal.returnurl;
  @Output() paypalConnected: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() countryRequireTaxSettings: boolean;
  connectData: PaypalConnect = undefined;
  inProgress = false;
  hasOldVersion = false;
  deletedAccounts: PaypalConnect[] = [];
  selectedOldAccount: PaypalConnect = undefined;
  onboardStatus: TrackOnboardStatus;

  constructor(
    private paypalOnboardService: PaypalOnboardService,
    private confirmationService: ConfirmationDialogsService,
    private _translateService: TranslateService,
    private paymentService: PaymentService,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private setupGuideService: SetupGuideService
  ) {}

  ngOnInit(): void {
    if (this.isReturnURL()) {
      this.handleReturnURLData();
      return;
    }
    this.init();
  }

  isReturnURL(): boolean {
    // return this.activateRoute.snapshot.params['payment-provider'] === 'paypal-redirect';
    return this.activateRoute.snapshot.queryParams['merchantIdInPayPal'];
  }

  handleReturnURLData(): void {
    this.inProgress = true;
    const merchantId = this.activateRoute.snapshot.queryParams['merchantId'];
    const merchantIdInPayPal = this.activateRoute.snapshot.queryParams['merchantIdInPayPal'];
    this.paypalOnboardService.saveOnboard(merchantId, merchantIdInPayPal).subscribe(
      () => {
        window.history.replaceState({}, '', this.redirectUrl);
        this.init();
        if (!this.countryRequireTaxSettings) {
          this.setupGuideService.setCompletedStep(SnippetEnum.SUPPLIER_BILLING_AND_PAYMENT_DETAILS);
        }
      },
      (_) => {},
      () => {
        this.inProgress = false;
      }
    );
  }

  init(): void {
    this.hasOldVersion = false;
    this.fetchConnectData()
      .pipe(
        filter((connectData) => !!connectData),
        switchMap((connectData) => {
          return connectData && connectData.activated
            ? of(<TrackOnboardStatus>{ primaryEmailConfirmed: true, paymentsReceivable: true })
            : this.submit();
        })
      )
      .subscribe((status) => {
        this.onboardStatus = status;
      });
  }

  fetchConnectData(): Observable<PaypalConnect> {
    this.inProgress = true;
    return this.paymentService.getPaypalConnect().pipe(
      tap(
        (connectData) => {
          if (connectData) {
            this.handleConnectData(connectData);
            return;
          }
          this.getDeletedPaypalEmails();
        },
        (_) => {},
        () => {
          this.inProgress = false;
        }
      )
    );
  }

  handleConnectData(paypalConnect: PaypalConnect): void {
    if (!paypalConnect) {
      return;
    }
    if (paypalConnect.version !== 'v2') {
      this.hasOldVersion = true;
      return;
    }
    this.connectData = paypalConnect;
    this.paypalConnected.emit(!Utils.isNullOrUndefinedOrLengthZero(paypalConnect?.activated));
  }

  paypalButtonClickHandle(): void {
    if (!this.connectData && !this.hasOldVersion) {
      this.getSignUpLink();
      return;
    }

    this.openConfirmationDialog().subscribe(() => {});
  }

  getSignUpLink(): void {
    this.paypalOnboardService.getSignUpLink(this.redirectUrl).subscribe((link) => {
      location.href = `${link.href}&displayMode=minibrowser`;
    });
  }

  connectionCheck(): void {
    this.submit()
      .pipe(switchMap(() => this.fetchConnectData()))
      .subscribe();
  }

  submit(): Observable<TrackOnboardStatus> {
    return this.paypalOnboardService.submit().pipe(
      tap((onboardStatus) => {
        this.onboardStatus = onboardStatus;
      })
    );
  }

  disconnect(): void {
    this.confirmationService
      .confirm(
        this._translateService.instant('BILLING.SUBSCRIPTION.PAYPAL_PAGES.WARNING'),
        this._translateService.instant('BILLING.SUBSCRIPTION.PAYPAL_PAGES.DISCONNECT_TEXT'),
        this._translateService.instant('BILLING.SUBSCRIPTION.PAYPAL_PAGES.DISCONNECT_BTN'),
        this._translateService.instant('BILLING.SUBSCRIPTION.PAYPAL_PAGES.CANCEL')
      )
      .subscribe(() => {
        this.paymentService.disconnectPaypalAccount(this.connectData.id).subscribe(() => {
          this.handleDisconnecting();
        });
      });
  }

  getDeletedPaypalEmails(): any {
    this.paypalOnboardService.getDeletedPaypalEmails().subscribe((response) => {
      this.deletedAccounts = response;
    });
  }

  handleDisconnecting(): void {
    this.connectData = null;
    this.getDeletedPaypalEmails();
    this.paypalConnected.emit(false);
  }

  getNewLink(): void {
    this.getSignUpLink();
  }

  reconnect(): void {
    this.paypalOnboardService.reActivePaypalConnect(this.selectedOldAccount.id).subscribe((_) => {
      this.init();
    });
  }

  openConfirmationDialog(): Observable<boolean> {
    return this.confirmationService.confirmWithoutCancel(
      this._translateService.instant('BILLING.SUBSCRIPTION.PAYPAL_PAGES.WARNING'),
      this._translateService.instant('BILLING.SUBSCRIPTION.PAYPAL_PAGES.DISCONNECT_PREVIOUS_WARNING')
    );
  }
}
