import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountryShippingInfo } from '../vo/country-shipping-info';
import { CurrencyExchangeToPipe } from '../../../pipes/currency-exchange-to.pipe';
import { CurrencyTransformPipe } from '../../../pipes/currency-transform-pipe.pipe';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MarketplaceEcomService } from '../../../../service/marketplace/marketplace-ecom/marketplace-ecom.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { ShippingRateConditionDto, ShippingRateType } from '../../../../vo/shipping-zones/shipping-rate-condition-dto';
import { ShippingCarrierType } from '../../../../vo/shipping-zones/shipping-carrier-type';
import { ConvertToUserWeightUnitPipe } from '../../../pipes/convert-to-user-weight-unit.pipe';
import { TruncatePipe } from '../../../pipes/truncate.pipe';

@Component({
  selector: 'app-shipping-details-rates-table',
  standalone: true,
  imports: [
    CommonModule,
    CurrencyExchangeToPipe,
    CurrencyTransformPipe,
    MatTableModule,
    TranslateModule,
    FlexModule,
    MatIconModule,
    MatTooltipModule,
    MatCardModule,
    ConvertToUserWeightUnitPipe,
    TruncatePipe,
  ],
  templateUrl: './shipping-details-rates-table.component.html',
  styleUrls: ['./shipping-details-rates-table.component.scss'],
})
export class ShippingDetailsRatesTableComponent implements OnInit {
  @Input() rates: CountryShippingInfo[];
  @Input() supplierCurrency: string;
  @Input() hasAppliedShippingZone: boolean;
  @Input() isAppliedShippingZone: boolean;
  @Input() taxIncludedToShipping: boolean;

  protected readonly shippingRateType = ShippingRateType;

  mappedRates: MappedRates[] = [];
  currency: string;

  readonly displayedColumns = ['location', 'condition', 'incrementalFee', 'shippingTime', 'shippingCost'];

  constructor(private _marketplaceEcomService: MarketplaceEcomService, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.rates.map((elem) => {
      this.mappedRates.push({
        location: elem.country,
        condition: elem.condition,
        additionalCost: elem.additionalCost,
        minShippingDays: elem.minShippingDays,
        maxShippingDays: elem.maxShippingDays,
        shippingCost: elem.shippingCost,
        isNestedElement: false,
        hasNested: elem.shippingRate && elem.shippingRate.length > 0,
      });

      if (elem.shippingRate && elem.shippingRate.length > 0) {
        elem.shippingRate.map((rate) => {
          this.mappedRates.push({
            location:
              rate.shippingCarrier.name +
              (rate.shippingCarrier.type && rate.shippingCarrier.type !== ShippingCarrierType.GENERAL
                ? ' - ' + this.translateService.instant('SHIPPING_DETAILS.SHIPPING_TYPE.' + rate.shippingCarrier.type)
                : ''),
            condition: rate.condition,
            additionalCost: rate.additionalCost,
            minShippingDays: rate.minShippingDays,
            maxShippingDays: rate.maxShippingDays,
            shippingCost: rate.cost,
            isNestedElement: true,
            hasNested: false,
          });
        });
      }
    });

    this.currency = this._marketplaceEcomService.getCurrency();
  }
}

interface MappedRates {
  location: string;
  condition: ShippingRateConditionDto;
  additionalCost: number;
  minShippingDays: number;
  maxShippingDays: number;
  shippingCost: number;
  isNestedElement: boolean;
  hasNested: boolean;
}
