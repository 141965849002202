import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { AppState } from 'app/app.state';
import { ShippingCatalogRestService, TaskForProfileResp } from 'app/service/shipping/shipping-catalog-rest.service';
import { ShippingProfileRestService } from 'app/service/shipping/shipping-profile-rest.service';
import { ShippingRateRestService } from 'app/service/shipping/shipping-rate-rest.service';
import { MarketplaceBadgeDirective } from 'app/shared/directives/marketplace-badge.directive';
import { IsEmptyPipe } from 'app/shared/pipes/is-empty.pipe';
import { getCountriesSelector } from 'app/store/countries-locations/countries-locations.selector';
import { shipsToPreferenceSelector } from 'app/store/preferences/preferences.selector';
import { locationByIpSelector } from 'app/store/user/user.selector';
import { omitNullOrUndefinedArray } from 'app/utils/operator/omit-null-or-undefined-array';
import { Utils } from 'app/utils/utils';
import { CountryLocationDto } from 'app/vo/countries-location/country-location-dto';
import { ShippingRateDto } from 'app/vo/shipping-zones/shipping-rate.dto';
import { combineLatest, finalize, forkJoin, mergeMap, Observable, of, switchMap, take, tap } from 'rxjs';
import { ShippingProfileDto } from '../../../vo/shipping-zones/shipping-profile-dto';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { ShippingDetailsNewFullComponent } from '../shipping-details-new-full/shipping-details-new-full.component';
import { ShippingDetailsRatesTableComponent } from '../shipping-details-new-full/shipping-details-rates-table/shipping-details-rates-table.component';
import { ShippingDetailsPreferredTableComponent } from '../shipping-details-preferred-table/shipping-details-preferred-table.component';
import { AlertMultiComponent } from '../alert-multi/alert-multi.component';
import { filter } from 'rxjs/operators';
import { shippingRateSort } from '../../../utils/shipping-rate-sort';

@UntilDestroy()
@Component({
  selector: 'app-shipping-details-preferred',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatTabsModule,
    MarketplaceBadgeDirective,
    ShippingDetailsRatesTableComponent,
    FlexModule,
    MatButtonModule,
    ShippingDetailsNewFullComponent,
    ShippingDetailsPreferredTableComponent,
    LoadingSpinnerComponent,
    IsEmptyPipe,
    AlertMultiComponent,
  ],
  templateUrl: './shipping-details-preferred.component.html',
  styleUrls: ['./shipping-details-preferred.component.scss'],
  providers: [ShippingProfileRestService, ShippingRateRestService],
})
export class ShippingDetailsPreferredComponent implements OnInit {
  @Input() userId: number;
  @Input() isWorldwide: boolean;
  @Input() supplierCurrency: string;
  @Input() taskId: number;
  @Input() isAutoOrder: Observable<boolean>;
  @Input() taxIncludedToShipping: boolean;

  showAll: boolean;
  profiles: ShippingProfileDto[];
  selectedProfile: ShippingProfileDto;
  selectedIndex = 0;
  rates: Record<string, ShippingRateDto[]> = {};
  preferredLocations: CountryLocationDto[];
  isLoading: boolean;
  isLoadingAll: boolean;

  private preferences$: Observable<string[]>;
  private countries$: Observable<CountryLocationDto[]>;
  private locationByIp$: Observable<string>;

  constructor(
    private shippingProfileService: ShippingProfileRestService,
    private shippingRateRestService: ShippingRateRestService,
    private shippingCatalogRestService: ShippingCatalogRestService,
    private store: Store<AppState>
  ) {
    this.preferences$ = this.store.select(shipsToPreferenceSelector);
    this.countries$ = this.store.select(getCountriesSelector);
    this.locationByIp$ = this.store.select(locationByIpSelector);
  }

  ngOnInit(): void {
    this.getProfiles();
  }

  handleTabChange(index: number): void {
    this.selectedIndex = index;
    this.selectedProfile = this.profiles?.[index];
    this.rates = {};
    this.getRates();
  }

  private getProfiles(): void {
    this.isLoading = true;
    this.isLoadingAll = true;

    this.shippingProfileService
      .getAllShippingProfileByUserIds(false, this.userId)
      .pipe(
        tap((profiles) => (this.profiles = profiles)),
        tap((profiles) => (this.showAll = !profiles?.length)),
        filter((profiles) => !!profiles?.length),
        mergeMap((profiles) => this.getTasksForProfile$(profiles)),
        untilDestroyed(this),
        finalize(() => (this.isLoadingAll = false))
      )
      .subscribe({
        next: () => this.getRates(),
      });
  }

  private getTasksForProfile$(profiles: ShippingProfileDto[]): Observable<TaskForProfileResp[]> {
    return this.shippingCatalogRestService.getTasksForProfile(profiles.map((p) => p.id)).pipe(
      tap((tasksResponse) => {
        if (tasksResponse) {
          if (this.taskId) {
            this.profiles = this.profiles.filter(
              (p) => p.id === tasksResponse.find((t) => t.taskId === this.taskId).shippingProfileId
            );
          } else {
            this.profiles = this.profiles.filter((profile) =>
              tasksResponse.map((p) => p.shippingProfileId).includes(profile.id)
            );
          }
        }
      })
    );
  }

  private getRates(): void {
    combineLatest([this.preferences$, this.countries$, this.locationByIp$])
      .pipe(
        tap(() => {
          this.isLoading = true;
        }),
        omitNullOrUndefinedArray(),
        switchMap(([preferences, countries, location]) =>
          this.getPreferredLocations$(preferences, countries, location)
        ),
        tap((preferences) => {
          this.preferredLocations = preferences;
        }),
        switchMap((preferredLocations) => this.getRatesForLocations$(preferredLocations)),
        take(1),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe();
  }

  private getPreferredLocations$(
    preferences: string[],
    countries: CountryLocationDto[],
    location: string
  ): Observable<CountryLocationDto[]> {
    return !!preferences?.length
      ? of(preferences.map((preference) => countries.find((country) => country.country === preference)))
      : of([countries.find((country) => country.countryCode === location)]);
  }

  private getRatesForLocations$(preferredLocations: CountryLocationDto[]): Observable<ShippingRateDto[][]> {
    return forkJoin(
      preferredLocations.map((prefereredLocation) =>
        this.shippingRateRestService
          .getAllShippingRatesForProfileByLocation(
            this.selectedProfile ? this.selectedProfile.id : this.profiles[0].id,
            prefereredLocation.id,
            500
          )
          .pipe(
            tap((res) => {
              if (!Utils.isNullOrUndefinedOrLengthZero(res)) {
                this.rates[prefereredLocation.countryCode] = shippingRateSort(res);
              }
            })
          )
      )
    );
  }
}
