import { Pipe, PipeTransform } from '@angular/core';
import { PriceType } from '../../main/taskwizard/supplier-task-pricing/enums/price-type.enum';

@Pipe({
  name: 'getRetailerPriceRoundingDescription',
  standalone: true,
})
export class GetRetailerPriceRoundingDescriptionPipe implements PipeTransform {
  transform(value: PriceType): string {
    switch (value) {
      case PriceType.RETAIL_PRICE:
        return 'IMPORT_LIST.ROUNDING.RETAIL_PRICE_DESCRIPTION';
      case PriceType.PRICE:
        return 'IMPORT_LIST.ROUNDING.PRICE_DESCRIPTION';
      default:
        return 'IMPORT_LIST.ROUNDING.DESCRIPTION';
    }
  }
}
