import { Catalog } from '../../vo/catalog';
import {
  AddCatalogAction,
  AddRecentlyAddedProductsAction,
  DeleteCatalogAction,
  GetCatalogsSuccessAction,
  RCatalogsActionTypes,
  RetailerCatalogsActionsUnion,
  SetCatalogsAction,
  SetSelectedCatalogAction,
  UpdateCatalogFilterAction,
} from './rcatalogs.action';

export interface RcatalogsState {
  catalogs: Catalog[];
  selectedCatalog: Catalog;
  recentlyAddedProducts: RecentlyAddedProduct[];
}

const initialState: RcatalogsState = {
  catalogs: null,
  selectedCatalog: getSelectedImportListFromStorage() ?? null,
  recentlyAddedProducts: null,
};

export function rcatalogsReducer(state = initialState, action: RetailerCatalogsActionsUnion): RcatalogsState {
  switch (action.type) {
    case RCatalogsActionTypes.GET_RCATALOGS_SUCCESS: {
      return setCatalogs(state, action);
    }
    case RCatalogsActionTypes.setCatalogs: {
      return setCatalogs(state, action);
    }
    case RCatalogsActionTypes.addCatalog: {
      return addCatalog(state, action);
    }
    case RCatalogsActionTypes.clearCatalogs: {
      return clearCatalogs(state);
    }
    case RCatalogsActionTypes.deleteCatalog: {
      return deleteCatalog(state, action);
    }
    case RCatalogsActionTypes.setSelectedCatalog: {
      return setSelectedCatalog(state, action);
    }
    case RCatalogsActionTypes.clearSelectedCatalog: {
      return clearSelectedCatalog(state);
    }
    case RCatalogsActionTypes.addRecentlyAddedProducts: {
      return addRecentlyAddedProducts(state, action);
    }
    case RCatalogsActionTypes.clearRecentlyAddedProducts: {
      return clearRecentlyAddedProducts(state);
    }
    case RCatalogsActionTypes.updateCatalogFilter: {
      return updateCatalogFilter(state, action);
    }
    default: {
      return state;
    }
  }
}

function setCatalogs(state: RcatalogsState, action: GetCatalogsSuccessAction | SetCatalogsAction): RcatalogsState {
  return {
    ...state,
    catalogs: action.payload,
  };
}

function addCatalog(state: RcatalogsState, action: AddCatalogAction): RcatalogsState {
  return {
    ...state,
    catalogs: [...state.catalogs, action.payload],
  };
}

function clearCatalogs(state: RcatalogsState): RcatalogsState {
  return {
    ...state,
    catalogs: null,
  };
}

function deleteCatalog(state: RcatalogsState, action: DeleteCatalogAction): RcatalogsState {
  return {
    ...state,
    catalogs: state.catalogs.filter((catalog) => Number(catalog.id) !== action.payload),
  };
}

function setSelectedCatalog(state: RcatalogsState, action: SetSelectedCatalogAction): RcatalogsState {
  return {
    ...state,
    selectedCatalog: action.payload,
  };
}

function clearSelectedCatalog(state: RcatalogsState): RcatalogsState {
  return {
    ...state,
    selectedCatalog: null,
  };
}

function addRecentlyAddedProducts(state: RcatalogsState, action: AddRecentlyAddedProductsAction): RcatalogsState {
  const products: RecentlyAddedProduct[] = [...(state.recentlyAddedProducts ?? []), ...action.payload];
  return {
    ...state,
    recentlyAddedProducts: products.slice(-10),
  };
}

function clearRecentlyAddedProducts(state: RcatalogsState): RcatalogsState {
  return {
    ...state,
    recentlyAddedProducts: null,
  };
}

function getSelectedImportListFromStorage(): Catalog {
  return JSON.parse(localStorage.getItem('selectedImportList')) as Catalog;
}

function updateCatalogFilter(state: RcatalogsState, action: UpdateCatalogFilterAction): RcatalogsState {
  return {
    ...state,
    catalogs: state.catalogs.map((catalog) => {
      if (Number(catalog.id) != action.payload.catalogId) {
        return catalog;
      } else {
        return {
          ...catalog,
          filter: action.payload.filter,
        };
      }
    }),
  };
}

export interface RecentlyAddedProduct {
  supplierId: number;
  productId: string;
}
