<button mat-icon-button [matMenuTriggerFor]="menu">
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <ng-container *ngFor="let item of menus">
    <button
      mat-menu-item
      *ngIf="!(item.id === 'source_settings' && task.sourceType !== 'UPLOAD')"
      [disabled]="item.id === 'delete' && task.isStillRunning"
      (click)="menuItemClicked.emit({ id: item.id, task: task })"
    >
      <mat-icon>{{ item.icon }}</mat-icon>
      <ng-container [ngSwitch]="item.id">
        <ng-container *ngSwitchCase="'active_inactive'">
          <span>
            {{
              (task.isActive === '1' ? 'TASKS.MENU_ITEMS_LABELS.SET_INACTIVE' : 'TASKS.MENU_ITEMS_LABELS.SET_ACTIVE')
                | translate
            }}
          </span>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <span>{{ item.label }}</span>
        </ng-container>
      </ng-container>
    </button>
  </ng-container>
</mat-menu>
