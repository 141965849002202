import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { omitNullOrUndefined } from 'app/utils/operator/omit-null-or-undefined';
import { omitNullOrUndefinedArray } from 'app/utils/operator/omit-null-or-undefined-array';
import { Utils } from 'app/utils/utils';
import { uniq } from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { isAuthenticatedSelector } from '../../store/authentication/authentication.selector';
import { getCurrentUserIdSelector, hasRoleSelector } from '../../store/user/user.selector';
import { newCountries } from '../../utils/Countries';
import { FilterSuggestionsVO } from '../../vo/filter-suggestions-vo';
import { RolesEnum } from '../../vo/roles/roles';
import {
  createQueryFilter,
  FilterItemsVO,
  FullMarketplaceFilterVO,
  MarketplaceFilterPagination,
  SearchProductType,
  SearchProductVO,
  SearchQueryVO,
} from '../../vo/search-product-vo';
import { BasicCountryDto } from '../../vo/shipping-details/basic-country-dto';
import { EcomVO } from '../ecom/ecom.service';
import { PreferenceStoreService } from '../preference/preference.store.service';
import { SpringRestService } from '../rest/microservices/spring-rest.service';

@Injectable({
  providedIn: 'root',
})
export class ProductSearchService {
  private static microserviceName = 'productSearch';
  private static termCookieId = 'termHistory';

  constructor(
    private cookieService: CookieService,
    private restService: SpringRestService,
    private store: Store<AppState>,
    private preferenceStoreService: PreferenceStoreService
  ) {}

  public getProductsByVariantIds(variantIds: string[]): Observable<SearchProductVO[]> {
    return this.restService
      .post(ProductSearchService.microserviceName, `/Search/Products/ByVariantIds`, variantIds, {}, true)
      .pipe(map((response) => response.result));
  }

  public getProductsByVariantIdsAndSupplierUserId(
    variantIds: string[],
    supplierUserId: number
  ): Observable<SearchProductVO[]> {
    return this.restService
      .get(
        ProductSearchService.microserviceName,
        `/Search/Products/ByVariantIdsAndUserId`,
        { variantIds: variantIds.join(','), userId: supplierUserId },
        true
      )
      .pipe(map((response) => response.result));
  }

  public getProductBySynceeIds(synceeIds: string[]): Observable<SearchProductVO[]> {
    const qParams = synceeIds.join(',');
    return this.restService
      .get(ProductSearchService.microserviceName, `/Search/Products?productIds=${qParams}`)
      .pipe(map((response) => response.result));
  }

  private getUserData(): Observable<{ userId: number; isAdmin: boolean }> {
    return this.store.select(isAuthenticatedSelector).pipe(
      omitNullOrUndefined(),
      switchMap((authenticated) =>
        !!authenticated
          ? combineLatest([
              this.store.select(hasRoleSelector(RolesEnum.ADMIN)),
              this.store.select(getCurrentUserIdSelector),
            ]).pipe(
              omitNullOrUndefinedArray(),
              map(([isAdmin, userId]) => ({ userId, isAdmin }))
            )
          : of({ userId: null, isAdmin: false })
      )
    );
  }

  private searchProductFrom(
    ecom: EcomVO,
    filters: Partial<FullMarketplaceFilterVO>,
    path: string,
    pagination?: MarketplaceFilterPagination,
    type: SearchProductType = SearchProductType.HYBRID,
    isHidden?: boolean
  ): Observable<ProductSearchResponse> {
    const filterItems = new FilterItemsVO({ ...filters, ...(Utils.isNullOrUndefined(pagination) ? {} : pagination) });
    return combineLatest([this.getUserData(), this.preferenceStoreService.getPreferredLocation()]).pipe(
      omitNullOrUndefinedArray(),
      take(1),
      switchMap(([userData, location]) =>
        this.restService.post(ProductSearchService.microserviceName, path, {
          ...this.filterItemsToPostQuery(ecom, filterItems, userData.isAdmin, userData.userId),
          isHidden: isHidden,
          countryCode: location?.code,
          searchType: type,
        })
      )
    );
  }

  public searchProducts(
    ecom: EcomVO,
    filters: Partial<FullMarketplaceFilterVO>,
    pagination?: MarketplaceFilterPagination,
    type: SearchProductType = SearchProductType.HYBRID,
    isHidden: boolean | null = false
  ): Observable<ProductSearchResponse> {
    return this.searchProductFrom(ecom, filters, '/Search/Products', pagination, type, isHidden);
  }

  public searchRandomProducts(
    ecom: EcomVO,
    filters: Partial<FullMarketplaceFilterVO>,
    pagination?: MarketplaceFilterPagination,
    type: SearchProductType = SearchProductType.HYBRID,
    isHidden?: boolean
  ): Observable<ProductSearchResponse> {
    return this.searchProductFrom(ecom, filters, '/Search/Products/Random', pagination, type, isHidden);
  }

  public searchBulkFilterProducts(
    ecom: EcomVO,
    filters: any[],
    taskId: number,
    pagination?: MarketplaceFilterPagination,
    isHidden?: boolean
  ): Observable<ProductSearchResponse> {
    return this.restService.post(ProductSearchService.microserviceName, '/Search/Products', {
      ecom: { id: ecom.id, ecomType: ecom.ecomType },
      queryFilter: Utils.isNullOrUndefined(filters)
        ? [{ key: 'TASK_ID', condition: 'equals', values: [taskId.toString()] }]
        : filters,
      ...(Utils.isNullOrUndefined(pagination) ? {} : pagination),
      isHidden: Utils.isNullOrUndefined(isHidden) ? false : isHidden,
    });
  }

  public filterSuggestions(filterSuggestion: FilterSuggestionsVO): Observable<string[]> {
    return this.restService.post(ProductSearchService.microserviceName, '/FilterSuggestions', filterSuggestion);
  }

  filterItemsToPostQuery(ecom: EcomVO, filterItems: any, isAdmin?: boolean, userId?: number): any {
    const postQuery = new SearchQueryVO();
    const queryFilters = [];
    const inStockqueryFilter = [];

    filterItems.getParams().forEach((elem) => {
      if (!Utils.isNullOrUndefined(elem.value)) {
        if (elem.isQueryFilter) {
          switch (elem.name) {
            case 'shipsFrom':
              const allEUCountries = [];
              newCountries.Europe.forEach((country) => allEUCountries.push(country.name));
              if (elem.value === 'Europe') {
                queryFilters.push(createQueryFilter(elem.elasticField, allEUCountries));
                queryFilters.push('and');
              } else {
                queryFilters.push(createQueryFilter(elem.elasticField, elem.value));
                queryFilters.push('and');
              }
              break;
            case 'stock':
              if (elem.value !== 'any') {
                inStockqueryFilter.push(createQueryFilter(elem.elasticField, [0], '>'));
                inStockqueryFilter.push('or');
                inStockqueryFilter.push(createQueryFilter(elem.elasticField, null, 'not_exists'));
              }
              break;
            case 'autoOrder':
              if (elem.value !== 'any') {
                queryFilters.push(createQueryFilter(elem.elasticField, elem.value));
                queryFilters.push('and');
              }
              break;
            case 'minPrice':
              if (elem.value !== null) {
                queryFilters.push(createQueryFilter(elem.elasticField, elem.value, '>'));
                queryFilters.push('and');
              }
              break;
            case 'maxPrice':
              if (elem.value !== null) {
                queryFilters.push(createQueryFilter(elem.elasticField, elem.value, '<'));
                queryFilters.push('and');
              }
              break;
            case 'minShippingTime':
              if (elem.value !== null) {
                queryFilters.push(createQueryFilter(elem.elasticField, elem.value, '>'));
                queryFilters.push('and');
              }
              break;
            case 'maxShippingTime':
              if (elem.value !== null) {
                queryFilters.push(createQueryFilter(elem.elasticField, elem.value, '<'));
                queryFilters.push('and');
              }
              break;
            default:
              if (elem.value !== 'any') {
                queryFilters.push(createQueryFilter(elem.elasticField, elem.value));
                queryFilters.push('and');
              }
          }
        } else {
          postQuery[elem.elasticField] = elem.value;
        }
      }
    });

    if (queryFilters?.length) {
      queryFilters.splice(-1, 1);
      postQuery.queryFilter.push(queryFilters);
    }
    if (inStockqueryFilter.length > 0) {
      if (queryFilters?.length) {
        postQuery.queryFilter.push('and');
      }
      postQuery.queryFilter.push(inStockqueryFilter);
    }

    if (!isAdmin && userId !== filterItems.supplier) {
      const visibilityQuery = [];
      visibilityQuery.push(createQueryFilter('VISIBILITY', [true]));
      visibilityQuery.push('or');
      visibilityQuery.push(createQueryFilter('VISIBILITY', [], 'not_exists'));
      postQuery.queryFilter.push('and');
      postQuery.queryFilter.push(visibilityQuery);
    }

    let preferences = this.cookieService.get('preferences');
    if (preferences !== '' && preferences !== '{}') {
      preferences = JSON.parse(preferences);
      const cookiePreferences = [];
      for (const [key, value] of Object.entries(preferences)) {
        const prefValues = [];
        for (const [key1, value1] of Object.entries(value)) {
          prefValues.push({
            value: key1,
            count: value1['count'],
          });
        }
        cookiePreferences.push({
          preferenceField: key,
          cookiePreferenceValues: prefValues,
        });
      }
      postQuery['cookiePreference'] = cookiePreferences;
    }

    if (!Utils.isNullOrUndefined(ecom)) {
      postQuery['ecom'] = {
        id: ecom.id || ecom.ecomId,
        ecomType: ecom.ecomType,
      };
    }

    if (!!userId) {
      postQuery['userId'] = userId;
    }

    return postQuery;
  }

  public getTrendingSearch(): Observable<string[]> {
    return this.restService.get(ProductSearchService.microserviceName, '/Search/History/TrendingSearch');
    // return Observable.of(['dress', 'fashion', 'red', 'valentine']).delay(500);
  }

  public saveTermToCookies(...termsToSave: string[]): void {
    if (this.cookieService.check(ProductSearchService.termCookieId)) {
      let terms = JSON.parse(this.cookieService.get(ProductSearchService.termCookieId)) as string[];
      terms = uniq([...termsToSave, ...terms]);
      if (terms.length > 3) {
        terms.pop();
      }
      this.cookieService.set(ProductSearchService.termCookieId, JSON.stringify(terms));
    } else {
      this.cookieService.set(ProductSearchService.termCookieId, JSON.stringify(termsToSave));
    }
  }

  public deleteTermFromCookies(term: string): void {
    if (this.cookieService.check(ProductSearchService.termCookieId)) {
      const terms = this.getTermCookies().filter((t) => t !== term);
      this.cookieService.set(ProductSearchService.termCookieId, JSON.stringify(terms));
    }
  }

  public getTermCookies(): string[] {
    if (this.cookieService.check(ProductSearchService.termCookieId)) {
      return JSON.parse(this.cookieService.get('termHistory')) as string[];
    } else {
      return [];
    }
  }

  public getLanguagesForFilters(): Observable<string[]> {
    return this.restService
      .get(ProductSearchService.microserviceName, '/Search/Products/Languages')
      .pipe(map((res) => res));
  }

  public getShipsFromForFilters(): Observable<string[]> {
    return this.restService
      .get(ProductSearchService.microserviceName, '/Search/Products/ShipsFrom')
      .pipe(map((res) => res));
  }

  public getUserLocationByIp(): Observable<Pick<BasicCountryDto, 'countryCode'>> {
    return this.restService.get(ProductSearchService.microserviceName, '/Location/Country', undefined, true);
  }
}

export interface ProductSearchResponse {
  took: number;
  total: number;
  result: SearchProductVO[];
}
