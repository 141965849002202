import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { RestService } from '../rest/rest.service';
import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { SupplierGatewayService } from '../suppliers/supplier-gateway.service';
import { SupplierWithBadgesDto } from '../../vo/supplier/supplier-with-badges-dto';
import { CountryCodeNameMapperService } from '../country-code-name-mapper.service';
import { EU_COUNTRIES_CODES } from '../../utils/Constants';

@Injectable({
  providedIn: 'root',
})
export class SupplierTaxSettingsService {
  private _payPalEmailChange = new Subject<string>();

  private COUNTRIES_REQUIRE_TAX_SETTINGS: string[] = [...EU_COUNTRIES_CODES, 'GB', 'AU', 'CA', 'US'];

  constructor(
    private restService: RestService,
    private supplierGatewayService: SupplierGatewayService,
    private countryCodeNameMapperService: CountryCodeNameMapperService
  ) {}

  public validateTaxRate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value >= 0 ? null : { validateTaxRate: { value: 'Tax rate must be positive!' } };
    };
  }

  public getWarehouseLocation(): Observable<any> {
    return this.restService.get('SupplierDetailsService/getWarehouseLocation').pipe(
      map((res) => {
        return res.getFirstData();
      })
    );
  }

  public saveTaxSettings(
    productHasTax: boolean,
    shippingHasTax: boolean,
    addTaxToOrder: boolean,
    shippingHasTaxInStoreOrder: boolean,
    taxSettings: TaxRate[]
  ): Observable<any> {
    const params = {
      productHasTax: productHasTax,
      shippingHasTax: shippingHasTax,
      addTaxToOrder: addTaxToOrder,
      shippingHasTaxInStoreOrder: shippingHasTaxInStoreOrder,
      taxSettings: taxSettings.map((item) => item.toBasicObject()),
    };

    return this.restService.post('SupplierTaxSettingsService/save', params).pipe(
      map((res) => {
        return res.getData();
      })
    );
  }

  public saveUSSettings(
    accountId: number,
    licenceKey: string,
    companyCode: string,
    city: string,
    address1: string,
    address2: string,
    zip: string,
    province: any
  ): Observable<any> {
    const params = {
      id: accountId,
      licenceKey: licenceKey,
      companyCode: companyCode,
      countryCode: 'US',
      city: city,
      address1: address1,
      address2: address2,
      zip: zip,
      provinceCode: province.abbreviation,
      provinceName: province.name,
    };

    return this.restService.post('AvalaraService/save', params).pipe(
      map((res) => {
        return res.getData();
      })
    );
  }

  public getAvalaraAccount(): Observable<any> {
    return this.restService.get('AvalaraService/getAvalaraAccount').pipe(
      map((res) => {
        return res.getFirstData();
      })
    );
  }

  public saveTaxExemption(userId: number, taxExemption: boolean): Observable<any> {
    const params = {
      user: userId,
      exemption: taxExemption,
    };

    return this.restService.post('SupplierTaxSettingsService/updateTaxExemption', params).pipe(
      map((res) => {
        return res.getData();
      })
    );
  }

  public getTaxExemption(userId: string): Observable<any> {
    const params = new HttpParams().set('user', userId);

    return this.restService.get('SupplierTaxSettingsService/getTaxExemption', params).pipe(
      map((res) => {
        return res.getFirstData();
      })
    );
  }

  public getTaxSettings(): Observable<any> {
    return this.restService.get('SupplierTaxSettingsService/getTaxSettings').pipe(
      map((res) => {
        return res.getFirstData();
      })
    );
  }

  get payPalEmailChange(): Subject<string> {
    return this._payPalEmailChange;
  }

  public getCountryRequireTaxSettings(): Observable<boolean> {
    return this.supplierGatewayService.getCurrentUserSupplier().pipe(
      map((supplier: SupplierWithBadgesDto) => supplier.mainWarehouseLocation),
      map(this.countryCodeNameMapperService.mapCountryNameToCode),
      map((countryCode: string) => this.COUNTRIES_REQUIRE_TAX_SETTINGS.includes(countryCode))
    );
  }
}

export class BoolOptions {
  private _value: boolean;
  private _text: string;

  constructor(value: boolean, text: string) {
    this._value = value;
    this._text = text;
  }

  get value(): boolean {
    return this._value;
  }

  get text(): string {
    return this._text;
  }
}

export class TaxRate {
  private _countryCode: string;
  private _countryName: string;
  private _taxRate: number;

  constructor(countryCode: string, countryName: string, taxRate: number) {
    this._countryCode = countryCode;
    this._countryName = countryName;
    this._taxRate = taxRate;
  }

  public toBasicObject(): object {
    return {
      countryCode: this.countryCode,
      countryName: this.countryName,
      taxRate: this.taxRate,
    };
  }

  get countryCode(): string {
    return this._countryCode;
  }

  get countryName(): string {
    return this._countryName;
  }

  get taxRate(): number {
    return this._taxRate;
  }

  set taxRate(value: number) {
    this._taxRate = value;
  }
}
