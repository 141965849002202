import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ApprovalDialogComponent, ApproveDataVO } from './approval-dialog.component';
import { map } from 'rxjs/operators';
import { CatalogSidebarService } from '../../../service/catalog-sidebar/catalog-sidebar.service';

@Injectable({
  providedIn: 'root',
})
export class ApprovalDialogService {
  constructor(private dialog: MatDialog, private catalogSidebarService: CatalogSidebarService) {}

  public open(needsApproval: boolean, approveData: ApproveDataVO, taskId: number): Observable<boolean> {
    if (needsApproval) {
      return this.confirmSendApprove(approveData, taskId);
    } else {
      return this.catalogSidebarService.addRetailerToCatalogWithRest({ catalogId: taskId }).pipe(map(() => true));
    }
  }

  public confirmSendApprove(approveData: ApproveDataVO, catalogId: number | string): Observable<boolean> {
    let dialogRef: MatDialogRef<ApprovalDialogComponent>;
    const config = new MatDialogConfig();
    // config.viewContainerRef = viewContainerRef;
    config.autoFocus = false;
    config.maxWidth = '1000px';
    // config.panelClass = 'custom-modal-container';
    config.panelClass = 'custom-modal-full-width-lt-md';

    dialogRef = this.dialog.open(ApprovalDialogComponent, config);

    dialogRef.componentInstance.approveData = approveData;
    dialogRef.componentInstance.catalogId = catalogId;
    dialogRef.componentInstance.contentType = 'approve';

    return dialogRef.afterClosed();
  }

  public confirmUnderstandAndAccept(approveData: ApproveDataVO, catalogId: number | string): Observable<boolean> {
    let dialogRef: MatDialogRef<ApprovalDialogComponent>;
    const config = new MatDialogConfig();
    // config.viewContainerRef = viewContainerRef;
    config.autoFocus = false;
    config.maxWidth = '1000px';
    // config.panelClass = 'custom-modal-container';
    config.panelClass = 'custom-modal-full-width-lt-md';

    dialogRef = this.dialog.open(ApprovalDialogComponent, config);

    dialogRef.componentInstance.catalogId = catalogId;
    dialogRef.componentInstance.approveData = approveData;
    dialogRef.componentInstance.contentType = 'accept';

    return dialogRef.afterClosed();
  }
}
