import { Component, Input, OnInit } from '@angular/core';
import { CatalogApproveStatus } from 'app/service/catalog-sidebar/catalog-sidebar.service';
import { SearchProductVO } from 'app/vo/search-product-vo';
import { ProductPrices, ProductPricingUtils } from '../../../../utils/pricing/product-pricing-utils';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { CurrencyExchangeToPipe } from '../../../../shared/pipes/currency-exchange-to.pipe';
import { CurrencyTransformPipe } from '../../../../shared/pipes/currency-transform-pipe.pipe';

@Component({
  selector: 'app-product-prices',
  templateUrl: './product-prices.component.html',
  styleUrls: ['./product-prices.component.scss'],
  standalone: true,
  imports: [TranslateModule, CommonModule, FlexModule, CurrencyExchangeToPipe, CurrencyTransformPipe],
})
export class ProductPricesComponent implements OnInit {
  @Input() product: SearchProductVO;
  @Input() ecomCurrency: string;
  @Input() catalogStatus: CatalogApproveStatus;

  prices: ProductPrices;
  isLoading = true;

  constructor() {}

  ngOnInit(): void {
    this.calculatePrices();
    this.isLoading = false;
  }

  private calculatePrices(): void {
    this.prices = ProductPricingUtils.calculatePrices(
      this.product.VARIANTS[0],
      !!this.product.SETTINGS?.possibleMargin ? Number(this.product.SETTINGS.possibleMargin) : 0
    );
  }
}
