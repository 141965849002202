<div>
  <div class="pb-24">
    {{ 'SUPPLIER_CURRENCY_SELECTOR.TITLE' | translate }}
  </div>
  <ng-container *conditionLoading="!loading">
    <ng-container *ngIf="hasSupplierData; else empty">
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign.gt-xs="start center" fxLayoutGap="32px">
        <div class="title" fxFlex="50">{{ 'SUPPLIER_CURRENCY_SELECTOR.LABEL' | translate }}</div>
        <mat-form-field
          fxFlex="50"
          appearance="fill"
          class="input-without-padding input-without-padding-except-error select-without-label"
        >
          <mat-select [value]="currency" name="currency" (valueChange)="onChangeHandler($event)">
            <mat-option *ngFor="let currency of currencies" [value]="currency.value">{{
              currency.value + ' - ' + currency.label
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #empty>
  <app-alert-with-button
    type="warning"
    [alertButton]="{
      link: '/supplier/storefront-settings/information-for-retailers',
      textKey: 'SUPPLIER_CURRENCY_SELECTOR.EMPTY_STATE.GO_TO_INFORMATION_FOR_RETAILERS'
    }"
    [message]="'SUPPLIER_CURRENCY_SELECTOR.EMPTY_STATE.DESCRIPTION' | translate"
  ></app-alert-with-button>
</ng-template>
