<div class="shipping-details-dialog">
  <div class="header">
    <div class="general-info">
      <div class="general-item">
        <h4 class="h4" [style.margin]="'0'">{{ 'SHIPPING_DETAILS_DIALOG.WAREHOUSE_LOCATION' | translate }}:</h4>
        <span class="typography-body-3">{{ data.mainWarehouseLocation }}</span>
      </div>
    </div>
    <mat-icon class="s-20 cursor-pointer" mat-dialog-close>close</mat-icon>
  </div>
  <mat-dialog-content>
    <app-shipping-details-preferred
      [userId]="data.userId"
      [supplierCurrency]="data.supplierCurrency"
      [isWorldwide]="data.isWorldWide"
      [isAutoOrder]="isAutoOrder"
      [taskId]="data.taskId"
      [taxIncludedToShipping]="taxIncludedToShipping"
    ></app-shipping-details-preferred>
  </mat-dialog-content>
</div>
