import { environment } from '../../../../environments/environment';
import { FooterGroup, FooterMenuItem } from './footer.component';

export const footerStructure: FooterGroup[] = [
  {
    name: 'COMPANY',
    items: [
      {
        type: 'INNER_NAV',
        key: 'ABOUT_US',
        value: '/about-us',
      },
      {
        type: 'INNER_NAV',
        key: 'CONTACT_US',
        value: '/contact',
      },
      {
        type: 'INNER_NAV',
        key: 'PRICING',
        value: '/pricing',
      },
      {
        type: 'WORDPRESS_NAV_WITH_LANGUAGE',
        key: 'PARTNERS',
        value: '/our-partners/',
        wpPageType: 'page',
        wpDomain: 'https://syncee.com',
      },
      {
        type: 'WORDPRESS_NAV_WITH_LANGUAGE',
        key: 'CAREERS',
        value: '/jobs/',
        wpPageType: 'page',
        wpDomain: 'https://syncee.com',
      },
      {
        type: 'INNER_NAV',
        key: 'TERMS_OF_USE',
        value: '/terms-of-use',
      },
      {
        type: 'INNER_NAV',
        key: 'PRIVACY_POLICY',
        value: '/privacy-policy',
      },
      {
        type: 'INNER_NAV',
        key: 'COOKIE_POLICY',
        value: '/cookie-policy',
      },
    ],
  },
  {
    name: 'PLATFORM',
    items: [
      {
        type: 'INNER_NAV',
        key: 'DROPSHIPPING',
        value: '/dropshipping',
      },
      // {
      //   type: 'EXTERNAL_NAV',
      //   key: 'WHOLESALE',
      //   value: 'https://syncee.co/marketplace/',
      // },
      {
        type: 'EXTERNAL_NAV',
        key: 'ALIBABA',
        value: 'https://syncee.com/page/alibaba-dropshipping/',
      },
      {
        type: 'EXTERNAL_NAV',
        key: 'DATAFEED_MANAGER',
        value: 'https://syncee.com/page/datafeed-manager/',
      },
      {
        type: 'INNER_NAV',
        key: 'FOR_SUPPLIERS',
        value: '/suppliers',
      },
    ],
  },
  {
    name: 'PROGRAMS',
    items: [
      {
        type: 'INNER_NAV',
        key: 'SUPPLIER_REFERRAL',
        value: '/supplier-referral',
      },
      {
        type: 'INNER_NAV',
        key: 'RETAILER_REFERRAL',
        value: '/retailer-referral',
      },
      {
        type: 'INNER_NAV',
        key: 'AFFILIATE_PROGRAM',
        value: '/affiliates',
      },
    ],
  },
  {
    name: 'INTEGRATIONS',
    items: [
      {
        type: 'INNER_NAV',
        key: 'SHOPIFY',
        value: '/integration/shopify',
      },
      {
        type: 'WORDPRESS_NAV_WITH_LANGUAGE',
        key: 'WIX',
        value: '/wix-dropshipping/',
        wpPageType: 'page',
        wpDomain: 'https://syncee.com',
      },
      {
        type: 'WORDPRESS_NAV_WITH_LANGUAGE',
        key: 'WOOCOMMERCE',
        value: '/woocommerce-dropshipping/',
        wpPageType: 'page',
        wpDomain: 'https://syncee.com',
      },
      {
        type: 'WORDPRESS_NAV_WITH_LANGUAGE',
        key: 'SQUARESPACE',
        value: '/squarespace-dropshipping/',
        wpPageType: 'page',
        wpDomain: 'https://syncee.com',
      },
      {
        type: 'WORDPRESS_NAV_WITH_LANGUAGE',
        key: 'BIGCOMMERCE',
        value: '/bigcommerce-dropshipping/',
        wpPageType: 'page',
        wpDomain: 'https://syncee.com',
      },
      {
        type: 'WORDPRESS_NAV_WITH_LANGUAGE',
        key: 'ECWID',
        value: '/ecwid-dropshipping/',
        wpPageType: 'page',
        wpDomain: 'https://syncee.com',
      },
      {
        type: 'WORDPRESS_NAV_WITH_LANGUAGE',
        key: 'JUMPSELLER',
        value: '/jumpseller-dropshipping/',
        wpPageType: 'page',
        wpDomain: 'https://syncee.com',
      },
      {
        type: 'WORDPRESS_NAV_WITH_LANGUAGE',
        key: 'EKM',
        value: '/ekm-dropshipping/',
        wpPageType: 'page',
        wpDomain: 'https://syncee.com',
      },
      {
        type: 'WORDPRESS_NAV_WITH_LANGUAGE',
        key: 'SHOPRENTER',
        value: '/shoprenter-dropshipping/',
        wpPageType: 'page',
        wpDomain: 'https://syncee.com',
      },
      {
        type: 'WORDPRESS_NAV_WITH_LANGUAGE',
        key: 'KMO',
        value: '/kmo-shops-dropshipping/',
        wpPageType: 'page',
        wpDomain: 'https://syncee.com',
      },
      {
        type: 'INNER_NAV',
        key: 'SHOPIFY_PLUS',
        value: '/integration/shopify-plus',
      },
    ],
  },
  {
    name: 'RESOURCES',
    items: [
      {
        type: 'EXTERNAL_NAV_WITH_LANGUAGE',
        key: 'HELP_CENTER',
        value: 'https://help.syncee.com/',
      },
      {
        type: 'INNER_NAV',
        key: 'FAQ',
        value: '/faq',
      },
      {
        type: 'EXTERNAL_NAV',
        key: 'BLOG',
        value: 'https://syncee.com/blog/',
      },
      {
        type: 'EXTERNAL_NAV',
        key: 'COMMUNITY',
        value: 'https://www.facebook.com/groups/376552566064072',
      },
      {
        type: 'EXTERNAL_NAV',
        key: 'REVIEWS',
        value: 'https://apps.shopify.com/syncee-1/reviews',
      },
    ],
  },
];

export const socialPlatforms: FooterMenuItem[] = [
  {
    key: 'FACEBOOK',
    value: 'https://www.facebook.com/synceeapp',
    source: `${environment.assetsUrl}/images/social-icons/facebook.svg`,
    type: 'EXTERNAL_NAV',
  },
  {
    key: 'INSTAGRAM',
    value: 'https://www.instagram.com/syncee_co',
    source: `${environment.assetsUrl}/images/social-icons/instagram.svg`,
    type: 'EXTERNAL_NAV',
  },
  {
    key: 'YOUTUBE',
    value: 'https://www.youtube.com/@syncee_official',
    source: `${environment.assetsUrl}/images/social-icons/youtube.svg`,
    type: 'EXTERNAL_NAV',
  },
  {
    key: 'TIKTOK',
    value: 'https://www.tiktok.com/@syncee_official',
    source: `${environment.assetsUrl}/images/social-icons/tiktok.svg`,
    type: 'EXTERNAL_NAV',
  },
  {
    key: 'LINKED_IN',
    value: 'https://linkedin.com/company/syncee',
    source: `${environment.assetsUrl}/images/social-icons/linkedin.svg`,
    type: 'EXTERNAL_NAV',
  },
  {
    key: 'TWITTER',
    value: 'https://x.com/syncee_co',
    source: `${environment.assetsUrl}/images/social-icons/twitter-x.svg`,
    type: 'EXTERNAL_NAV',
  },
];

export const allRightsReserved: FooterMenuItem = {
  key: 'ALL_RIGHTS_RESERVED',
  type: 'NON_CLICK',
};

export const gdprs: FooterMenuItem[] = [
  {
    type: 'INNER_NAV',
    key: 'TERMS_OF_USE',
    value: '/terms-of-use',
  },
  {
    type: 'INNER_NAV',
    key: 'PRIVACY_POLICY',
    value: '/privacy-policy',
  },
  {
    type: 'INNER_NAV',
    key: 'COOKIE_POLICY',
    value: '/cookie-policy',
  },
];
