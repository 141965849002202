import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { TemplateType } from 'app/service/image-templates/image-templates.service';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { AppState } from '../../../app.state';
import { NotificationService } from '../../../main/notification/notification.service';
import { SupplierImageType, SupplierImageTypeEnum } from '../../../service/suppliers/suppliers.service';
import { getCurrentUserIdSelector } from '../../../store/user/user.selector';
import { GetResourceFromStoragePipe } from '../../pipes/get-resource-from-storage';
import { CustomDividerComponent } from '../custom-divider/custom-divider.component';
import { DragAndDropFileComponent } from '../drag-and-drop-file/drag-and-drop-file.component';
import {
  UploadSupplierImageComponent,
  UploadSupplierImageVariant,
} from '../upload-supplier-image/upload-supplier-image.component';

@Component({
  selector: 'app-storefront-upload-images',
  standalone: true,
  imports: [
    CommonModule,
    DragAndDropFileComponent,
    CustomDividerComponent,
    UploadSupplierImageComponent,
    GetResourceFromStoragePipe,
    TranslateModule,
  ],
  templateUrl: './storefront-upload-images.component.html',
  styleUrls: ['./storefront-upload-images.component.scss'],
})
export class StorefrontUploadImagesComponent implements OnInit, OnDestroy {
  @ViewChild('supplierLogo') supplierLogo: UploadSupplierImageComponent;
  @ViewChild('coverImage') coverImage: UploadSupplierImageComponent;
  // @ViewChild('brandImage') brandImage: UploadSupplierImageComponent;
  @Input() save: Subject<void>;
  @Input() variant: UploadSupplierImageVariant = 'default';
  @Input() hasDivider = true;
  userId: Observable<number>;
  changedTypes = new Set<SupplierImageType>();
  private unsubscribeAll: Subject<void>;

  imageType = SupplierImageTypeEnum;
  templateType = TemplateType;

  readonly timestamp = `&timestamp=${new Date().toISOString()}`;

  constructor(private store: Store<AppState>, private notificationService: NotificationService) {
    this.unsubscribeAll = new Subject<void>();
    this.userId = this.store.select(getCurrentUserIdSelector);
  }

  ngOnInit(): void {
    this.subscribeToSave();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private subscribeToSave(): void {
    if (!!this.save) {
      this.save.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => this.saveImages().subscribe());
    }
  }

  private getSaveObservables(): Observable<void>[] {
    return [
      ...(this.changedTypes.has('supplierLogo') ? [this.supplierLogo.save()] : []),
      ...(this.changedTypes.has('coverImage') ? [this.coverImage.save()] : []),
      // ...(this.changedTypes.has('brandLogo') ? [this.brandImage.save()] : []),
    ];
  }

  saveImages(): Observable<any> {
    const saveImages = this.getSaveObservables();
    return saveImages.length
      ? forkJoin(saveImages).pipe(
          catchError(() => {
            this.notificationService.error('Something went wrong!');
            return of(false);
          }),
          map((res) => (!res ? false : true)),
          tap((res) => {
            if (res) {
              this.notificationService.success('Images saved successfully!');
            }
          })
        )
      : of(null);
  }

  get isValid(): boolean {
    return this.supplierLogo?.isValid /*&& this.brandImage?.isValid */ && this.coverImage?.isValid;
  }
}
