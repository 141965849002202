<div class="shipping-details-full">
  <ng-container *ngIf="!isLoading; else loading">
    <ng-container *ngIf="(isAutoOrder | async) === false && allRatesNull">
      <div class="typography-body-1 pb-16">
        {{ 'PRODUCT_DETAILS.SHIPPING_MANAGED_INDIVIDUALLY' | translate }}
      </div>
    </ng-container>

    <ng-container *ngIf="profiles && profiles.length > 1">
      <mat-tab-group [selectedIndex]="selectedIndex" class="tab-simple-pagination tab-label-min-width-unset">
        <ng-container *ngFor="let profile of profiles">
          <mat-tab>
            <ng-template mat-tab-label>
              {{ profile.name }}
              <ng-container *ngIf="appliedShippingZone && appliedShippingZone.id == profile.id">
                <div
                  marketplaceBadge
                  [name]="'SHIPPING_DETAILS.APPLIES' | translate"
                  class="applies-badge typography-label-small"
                >
                  {{ 'SHIPPING_DETAILS.APPLIES' | translate }}
                </div>
              </ng-container>
            </ng-template>
            <ng-container *ngIf="appliedShippingZone && appliedShippingZone.id == profile.id">
              <div class="typography-body-1 p-12">{{ 'SHIPPING_DETAILS.APPLIED_PROFILE' | translate }}</div>
            </ng-container>
            <ng-container *ngIf="appliedShippingZone && appliedShippingZone.id != profile.id">
              <div class="typography-body-1 p-12">{{ 'SHIPPING_DETAILS.NOT_APPLIED_PROFILE' | translate }}</div>
            </ng-container>
            <ng-container [ngTemplateOutlet]="content" [ngTemplateOutletContext]="{ profile: profile }"></ng-container>
          </mat-tab>
        </ng-container>
      </mat-tab-group>
    </ng-container>

    <ng-container *ngIf="profiles && profiles.length === 1">
      <ng-container [ngTemplateOutlet]="content" [ngTemplateOutletContext]="{ profile: profiles[0] }"></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #content let-profile="profile">
  <app-shipping-details-zones
    [shippingZones]="profile.shippingZones"
    [isWorldwide]="isWorldwide"
    [supplierCurrency]="supplierCurrency"
    [isAppliedShippingZone]="appliedShippingZone?.id == profile.id"
    [hasAppliedShippingZone]="!!appliedShippingZone"
    [taxIncludedToShipping]="taxIncludedToShipping"
  >
  </app-shipping-details-zones>
</ng-template>

<ng-template #loading>
  <div class="w-100-p p-16">
    <app-loading-spinner [centered]="true" [size]="50"></app-loading-spinner>
  </div>
</ng-template>
