import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PaymentService, StripeConnect } from '../../../service/payment/payment.service';
import { ConfirmationDialogsService } from '../dialogs/confirmation-dialog/confirmation-dialog.service';
import { MatIconModule } from '@angular/material/icon';
import { FlexModule } from '@angular/flex-layout';
import { NgForOf, NgIf, NgStyle, NgSwitch, NgSwitchCase } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { CustomIconComponent } from '../custom-icon/custom-icon.component';
import { ConditionLoadingDirective } from '../../directives/condition-loading.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SetupGuideService } from '../../../main/setup-guide/service/setup-guide.service';
import { SnippetEnum } from '../../../main/setup-guide/enums/snippet-enums';
import { Utils } from '../../../utils/utils';

@Component({
  selector: 'app-stripe-connect',
  templateUrl: './stripe-connect.component.html',
  styleUrls: ['./stripe-connect.component.scss', '../payment-connect/shared-payment.scss'],
  imports: [
    MatIconModule,
    TranslateModule,
    FlexModule,
    NgIf,
    NgSwitch,
    MatButtonModule,
    NgStyle,
    NgForOf,
    MatSelectModule,
    NgSwitchCase,
    CustomIconComponent,
    ConditionLoadingDirective,
    MatTooltipModule,
  ],
  standalone: true,
})
export class StripeConnectComponent implements OnInit {
  @Input() redirectURL: string;
  @Output() stripeConnected: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() countryRequireTaxSettings: boolean;

  connectedData: StripeConnect;
  loadingStatus = false;
  deletedAccounts = [];
  selectedAccount: StripeConnect = null;

  constructor(
    private paymentService: PaymentService,
    public confirmationDialogsService: ConfirmationDialogsService,
    private _translateService: TranslateService,
    private activateRoute: ActivatedRoute,
    private setupGuideService: SetupGuideService
  ) {}

  ngOnInit(): void {
    const accountId = this.activateRoute.snapshot.queryParams['account-id'];
    if (accountId) {
      if (this.activateRoute.snapshot.queryParams['expired'] == 'true') {
        this.refreshConnect(accountId);
      } else {
        this.completeConnect(accountId);
      }
    } else {
      this.init();
    }
  }

  init(): void {
    this.paymentService.getStripeConnect().subscribe((data) => {
      if (data) {
        this.handleConnecting(data);
      } else {
        this.hideProgressBar(true);
        this.getDeletedAccounts();
      }
    });
  }

  startConnect(): void {
    this.hideProgressBar(false);
    this.paymentService.createAccount(this.redirectURL).subscribe((resp) => {
      if (!resp) {
        return;
      }
      window.location.href = resp.url;
    });
  }

  refreshConnect(accountId): void {
    this.paymentService.refreshConnect(accountId).subscribe(() => {
      this.init();
    });
  }

  completeConnect(accountId): void {
    this.paymentService.completeAccount(accountId).subscribe((data) => {
      this.handleConnecting(data);
      if (!this.countryRequireTaxSettings) {
        this.setupGuideService.setCompletedStep(SnippetEnum.SUPPLIER_BILLING_AND_PAYMENT_DETAILS);
      }
    });
  }

  generateAccountLink(): void {
    this.paymentService.createStripeAccountLink(this.connectedData.accountId, this.redirectURL).subscribe((resp) => {
      window.location.href = resp.url;
    });
  }

  openDialogForNewLink(): void {
    this.confirmationDialogsService
      .confirm(
        this._translateService.instant('BILLING.SUBSCRIPTION.STRIPE_PAGES.NEW_LINK.TITLE'),
        this._translateService.instant('BILLING.SUBSCRIPTION.STRIPE_PAGES.NEW_LINK.MESSAGE'),
        this._translateService.instant('BILLING.SUBSCRIPTION.STRIPE_PAGES.NEW_LINK.OK_BTN'),
        this._translateService.instant('BILLING.SUBSCRIPTION.STRIPE_PAGES.NEW_LINK.CANCEL_BTN')
      )
      .subscribe((result) => {
        if (result) {
          this.generateAccountLink();
        }
      });
  }

  hideProgressBar(status: boolean): void {
    this.loadingStatus = status;
  }

  disconnect(): void {
    this.confirmationDialogsService
      .confirm(
        this._translateService.instant('BILLING.SUBSCRIPTION.PAYPAL_PAGES.WARNING'),
        this._translateService.instant('BILLING.SUBSCRIPTION.PAYPAL_PAGES.DISCONNECT_TEXT'),
        this._translateService.instant('BILLING.SUBSCRIPTION.PAYPAL_PAGES.DISCONNECT_BTN'),
        this._translateService.instant('BILLING.SUBSCRIPTION.PAYPAL_PAGES.CANCEL')
      )
      .subscribe(() => {
        this.paymentService.disconnectStripeAccount(this.connectedData.id).subscribe(() => {
          this.handleDisconnecting();
        });
      });
  }

  getDeletedAccounts(): void {
    this.paymentService.getDeletedStripeConnects().subscribe((accounts) => {
      this.deletedAccounts = accounts;
    });
  }

  reUseAccount(): void {
    this.paymentService.reActiveStripeConnect(this.selectedAccount.id).subscribe(() => {
      this.init();
    });
  }

  handleDisconnecting(): void {
    this.connectedData = null;
    this.getDeletedAccounts();
    this.stripeConnected.emit(false);
  }

  handleConnecting(data): void {
    this.connectedData = data;
    this.stripeConnected.emit(!Utils.isNullOrUndefinedOrLengthZero(data?.activated));
    this.hideProgressBar(true);
  }
}
