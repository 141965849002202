import { Component, Input, OnInit } from '@angular/core';
import { ReviewReply } from 'app/main/syncee-review/model/review-reply';
import { SynceeReviewService } from 'app/main/syncee-review/service/syncee-review.service';
import { omitNullOrUndefined } from 'app/utils/operator/omit-null-or-undefined';
import { SynceeReviewDialogService } from '../../syncee-review/service/syncee-review-dialog.service';
import { SnippetEnum } from '../enums/snippet-enums';
import { SetupGuideService } from '../service/setup-guide.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-setup-guide-review-content',
  templateUrl: './setup-guide-review-content.component.html',
  styleUrls: ['./setup-guide-review-content.component.scss'],
})
export class SetupGuideReviewContentComponent implements OnInit {
  @Input() isCompleted: boolean;
  review: ReviewReply;
  backgroundImageUrl = 'assets/images/backgrounds/setup-guide-review-background.jpg';

  constructor(
    private setupGuideService: SetupGuideService,
    private synceeReviewDialogService: SynceeReviewDialogService,
    private synceeReviewService: SynceeReviewService
  ) {}

  ngOnInit(): void {
    this.getSynceeReview();
  }

  completeReviewStep(): void {
    if (!this.isCompleted) {
      this.setupGuideService.setCompletedStep(SnippetEnum.REVIEW);
    }
  }

  handleReviewButtonClick(): void {
    this.synceeReviewDialogService.open().subscribe(() => {
      this.getSynceeReview();
    });
  }

  private getSynceeReview(): void {
    this.synceeReviewService
      .getReviewReplies()
      .pipe(omitNullOrUndefined(), take(1))
      .subscribe({
        next: (review) => {
          this.review = review;
        },
      });
  }
}
