<div class="outer-container" [ngClass]="wrapperClass">
  <div
    class="inner-container"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutAlign.lt-md="center stretch"
    [ngClass]="innerWrapperClass"
  >
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="28px" class="white-500-fg cta-header-text">
      <h2 class="mt-0" [ngClass.lt-md]="'mt-0 centered-text'">
        {{ title }}
      </h2>
      <div
        *ngIf="!!descriptionInnerHTML && !descriptionTemplateRef"
        class="typography-body-3 cta-description"
        [innerHTML]="descriptionInnerHTML | sanitizeHTML"
      ></div>
      <ng-container *ngIf="!!descriptionTemplateRef" [ngTemplateOutlet]="descriptionTemplateRef"></ng-container>
      <div class="columns">
        <div class="left centered-text">
          <h3 *ngIf="!!subtitleLeft" class="m-0 pb-20" [class.h4]="ltMd">{{ subtitleLeft }}</h3>
          <div
            *ngIf="!!descriptionLeft"
            [ngClass]="ltMd ? 'typography-h4-subtitle pb-32' : 'typography-h3-subtitle pb-40'"
          >
            {{ descriptionLeft }}
          </div>
          <app-general-button *ngIf="!!buttonLeft" class="button" [config]="buttonLeft"></app-general-button>
        </div>
        <app-custom-divider [orientation]="ltMd ? 'horizontal' : 'vertical'"></app-custom-divider>
        <div class="right centered-text">
          <h3 *ngIf="!!subtitleRight" class="m-0 pb-20" [class.h4]="ltMd">{{ subtitleRight }}</h3>
          <div
            *ngIf="!!descriptionRight"
            [ngClass]="ltMd ? 'typography-h4-subtitle pb-32' : 'typography-h3-subtitle pb-40'"
          >
            {{ descriptionRight }}
          </div>
          <app-general-button *ngIf="!!buttonRight" class="button" [config]="buttonRight"></app-general-button>
        </div>
      </div>
    </div>
  </div>
</div>
