import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import {
  navigation,
  navigation_admin_with_collapse,
  navigation_agency,
  navigation_general,
  navigation_global_admin,
  navigation_global_admin_as_agency,
  navigation_global_admin_as_own_retailer,
  navigation_global_admin_as_own_supplier,
  navigation_global_admin_as_supplier,
} from 'app/navigation/navigation';
import { getCurrentUserIdSelector, getUserRoleSelector, hasRoleSelector } from 'app/store/user/user.selector';
import { USER_ROLES_STRINGS } from 'app/utils/Constants';
import { omitNullOrUndefined } from 'app/utils/operator/omit-null-or-undefined';
import { combineLatest, forkJoin, of, switchMap } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { FuseNavigationItem } from '../../../@fuse/types';
import { getInstalledSynceeAppsSelector } from '../../store/syncee-apps/syncee-apps.selector';
import { getAllTasks, hasApprovedTasksSelector } from '../../store/tasks/tasks.selector';
import { RolesEnum, ROLE_TYPE } from '../../vo/roles/roles';
import { NavigationService } from '../navigation/navigation.service';
import { SupplierGatewayService } from '../suppliers/supplier-gateway.service';
import { InstalledAppsAndGroups, SynceeAppGroupsDto, SynceeAppsDto } from '../user-apps/syncee-apps.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class MenuManagerService {
  navigation: any;
  navigation_general: any;
  navigation_agency: any;
  navigation_global_admin: any;
  navigation_global_admin_as_supplier: any;
  navigation_global_admin_as_agency: any;
  navigation_admin_with_collapse: any;
  navigation_global_admin_as_own_supplier: any;
  navigation_global_admin_as_own_retailer: any;

  constructor(
    private store: Store<AppState>,
    private _fuseNavigationService: FuseNavigationService,
    private fuseConfigService: FuseConfigService,
    private supplierGatewayService: SupplierGatewayService,
    private navigationService: NavigationService,
    private route: ActivatedRoute
  ) {
    this.initSidebarElements();
    this.loadListeners();
    // this.setStorefrontPreviewUrl();
  }

  private loadListeners(): void {
    combineLatest([
      this.store.select(getUserRoleSelector),
      this.store.select(getInstalledSynceeAppsSelector).pipe(catchError(() => of(null as InstalledAppsAndGroups))),
    ]).subscribe(([role, installedApps]) => {
      if (installedApps) {
        this.navigation_agency = navigation_agency;
        this.addInstalledSynceeAppsToMenu(installedApps);
        this.reRegisterNavigationAgency();
        this.setCurrentMenuByRole(role);
      } else {
        this.navigation_agency = navigation_agency;
        this.reRegisterNavigationAgency();
        this.setCurrentMenuByRole(role);
      }
    });
  }

  private reRegisterNavigationAgency(): void {
    this._fuseNavigationService.unregister(USER_ROLES_STRINGS.AGENT);
    this._fuseNavigationService.register(USER_ROLES_STRINGS.AGENT, this.navigation_agency);
  }

  private setStorefrontPreviewUrl(): void {
    forkJoin([
      this.store.select(hasRoleSelector(RolesEnum.SUPPLIER)).pipe(omitNullOrUndefined(), take(1)),
      this.store.select(getCurrentUserIdSelector).pipe(omitNullOrUndefined(), take(1)),
    ])
      .pipe(
        switchMap(([hasSupplierRole, userId]) =>
          !!hasSupplierRole && !!userId
            ? forkJoin([
                this.store.select(getAllTasks).pipe(omitNullOrUndefined(), take(1)),
                this.store.select(hasApprovedTasksSelector).pipe(omitNullOrUndefined(), take(1)),
                this.supplierGatewayService.getSingleSupplier(userId),
              ])
            : of([])
        )
      )
      .subscribe(([tasks, hasApprovedTask, supplier]) => {
        this._fuseNavigationService.updateNavigationItem('storefrontPreview', {
          suffixIcon: !!tasks && !!tasks.length && supplier?.companyName ? 'visibility' : null,
          suffixIconUrl:
            !supplier || !supplier.companyName
              ? null
              : this.navigationService.getStorefrontPath(supplier.userId, supplier.handle) +
                `${!hasApprovedTask ? '/preview' : ''}`,
        });
      });
  }

  setCurrentMenuByRole(role: ROLE_TYPE): void {
    switch (role) {
      case 1:
        this._fuseNavigationService.setCurrentNavigation(USER_ROLES_STRINGS.SUPPLIER);
        this.setStorefrontPreviewUrl();
        break;
      case 2:
        this._fuseNavigationService.setCurrentNavigation(USER_ROLES_STRINGS.AGENT);
        break;
      case 3:
        this._fuseNavigationService.setCurrentNavigation(USER_ROLES_STRINGS.GLOBAL_ADMIN);
        break;
    }
  }

  private addInstalledSynceeAppsToMenu(installedAppsAndGroups: InstalledAppsAndGroups): void {
    const appsMenuIndex = navigation_agency[0].children.findIndex((navChild) => navChild.id === 'apps');

    this.removeAllSynceeAppsFromMenu(appsMenuIndex);
    this.appendMenuWithItems(installedAppsAndGroups, appsMenuIndex);
    this.appendMenuWithGroups(installedAppsAndGroups, appsMenuIndex);
  }

  private removeAllSynceeAppsFromMenu(appsMenuIndex: number): void {
    navigation_agency[0].children[appsMenuIndex].children.forEach((staticChild) =>
      this.navigation_agency[0].children[appsMenuIndex].children
        .filter((child) => child.id !== staticChild.id)
        .forEach((child) => this.navigation_agency[0].children[appsMenuIndex].children.pop(child))
    );
  }

  private appendMenuWithItems(installedAppsAndGroups: InstalledAppsAndGroups, appsMenuIndex: number): void {
    installedAppsAndGroups.installedApps.forEach((installedApp) => {
      const navChild = this.createMenuItemFromInstalledApp(installedApp);
      if (!installedApp.synceeAppGroupsId) {
        this.navigation_agency[0].children[appsMenuIndex].children.push(navChild);
      }
    });
  }

  private appendMenuWithGroups(installedAppsAndGroups: InstalledAppsAndGroups, appsMenuIndex: number): void {
    installedAppsAndGroups.installedGroups.map((installedGroup) => {
      const navGroup = this.createMenuGroupFromInstalledGroups(installedGroup);
      const navChildrenUnderGroup = installedAppsAndGroups.installedApps
        .filter((app) => app.synceeAppGroupsId === installedGroup.id)
        .map((app) => this.createMenuItemFromInstalledApp(app));

      navGroup.children.push(...navChildrenUnderGroup);

      this.navigation_agency[0].children[appsMenuIndex].children.push(navGroup);
    });
  }

  private createMenuItemFromInstalledApp(app: SynceeAppsDto): FuseNavigationItem {
    return {
      id: app.type.toLowerCase(),
      title: app.type,
      type: 'item',
      translate: `NAV.${app.type}`,
      url: app.url,
    };
  }

  private createMenuGroupFromInstalledGroups(group: SynceeAppGroupsDto): FuseNavigationItem {
    return {
      id: group.type.toLowerCase(),
      title: group.type,
      type: 'collapsable',
      translate: `NAV.${group.type}`,
      children: [],
    };
  }

  hideMenu(): void {
    this.fuseConfigService.config = { layout: { navbar: { hidden: true } } };
  }

  showMenu(): void {
    this.fuseConfigService.config = { layout: { navbar: { hidden: false } } };
  }

  setCurrentMenuById(id: string): void {
    this._fuseNavigationService.setCurrentNavigation(id);
  }

  initSidebarElements(): void {
    this.navigation = navigation; // supplier
    this.navigation_general = navigation_general; // general
    // this.navigation_agency = navigation_agency; // retailer - set separately
    this.navigation_global_admin = navigation_global_admin; // admin
    this.navigation_global_admin_as_supplier = navigation_global_admin_as_supplier; // admin with supplier signed in
    this.navigation_global_admin_as_agency = navigation_global_admin_as_agency; // admin with retailer signed in
    this.navigation_admin_with_collapse = navigation_admin_with_collapse;
    this.navigation_global_admin_as_own_supplier = navigation_global_admin_as_own_supplier; // admin with own supplier signed in
    this.navigation_global_admin_as_own_retailer = navigation_global_admin_as_own_retailer; // admin with own supplier signed in

    // Register the navigation to the service
    this._fuseNavigationService.register(USER_ROLES_STRINGS.SUPPLIER, this.navigation);
    this._fuseNavigationService.register(USER_ROLES_STRINGS.GENERAL, this.navigation_general);
    this._fuseNavigationService.register(USER_ROLES_STRINGS.GLOBAL_ADMIN, this.navigation_global_admin);
    this._fuseNavigationService.register(
      USER_ROLES_STRINGS.GLOBAL_ADMIN_AS_SUPPLIER,
      this.navigation_global_admin_as_supplier
    );
    this._fuseNavigationService.register(
      USER_ROLES_STRINGS.GLOBAL_ADMIN_AS_AGENCY,
      this.navigation_global_admin_as_agency
    );
    this._fuseNavigationService.register(
      USER_ROLES_STRINGS.GLOBAL_ADMIN_AS_OWN_SUPPLIER,
      this.navigation_global_admin_as_own_supplier
    );
    this._fuseNavigationService.register(
      USER_ROLES_STRINGS.GLOBAL_ADMIN_AS_OWN_RETAILER,
      this.navigation_global_admin_as_own_retailer
    );
  }
}
