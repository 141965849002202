import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartConfiguration, ChartDataset } from 'chart.js';
import { camelCase } from 'lodash';
import * as moment from 'moment/moment';
import { Observable, Subject, combineLatest } from 'rxjs';
import { FuseConfigService } from '../../../../@fuse/services/config.service';
import {
  SupplierDashboardAnalyticsService,
  SupplierTimelineRangeDto,
} from '../../../service/analytics/supplier-dashboard-analytics.service';
import { ColorPaletteService } from '../../../service/color-palette/color-palette.service';
import { RolesEnum } from '../../../vo/roles/roles';
import { StatisticType } from '../orders-chart/orders-chart.component';
import { OverviewRecord } from '../overview/overview.component';
import { SupplierDashboardService } from '../services/supplier-dashboard.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { omitNullOrUndefined } from '../../../utils/operator/omit-null-or-undefined';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { hasApprovedTasksSelector } from '../../../store/tasks/tasks.selector';

@UntilDestroy()
@Component({
  selector: 'app-supplier-dashboard',
  templateUrl: './supplier-dashboard.component.html',
  styleUrls: ['./supplier-dashboard.component.scss'],
})
export class SupplierDashboardComponent implements OnInit, AfterViewInit {
  @ViewChild('topRightTemplate') topRightTemplate: TemplateRef<any>;

  public dateFrom: Date;

  public dateTo: Date;

  public overViewRecords: OverviewRecord[];

  public timeFrames: SupplierTimelineRangeDto[];

  public anyOrdersReceived: boolean;

  public statisticTypes: StatisticType[] = ['PRODUCTS_SOLD', 'REVENUE', 'ORDERS'];

  public dataset: ChartDataset<'bar', (number | [number, number])[]> = {
    data: [],
    backgroundColor: this.colorPaletteService.getColorVariable('--app-syncee-primary-50'),
    barThickness: 'flex',
    borderRadius: 8,
  };

  public barChartOptions: ChartConfiguration<'bar'>['options'];

  public datasetsChanged: Subject<void> = new Subject<void>();

  public labels: string[];

  public currency$: Observable<string>;

  private DAYS_BEFORE = 30;

  public hasApprovedCatalogs$ = this.store.select(hasApprovedTasksSelector);

  constructor(
    private fuseConfigService: FuseConfigService,
    private colorPaletteService: ColorPaletteService,
    private supplierDashboardAnalyticsService: SupplierDashboardAnalyticsService,
    private translateService: TranslateService,
    private _supplierService: SupplierDashboardService,
    private store: Store<AppState>
  ) {
    this.setCurrency();
  }

  ngOnInit(): void {
    this.initBarChartOptions();
  }

  ngAfterViewInit(): void {
    this.initTheme();
    this.setDateFrom();
    this.setOverviewAnalytics();
    this.getDailyOrderAnalytics();
  }

  public handleDateChange(): void {
    this.getDailyOrderAnalytics();
  }

  public handleStatisticTypeChange(event: StatisticType): void {
    this.dataset.data = this.timeFrames.map((timeFrame) => timeFrame[camelCase(event.toLowerCase())]);
    this.barChartOptions.scales.y.title.text = this.translateService.instant(
      `DASHBOARD.CHART_STATISTIC_TYPES.${event}`
    );
    this.datasetsChanged.next();
  }

  private setCurrency(): void {
    this.currency$ = this._supplierService.getSupplierPaymentCurrency();
  }

  private initBarChartOptions(): void {
    this.translateService
      .stream('DASHBOARD.SUPPLIER.DASHBOARD.BAR_CHART_OPTIONS')
      .pipe(untilDestroyed(this), omitNullOrUndefined())
      .subscribe((translation): void => {
        this.barChartOptions = {
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 2,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              title: {
                text: translation['TIMELINE'],
                display: true,
                color: this.colorPaletteService.getColorVariable('--app-black-500'),
                font: {
                  size: 14,
                  family: 'Inter',
                  weight: 'bold',
                },
              },
              grid: {
                display: false,
              },
              ticks: {
                display: true,
                autoSkip: true,
                maxTicksLimit: 5,
              },
            },
            y: {
              title: {
                text: translation['PRODUCTS_SOLD'],
                display: true,
                color: this.colorPaletteService.getColorVariable('--app-black-500'),
                font: {
                  size: 14,
                  family: 'Inter',
                  weight: 'bold',
                },
              },
              ticks: {
                display: true,
              },
            },
          },
        };
      });
  }

  private getDailyOrderAnalytics(): void {
    this.supplierDashboardAnalyticsService
      .getDailyOrderAnalytics(this.dateFrom, this.dateTo, 17)
      .subscribe((timeFrames) => {
        this.timeFrames = timeFrames;
        this.dataset.data = timeFrames.map((timeFrame) => timeFrame.productsSold);
        this.labels = this.getDateRangeLabels(timeFrames);
        this.anyOrdersReceived = timeFrames.some((range) => range.orders > 0);
        this.datasetsChanged.next();
      });
  }

  private setDateFrom(): void {
    this.dateFrom = moment().startOf('day').subtract(this.DAYS_BEFORE, 'days').toDate();
  }

  private setOverviewAnalytics(): void {
    combineLatest([
      this.supplierDashboardAnalyticsService.getOverviewAnalytics().pipe(take(1)),
      this.translateService
        .stream('DASHBOARD.SUPPLIER.DASHBOARD.OVERVIEW_ANALYTICS')
        .pipe(untilDestroyed(this), omitNullOrUndefined()),
    ]).subscribe(([overViewAnalytics, translation]): void => {
      this.overViewRecords = [
        {
          title: translation['TOTAL_REVENUE']['TITLE'],
          info: '',
          unitOfMeasurement: 'CURRENCY',
          value: overViewAnalytics.totalRevenue || 0,
          tooltip: translation['TOTAL_REVENUE']['TOOLTIP'],
        },
        {
          title: translation['AVERAGE_REVENUE']['TITLE'],
          info: '',
          unitOfMeasurement: 'CURRENCY',
          value: overViewAnalytics.averageRevenue || 0,
          tooltip: translation['AVERAGE_REVENUE']['TOOLTIP'],
        },
        {
          title: translation['MAX_REVENUE_ON_ORDER']['TITLE'],
          info: '',
          unitOfMeasurement: 'CURRENCY',
          value: overViewAnalytics.maxRevenueOnOrder || 0,
          tooltip: translation['MAX_REVENUE_ON_ORDER']['TOOLTIP'],
        },
      ];
    });
  }

  private getDateRangeLabels(timeFrames: SupplierTimelineRangeDto[]): string[] {
    return timeFrames ? timeFrames.map((range) => new Date(Date.parse(range.fromDate)).toLocaleDateString()) : [];
  }

  private initTheme(): void {
    this.translateService
      .stream('DASHBOARD.SUPPLIER.DASHBOARD.TITLE')
      .pipe(untilDestroyed(this), omitNullOrUndefined())
      .subscribe((title: string): void => {
        this.fuseConfigService.config = {
          layout: {
            title,
          },
          templates: {
            topRightContent: this.topRightTemplate,
          },
        };
      });
  }

  protected readonly RolesEnum = RolesEnum;
}
