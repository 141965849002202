import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatColumnDef, MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { CustomPanelComponent } from 'app/shared/components/custom-panel/custom-panel.component';
import { SimplePaginatorWithArrowsComponent } from 'app/shared/components/paginations/simple-paginator-with-arrows/simple-paginator-with-arrows.component';
import { Utils } from 'app/utils/utils';
import { isEqual } from 'lodash';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { FadeInOutAnimation } from '../../../../styles/animations/fade-in-out.animation';
import {
  ActionsButtonWithMenuAction,
  ActionsButtonWithMenuComponent,
} from '../actions-button-with-menu/actions-button-with-menu.component';
import { CustomDividerComponent } from '../custom-divider/custom-divider.component';
import { ErrorMessageComponent } from '../error-message/error-message.component';
import {
  Choice,
  FilterFactoryComponent,
  IndexTableFilter,
  TableFilterItem,
  TableViewItem,
} from '../filter-factory/filter-factory.component';
import {
  IndexTableSearchGroupComponent,
  TableSort,
} from '../index-table-search-group/index-table-search-group.component';
import { NoSearchResultsComponent } from '../no-search-results/no-search-results.component';
import { SingleAlertWithIconComponent } from '../single-alert-with-icon/single-alert-with-icon.component';
import { SkeletonModule } from '../skeleton/skeleton.module';

/*
 * In case of an expandable row, add an "overflow: hidden" style to the container of your expendable row
 *
 *
 *@example
 * <ng-container matColumnDef="example-expanded-column">
 *    <td ...>
 *      <div style="overflow: hidden;"></div>
 *    </td>
 * </ng-container>
 *
 */
@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
  standalone: true,
  animations: [FadeInOutAnimation],
  imports: [
    CommonModule,
    SkeletonModule,
    MatTableModule,
    CustomPanelComponent,
    MatPaginatorModule,
    SimplePaginatorWithArrowsComponent,
    FlexLayoutModule,
    TranslateModule,
    MatProgressSpinnerModule,
    NoSearchResultsComponent,
    MatCheckboxModule,
    FormsModule,
    ActionsButtonWithMenuComponent,
    ErrorMessageComponent,
    FilterFactoryComponent,
    IndexTableSearchGroupComponent,
    CustomDividerComponent,
    SingleAlertWithIconComponent,
  ],
})
export class CustomTableComponent<T> implements OnInit, AfterContentInit, AfterViewInit, OnDestroy {
  @ViewChild(MatTable, { static: true }) table: MatTable<T>;
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;

  @Input() emptyMessage = 'COMMON_ALERTS.NO_RESULT.TITLE';

  private _displayedColumns: string[];
  public get displayedColumns(): string[] {
    return this._displayedColumns;
  }
  @Input() public set displayedColumns(value: string[]) {
    this._displayedColumns = value;
    this.innerDisplayedColumns = [...value];
  }
  @Input() dataSource: MatTableDataSource<T>;
  @Input() isServerSidePaginate = true;
  @Input() fetcher: (page: number, pageSize: number, forceReload: boolean) => Observable<CustomTableDataResponse<T>>;
  @Input() page = 0;
  @Input() size = 10;
  @Input() allItemsCount = 0;
  @Input() isPaginate = true;
  @Input() staticPaginateSubject = new BehaviorSubject<void>(null);
  @Input() expandedColumn?: string;
  @Input() forceReload?: Subject<void>;
  @Input() setPageToZero?: Subject<void>;
  @Input() selectable = false;
  @Input() noResultComponent: TemplateRef<HTMLElement>;
  @Input() selectedActions: ActionsButtonWithMenuAction[] = [];
  @Input() actionsButtonText = 'CUSTOM_TABLE.ACTIONS';
  @Input() fetcherLogic = true;
  @Input() public overflowScroll = false;

  @Input() skeletonTemplateColumns?: TemplateRef<void>;
  @Input() numberOfSkeletonRows = 10;
  @Input() numberOfSkeletonColumns = 6;

  // "Index table" filter variables
  @Input() enableFilters = false;
  @Input() enableFilterDatePicker = false;
  @Input() enableSort = false;
  @Input() showFilterButton = true;
  @Input() showExpandButton = false;
  @Input() showSearchbar = true;
  @Input() permanentSearchbar = true;
  @Input() filterArray: IndexTableFilter<any>[];
  @Input() viewArray: TableViewItem[];
  @Input() openFilters: boolean;
  @Input() activeFilters: TableFilterItem[];
  @Input() dateFrom: Date;
  @Input() dateTo: Date;
  @Input() isLoading = false;
  @Input() hasBorder = true;
  @Input() sortArray: Pick<Choice<TableSort['sortBy']>, 'label' | 'value'>[];

  @Output() searchTermChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() filterChange: EventEmitter<TableFilterItem> = new EventEmitter<TableFilterItem>();
  @Output() viewChange: EventEmitter<TableViewItem> = new EventEmitter<TableViewItem>();
  @Output() clearAllClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() dateFromChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() dateToChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() sortChange: EventEmitter<TableSort> = new EventEmitter<TableSort>();

  @Output() clickRow = new EventEmitter<T>();
  @Output() selectedItemsChange = new EventEmitter<T[]>();
  @Output() nextClicked = new EventEmitter<void>();
  @Output() previousClicked = new EventEmitter<void>();
  @Output() sizeChanged = new EventEmitter<number>();
  isEmpty = false;
  loading = false;
  hasError = false;
  selectedCheckboxes: boolean[] = [];
  selectedItems: T[] = [];
  allCheckboxStatus: AllCheckboxStatus = 'unchecked';
  innerDisplayedColumns: string[];
  filterGroupOpen = false;

  private unsubscribeAll: Subject<void>;

  constructor() {
    this.unsubscribeAll = new Subject();
  }
  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.initSelectable();
    this.initFetch();
    this.subscribeToForceReload();
    this.subscribeToSetPageToZero();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private initSelectable(): void {
    if (this.selectable) {
      this.innerDisplayedColumns.unshift('checkbox');
    }
  }

  private initSelectedCheckboxes(): void {
    this.selectedCheckboxes = new Array(this.dataSource.data.length).fill(false);
  }

  private clearSelectedItems(): void {
    this.setSelectedItems([]);
    this.initSelectedCheckboxes();
    this.setAllCheckboxStatus();
  }

  private subscribeToForceReload(): void {
    if (!!this.forceReload) {
      this.forceReload.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => this.reload());
    }
  }

  private subscribeToSetPageToZero(): void {
    if (!!this.setPageToZero) {
      this.setPageToZero.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => (this.page = 0));
    }
  }

  private setSelectedItems(items: T[]): void {
    this.selectedItems = items;
    this.selectedItemsChange.emit(this.selectedItems);
  }

  private handlePaginationChange(): void {
    if (!this.isServerSidePaginate) {
      this.staticPaginateSubject.next(null);
    } else {
      this.initFetch();
    }
  }

  private setAllCheckboxStatus(): void {
    if (this.selectedItems.length === 0) {
      this.allCheckboxStatus = 'unchecked';
    } else if (this.selectedItems.length === this.dataSource.data.length) {
      this.allCheckboxStatus = 'checked';
    } else {
      this.allCheckboxStatus = 'indeterminate';
    }
  }

  initFetch(forceReload = false): void {
    if (!this.fetcher) {
      return;
    }
    this.loading = true;
    this.fetcher(this.page, this.size, forceReload)
      .pipe(
        switchMap((response) => {
          if (this.isServerSidePaginate) {
            return of(response);
          } else {
            return this.staticPaginateSubject.asObservable().pipe(
              map(() => {
                return this.handleStaticPageChange(response);
              })
            );
          }
        })
      )
      .subscribe(
        (response) => {
          this.isEmpty = response.data.length === 0;
          this.dataSource = new MatTableDataSource<T>(response.data);
          this.allItemsCount = response.allItemsCount;
          this.loading = false;
          this.hasError = false;
          if (this.selectable) {
            this.clearSelectedItems();
          }
        },
        () => {
          this.loading = false;
          this.hasError = true;
        }
      );
  }

  handleStaticPageChange(response: CustomTableDataResponse<T>): CustomTableDataResponse<T> {
    const start = this.page * this.size;
    const end = start + this.size;
    return {
      page: this.page,
      size: this.size,
      allItemsCount: response.allItemsCount,
      data: response.data.slice(start, end),
    };
  }

  ngAfterContentInit(): void {
    if (!this.table) {
      return;
    }
    this.columnDefs.forEach((columnDef) => {
      this.table.addColumnDef(columnDef);
    });
  }

  nextPage(): void {
    if (this.fetcherLogic) {
      ++this.page;
      this.handlePaginationChange();
    } else {
      this.nextClicked.emit();
    }
  }

  previousPage(): void {
    if (this.fetcherLogic) {
      --this.page;
      this.handlePaginationChange();
    } else {
      this.previousClicked.emit();
    }
  }

  sizeChangeHandler(value: number): void {
    if (this.fetcherLogic) {
      this.size = value;
      this.page = 0;
      this.handlePaginationChange();
    } else {
      this.sizeChanged.emit(value);
    }
  }

  clickOnRow(row: T): void {
    this.clickRow.emit(row);
  }

  reload(page?: number): void {
    this.page = Utils.isNullOrUndefinedOrLengthZero(page) ? this.page : page;
    this.initFetch(true);
  }

  handleCheckboxClicked(checked: boolean, item: T, index: number): void {
    if (checked) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems = this.selectedItems.filter((selectedItem) => !isEqual(selectedItem, item));
    }
    this.selectedCheckboxes[index] = checked;
    this.setAllCheckboxStatus();
    this.selectedItemsChange.emit(this.selectedItems);
  }

  handleAllCheckboxClicked(): void {
    if (this.selectedItems.length === 0) {
      this.selectedCheckboxes.fill(true);
      this.setSelectedItems(this.dataSource.data);
    } else if (this.selectedItems.length >= this.dataSource.data.length) {
      this.clearSelectedItems();
    } else {
      this.selectedCheckboxes.fill(true);
      this.setSelectedItems([
        ...this.selectedItems,
        ...this.dataSource.data.filter(
          (item) => !this.selectedItems.some((selectedItem) => isEqual(selectedItem, item))
        ),
      ]);
    }
    this.setAllCheckboxStatus();
  }

  get isMultiTemplateDataRows(): boolean {
    return !!this.expandedColumn;
  }
}

export interface CustomTableDataResponse<T> {
  data: T[];
  allItemsCount: number;
  page: number;
  size: number;
}

type AllCheckboxStatus = 'checked' | 'unchecked' | 'indeterminate';
