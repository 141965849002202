import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { SuppliersService } from 'app/service/suppliers/suppliers.service';
import { getCurrentUserIdSelector } from 'app/store/user/user.selector';
import { map, Observable, shareReplay, switchMap } from 'rxjs';
import { omitNullOrUndefined } from '../../../utils/operator/omit-null-or-undefined';
import { SupplierDetailsService } from '../../../service/suppliers/supplier-details.service';

@Injectable()
export class SupplierDashboardService {
  private _supplierPaymentCurrency$: Observable<string>;

  constructor(
    private _store: Store<AppState>,
    private _supplierService: SuppliersService,
    private supplierDetailsService: SupplierDetailsService
  ) {}

  getSupplierPaymentCurrency(): Observable<string> {
    if (!this._supplierPaymentCurrency$) {
      this._supplierPaymentCurrency$ = this._store.select(getCurrentUserIdSelector).pipe(
        omitNullOrUndefined(),
        switchMap((userId: number) => this.supplierDetailsService.get(userId)),
        map((supplierData) => supplierData?.paymentCurrency || 'USD'),
        shareReplay({
          refCount: true,
          bufferSize: 1,
        })
      );
    }
    return this._supplierPaymentCurrency$;
  }
}
