<div id="price-tables" class="page-layout simple fullwidth py-16 px-24" *ngIf="isLoaded; else progress">
  <ng-container *ngIf="!hasError; else error">
    <ng-container *ngIf="hasStore; else noStore">
      <div class="pb-20">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
          <div fxLayoutAlign="start center" class="store-selector-container">
            <h1 class="pr-8">{{ 'BILLING.SUBSCRIPTION.SUBSCRIPTION_FOR_LABEL' | translate }}</h1>
            <mat-form-field id="domain-selector" appearance="fill">
              <mat-select
                [value]="selectedDomain.id"
                (valueChange)="storeSelectionChange($event)"
                disableOptionCentering
                panelClass="mat-select-display-change"
              >
                <mat-option *ngFor="let subs of ecomList" [value]="subs.id"> {{ subs.domain }} </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="how-pricing-works">
            <a
              href="https://help.syncee.io/en/collections/130560-pricing-and-subscriptions-for-retailers"
              target="_blank"
              >{{ 'BILLING.SUBSCRIPTION.HOW_PRICING_WORKS_LABEL' | translate }}</a
            >
          </div>
        </div>

        <div
          class="downgrade-container"
          *ngIf="
            (selectedDomain.subscriptions?.rmp.planId !== 120 && selectedDomain.subscriptions?.rmp.churnDate) ||
            (selectedDomain.subscriptions?.df.planId !== 100 && selectedDomain.subscriptions?.df.churnDate)
          "
        >
          <div>
            {{ 'BILLING.SUBSCRIPTION.DOWNGRADE_MESSAGE' | translate : { plans: churnedPlan } }}
            {{ selectedDomain.subscribed | date : 'short' }}.
          </div>
        </div>
        <div class="wix-error-container" *ngIf="isWixUninstalled">
          <div>{{ 'BILLING.SUBSCRIPTION.WIX_UNINSTLLED' | translate }}</div>
        </div>
      </div>

      <div *ngIf="discountToken && coupon" class="discount-title-wrapper">
        <div class="discount-title" fxLayout="row" fxLayoutAlign="center center">
          <img src="assets/images/subscription/discount-icon.png" />
          <span
            >{{ 'BILLING.SUBSCRIPTION.COUPON.COUPON_TOKEN_TITLE.LIMITED' | translate }} -
            <span class="highlight">
              {{ 'BILLING.SUBSCRIPTION.COUPON.COUPON_TOKEN_TITLE.SAVE' | translate }}
              {{ getTokenCouponDiscountStr() }}</span
            >
            {{ 'BILLING.SUBSCRIPTION.COUPON.COUPON_TOKEN_TITLE.UNTIL' | translate }}
            {{ getTokenCouponUntilDate() | date : 'yyyy-MM-dd' : '+0000' }}</span
          >
        </div>
      </div>

      <div
        class="pb-20"
        *ngIf="(needMarketingBox && selectedPlans.rmp.productId <= BUSINESS_PLAN_ID) || selectedCycleType === 0"
      >
        <app-marketing-card
          [buttonText]="
            selectedPlans.rmp.productId < BUSINESS_PLAN_ID || selectedCycleType === 0
              ? ('BILLING.SUBSCRIPTION.MARKETING_CARD.BUTTON' | translate)
              : null
          "
          [text]="
            selectedPlans.rmp.productId === BUSINESS_PLAN_ID && selectedCycleType === 1
              ? ('BILLING.SUBSCRIPTION.MARKETING_CARD.ALT_TEXT' | translate)
              : ('BILLING.SUBSCRIPTION.MARKETING_CARD.TEXT' | translate)
          "
          (buttonClick)="handleMarketingBoxClicked()"
        ></app-marketing-card>
      </div>

      <div class="current-sub-container" *ngIf="subsLoaded; else buttonProgress">
        <div class="current-sub-table" *ngIf="!isOnMobile; else mobileSubTable">
          <!-- <div fxLayoutAlign="space-between center">
                        <h2>{{ 'BILLING.SUBSCRIPTION.CURRENT_SUBSCRIPTION_LABEL' | translate}}</h2>
                        <div class="how-pricing-works">
                            <a href="https://help.syncee.io/en/collections/130560-pricing-and-subscriptions-for-retailers"
                               target="_blank">{{ 'BILLING.SUBSCRIPTION.HOW_PRICING_WORKS_LABEL' | translate}}</a>
                        </div>
                    </div> -->
          <table>
            <thead>
              <tr class="border-bottom">
                <td>{{ 'BILLING.SUBSCRIPTION.PRODUCT_LABEL' | translate }}</td>
                <!-- Product -->
                <td></td>
                <!-- PLAN -->
                <td class="coupon-td"></td>
                <!-- Coupon -->
                <td></td>
                <!-- Cycle -->
                <td class="centered-text chip-td"></td>
                <!-- USAGE -->
                <td>
                  <span fxLayoutAlign="end">{{ 'BILLING.SUBSCRIPTION.YOUR_PRICE_LABEL' | translate }}</span>
                </td>
                <!-- Price -->
              </tr>
            </thead>
            <tbody>
              <!-- PRODUCTS -->

              <tr
                class="border-bottom"
                *ngFor="let product of synceeProducts"
                [class.noDfPlan]="product === 'df' && isOnlyMarketplace(selectedDomain.ecomType)"
              >
                <!-- Product -->
                <td>{{ synceeProductNames[product] }}</td>

                <!-- PLAN -->
                <td>
                  <div fxLayoutAlign="start center">
                    <mat-form-field
                      id="sm-selector"
                      appearance="fill"
                      class="input-without-padding"
                      *ngIf="selectedPlans[product]?.productId"
                    >
                      <mat-select
                        [value]="selectedPlans[product].productId"
                        (valueChange)="planSelectionChange($event, product)"
                        disableOptionCentering
                        panelClass="mat-select-display-change"
                      >
                        <mat-option *ngFor="let plan of plans[product]" [value]="plan.productId">
                          {{ plan.productName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-icon (click)="openCompareDialog(product)" class="cursor-pointer">info </mat-icon>
                  </div>
                  <div *ngIf="nextPlan && nextPlan[product]">
                    <span (click)="upgradeToPlan(nextPlan[product], product)" class="cursor-pointer"
                      >{{ 'BILLING.SUBSCRIPTION.UPGRADE_TO_LABEL' | translate }}
                      {{ nextPlan[product].productName }}</span
                    >
                  </div>
                </td>

                <!-- Coupon -->
                <td class="coupon-td">
                  <div *ngIf="this.availabledCouponEcomTypes.includes(selectedDomain.ecomType)">
                    <button
                      *ngIf="
                        !discountToken && (!coupon || !coupon.data || !coupon.data[selectedPlans[product].productId]);
                        else couponexist
                      "
                      mat-stroked-button
                      class="coupon-button"
                      (click)="openCouponCodeDialog(product)"
                      [disabled]="isCurrentPlanSelected(product) || isStarterPlan(product)"
                    >
                      {{ 'BILLING.SUBSCRIPTION.COUPON.COUPON_CODE' | translate }}
                    </button>

                    <ng-template #couponexist>
                      <ng-container
                        *ngIf="!isCurrentPlanSelected(product) && !isStarterPlan(product) && isCoupon(product)"
                      >
                        <div
                          class="coupon-activate-wrapper"
                          fxLayout="row"
                          fxLayoutAlign="start center"
                          fxLayoutGap="5px"
                        >
                          <div class="coupon-activated">
                            {{ 'BILLING.SUBSCRIPTION.COUPON.COUPON_CODE' | translate }}:
                            <span class="coupon-activated-text">{{
                              coupon.data[selectedPlans[product].productId].name
                            }}</span>
                          </div>
                          <mat-icon *ngIf="!discountToken" (click)="removeCoupon(selectedPlans[product].productId)"
                            >close</mat-icon
                          >
                        </div>
                        <div>
                          ( -{{ coupon.data[selectedPlans[product].productId].amount }}
                          <span [ngSwitch]="coupon.data[selectedPlans[product].productId].type">
                            <span *ngSwitchCase="'percentage'">%</span>
                            <span *ngSwitchCase="'fixed'">USD</span> </span
                          >)
                        </div>
                      </ng-container>
                    </ng-template>
                  </div>
                </td>

                <!-- Cycle -->
                <td class="centered-text">
                  <ng-container
                    *ngIf="
                      selectedPlans[product].productId !== 100 &&
                      selectedPlans[product].productId !== 120 &&
                      selectedPlans[product].productId !== 132
                    "
                  >
                    <mat-slide-toggle
                      [checked]="selectedCycleType == 1"
                      class="mat-slide-fixed-color"
                      (change)="cycleChanged($event)"
                    >
                    </mat-slide-toggle>
                    <div>{{ 'BILLING.SUBSCRIPTION.MONTHLY_ANNUALLY_LABEL' | translate }}</div>
                  </ng-container>
                </td>

                <td class="centered-text chip-td">
                  <mat-chip class="syncee-blue-chip subscrption-chip">
                    <ng-container [ngSwitch]="true">
                      <ng-container *ngSwitchCase="selectedPlans[product].productId === 120">{{
                        'BILLING.SUBSCRIPTION.BROWSE_AND_COLLECT' | translate
                      }}</ng-container>
                      <ng-container *ngSwitchCase="selectedPlans[product].productId === 100">{{
                        'BILLING.SUBSCRIPTION.PREPARE_PRODUCTS' | translate
                      }}</ng-container>
                      <ng-container
                        *ngSwitchCase="
                          isTrialAvailable &&
                          !isTrialActive &&
                          selectedCycleType === 0 &&
                          selectedPlans[product].productId !== 120 &&
                          selectedPlans[product].productId !== 100
                        "
                        >{{ 'BILLING.SUBSCRIPTION.TRIAL_AVAILABLE' | translate }}</ng-container
                      >
                      <ng-container
                        *ngSwitchCase="
                          selectedPlans[product].productId > selectedDomain.subscriptions[product].planId &&
                          selectedCycleType === 1
                        "
                        >${{ selectedPlans[product].planData.annualMonthlyPrice
                        }}{{ 'BILLING.SUBSCRIPTION.PER_MONTH' | translate }}</ng-container
                      >
                      <ng-container *ngSwitchDefault>{{
                        'BILLING.SUBSCRIPTION.LIMIT_USED'
                          | translate
                            : {
                                limit:
                                  selectedPlans[product].productId === selectedDomain.subscriptions[product].planId
                                    ? selectedDomain.subscriptions[product].managedProductsLimit
                                    : selectedPlans[product].planData.managedProductsLimit,
                                used: selectedDomain.subscriptions[product].usedProductNumber
                              }
                      }}</ng-container>
                    </ng-container>
                  </mat-chip>
                </td>

                <!-- Price -->
                <td>
                  <ng-container *ngIf="planHasAnnual[product] || selectedCycleType === 0; else planIsNotAvailable">
                    <div *ngIf="isCoupon(product) && !isCurrentPlanSelected(product)">
                      <div>
                        <span
                          fxLayoutAlign="end"
                          *ngIf="
                            selectedPlans[product].productId != selectedDomain.subscriptions[product].planId ||
                            currentCycleType !== selectedCycleType
                          "
                        >
                          <span class="original-price">
                            {{
                              selectedCycleType == 0
                                ? selectedPlans[product].productMonthlyPrice
                                : selectedPlans[product].productYearlyPrice
                            }}
                            USD
                          </span>
                        </span>
                      </div>
                    </div>
                    <div>
                      <ng-container *ngIf="isCurrentPlanSelected(product)">
                        <div *ngIf="selectedDomain.subscriptions[product].coupon" fxLayoutAlign="end">
                          <div class="align-end">
                            <div>
                              {{ 'BILLING.SUBSCRIPTION.COUPON.USED_COUPON' | translate }}:
                              <b>{{ selectedDomain.subscriptions[product].coupon.name }}</b>
                            </div>
                            <div>
                              {{
                                calculatePrice(
                                  selectedDomain.subscriptions[product].coupon.type,
                                  selectedDomain.subscriptions[product].coupon.amount,
                                  selectedDomain.subscriptions[product]?.price
                                )
                              }}
                              USD
                            </div>
                          </div>
                        </div>
                        <span *ngIf="!selectedDomain.subscriptions[product].coupon" fxLayoutAlign="end">
                          {{ selectedDomain.subscriptions[product]?.price }} USD
                        </span>
                      </ng-container>

                      <span fxLayoutAlign="end" *ngIf="!isCurrentPlanSelected(product)">
                        {{
                          selectedCycleType == 0
                            ? getCalculatedPrice(
                                product,
                                selectedPlans[product].productId,
                                selectedPlans[product].productMonthlyPrice
                              )
                            : getCalculatedPrice(
                                product,
                                selectedPlans[product].productId,
                                selectedPlans[product].productYearlyPrice
                              )
                        }}
                        USD
                      </span>
                    </div>
                  </ng-container>
                </td>
              </tr>
              <tr
                class="border-bottom"
                *ngIf="
                  selectedPlans['rmp'].planData.managedProductsLimit >= 50000 && selectedDomain.ecomType === 'shopify'
                "
              >
                <td></td>
                <td colspan="5">
                  <div class="variants-limit-warn mt-4">
                    {{ 'BILLING.SUBSCRIPTION.SHOPIFY_VARIANTS_LIMIT' | translate }}
                  </div>
                  <div class="variants-limit-warn">
                    {{ 'BILLING.SUBSCRIPTION.SHOPIFY_VARIANTS_LIMIT_LINK' | translate }}
                    <a
                      [href]="'https://syncee.co/2019/03/14/shopify-limits-the-number-of-product-variants/'"
                      [target]="'_blank'"
                      >https://syncee.co/2019/03/14/shopify-limits-the-number-of-product-variants/</a
                    >
                  </div>
                </td>
              </tr>
              <tr class="border-bottom noDfPlanInfoRow" *ngIf="isOnlyMarketplace(selectedDomain.ecomType)">
                <td>{{ synceeProductNames['df'] }}</td>
                <td colspan="5">
                  <div style="display: flex; align-items: center" class="my-8">
                    <mat-icon class="mr-4">error</mat-icon>
                    {{ 'BILLING.SUBSCRIPTION.NO_DF_PLAN_FOR_ECOMTYPE' | translate }}
                  </div>
                </td>
              </tr>

              <!-- / PRODUCTS -->

              <!-- SUMMARY -->
              <tr>
                <td>
                  <ng-container [ngSwitch]="selectedCycleType">
                    <span *ngSwitchCase="0">
                      {{ 'BILLING.SUBSCRIPTION.TOTAL_MONTHLY_PRICE_LABEL' | translate }}
                    </span>
                    <span *ngSwitchCase="1">
                      {{ 'BILLING.SUBSCRIPTION.TOTAL_ANNUAL_PRICE_LABEL' | translate }}
                    </span>
                  </ng-container>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <span fxLayoutAlign="end center">
                    {{ countTotalPrice() }} USD
                    <mat-icon
                      [matTooltip]="'BILLING.SUBSCRIPTION.TOTAL_MONTHLY_PRICE_TOOLTIP' | translate"
                      matTooltipPosition="below"
                      class="ml-4"
                      >help_outline</mat-icon
                    >
                  </span>
                </td>
              </tr>

              <!-- / SUMMARY -->
            </tbody>
          </table>
          <div>
            <div class="subscribe-box">
              <!-- *ngIf="!isAdmin" -->
              <div fxFlex="1 0 0">
                <app-accept-terms
                  [checked]="termsChecked"
                  (checkedChange)="handleTermsCheckboxChange($event)"
                ></app-accept-terms>
              </div>
              <div fxFlex="0 0 200px" fxLayoutAlign="end center">
                <button
                  [disabled]="isWixUninstalled || !isAllPlanAvailable"
                  *ngIf="!disableButton; else buttonProgress"
                  mat-button
                  class="syncee-blue-button"
                  (click)="handleSubscriptionButtonClicked()"
                >
                  <!--                            {{ selectedDomain.ecomType == 'wix'-->
                  <!--                            ? ('BILLING.SUBSCRIPTION.WIX_UPGRADE_BUTTON' | translate) :-->
                  <!--                            ('BILLING.SUBSCRIPTION.CHANGE_SUBSCRIPTION_LABEL' | translate)}}-->
                  <ng-container [ngSwitch]="true">
                    <span
                      *ngSwitchCase="
                        selectedDomain.subscriptions.rmp.planId !== 120 &&
                        selectedDomain.subscriptions.df.planId !== 100 &&
                        selectedPlans.rmp.productId === 120 &&
                        selectedPlans.df.productId === 100
                      "
                    >
                      {{ 'BILLING.SUBSCRIPTION.CANCEL_SUBSCRIPTION' | translate }}
                    </span>
                    <span *ngSwitchCase="isTrialAvailable && selectedCycleType === 0">
                      {{ 'BILLING.SUBSCRIPTION.FREE_TRIAL' | translate }}
                    </span>
                    <span
                      *ngSwitchCase="
                        selectedDomain.subscriptions.rmp.planId === 120 &&
                        selectedDomain.subscriptions.df.planId === 100 &&
                        (!isTrialAvailable || selectedCycleType === 1)
                      "
                    >
                      {{ 'BILLING.SUBSCRIPTION.SUBSCRIBE' | translate }}
                    </span>
                    <span *ngSwitchDefault>
                      {{ 'BILLING.SUBSCRIPTION.CHANGE_SUBSCRIPTION_LABEL' | translate }}
                    </span>
                  </ng-container>
                </button>
              </div>
            </div>
            <div *ngIf="showErrorTermsMessage">
              <div class="wix-error-container">
                <div>{{ 'BILLING.SUBSCRIPTION.ACCEPT_TERMS_ERROR' | translate }}</div>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between end">
              <div>
                <div *ngIf="selectedDomain.ecomType != 'wix'; else wixInfos">
                  <div *ngIf="selectedDomain.uninstalled" class="uninstall-content">
                    <div>
                      {{ 'BILLING.SUBSCRIPTION.UNINSTALLED_MESSAGE' | translate }}
                      <ng-container *ngIf="selectedDomain.ecomType == 'shopify'">
                        {{ 'BILLING.SUBSCRIPTION.UNINSTALLED_REINSTALL_MESSAGE' | translate }}
                        <a href="https://apps.shopify.com/syncee-1" target="_blank"
                          >https://apps.shopify.com/syncee-1</a
                        >
                      </ng-container>
                    </div>
                    <div>
                      {{ 'BILLING.SUBSCRIPTION.UNINSTALLED_ON' | translate }}
                      {{ selectedDomain.uninstalled | date : 'short' }}
                    </div>
                  </div>
                  <ng-container [ngSwitch]="selectedDomain.paymentProvider.toLowerCase()">
                    <div *ngSwitchCase="'wix'">
                      <div>{{ 'BILLING.SUBSCRIPTION.BILLED_BY_WIX' | translate }}</div>
                    </div>
                    <div *ngSwitchCase="'woocommerce'">
                      <div>{{ 'BILLING.SUBSCRIPTION.BILLED_BY_WOOCOMMERCE' | translate }}</div>
                    </div>
                    <div *ngSwitchCase="'shopify'">
                      <div>{{ 'BILLING.SUBSCRIPTION.BILLED_BY_SHOPIFY' | translate }}</div>
                    </div>
                    <div *ngSwitchDefault>
                      <ng-container *ngIf="paymentProvider && paymentProvider.cardDetails">
                        <ng-container *ngIf="paymentProvider.cardDetails.cardType">
                          {{
                            'BILLING.SUBSCRIPTION.CARD_DATA'
                              | translate
                                : {
                                    cardType: paymentProvider.cardDetails.cardType,
                                    last4: paymentProvider.cardDetails.last4,
                                    exp_month:
                                      ('GENERAL.SORT_MONTHS.' + [paymentProvider.cardDetails.exp_month] | translate),
                                    exp_year: paymentProvider.cardDetails.exp_year
                                  }
                          }}
                        </ng-container>
                        <ng-container *ngIf="paymentProvider.cardDetails.email">
                          {{
                            'BILLING.SUBSCRIPTION.PAYPAL_DATA'
                              | translate : { email: paymentProvider.cardDetails.email }
                          }}
                        </ng-container>
                        <a class="cursor-pointer" (click)="changeCardInformationDialog()">{{
                          'BILLING.SUBSCRIPTION.EDIT_CARD' | translate
                        }}</a>
                        <div
                          *ngIf="
                            checkSubscriptionUntil(paymentProvider.cardDetails.subscribedUntil.rmp) ||
                            checkSubscriptionUntil(paymentProvider.cardDetails.subscribedUntil.df)
                          "
                        >
                          <ng-container *ngIf="selectedDomain.cycle === 'monthly'; else recurringAnnually">
                            {{
                              'BILLING.SUBSCRIPTION.SUBSCRIBED_UNTIL_RECURRING_MONTHLY'
                                | translate : { date: getDate(paymentProvider.cardDetails.subscribedUntil.rmp) }
                            }}
                          </ng-container>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="paymentProvider.hasAccount() && !paymentProvider.cardDetails">
                        Your card has been removed from our system. Please add it here again:
                        <a class="cursor-pointer" (click)="changeCardInformationDialog()">Add card</a>
                      </ng-container>
                    </div>
                  </ng-container>
                  <div
                    *ngIf="
                      selectedDomain.subscriptions.df.planId === 100 &&
                      selectedDomain.subscriptions.rmp.planId === 120 &&
                      !selectedDomain.uninstalled
                    "
                  >
                    {{ 'BILLING.SUBSCRIPTION.NO_SUB' | translate }}
                  </div>
                  <div
                    *ngIf="
                      (selectedDomain.subscriptions.df.planId !== 100 ||
                        selectedDomain.subscriptions.rmp.planId !== 120) &&
                      !(selectedDomain.subscriptions.rmp.churnDate || selectedDomain.subscriptions.df.churnDate) &&
                      !selectedDomain.uninstalled &&
                      !isTrialActive
                    "
                  >
                    {{ 'BILLING.SUBSCRIPTION.SUBSCRIBED_UNTIL' | translate }}:
                    {{ selectedDomain.subscribed | date : 'dd.MM.yyyy HH:mm' }}
                  </div>
                  <div
                    *ngIf="
                      (selectedDomain.subscriptions.df.planId !== 100 ||
                        selectedDomain.subscriptions.rmp.planId !== 120) &&
                      (selectedDomain.subscriptions.df.churnDate || selectedDomain.subscriptions.rmp.churnDate) &&
                      (selectedDomain.subscriptions.df.churnDate
                        ? selectedDomain.subscriptions.df.churnDate > selectedDomain.subscriptions.df.freeTrialUntil
                        : true) &&
                      (selectedDomain.subscriptions.rmp.churnDate
                        ? selectedDomain.subscriptions.rmp.churnDate > selectedDomain.subscriptions.rmp.freeTrialUntil
                        : true)
                    "
                  >
                    {{
                      'BILLING.SUBSCRIPTION.CHURN'
                        | translate : { subUntil: selectedDomain.subscribed | date : 'dd.MM.yyyy' }
                    }}
                  </div>
                  <div *ngIf="isTrialActive">
                    <div>
                      {{ 'BILLING.SUBSCRIPTION.TRIAL.ACTIVE' | translate
                      }}{{ selectedDomain.subscriptions.rmp.freeTrialUntil | date : 'dd.MM.yyyy' }}
                    </div>
                  </div>
                  <div
                    *ngIf="
                      !isTrialActive &&
                      isTrialAvailable &&
                      selectedDomain.subscriptions.rmp.freeTrialUntil &&
                      selectedDomain.subscriptions.rmp.planId === 120 &&
                      selectedDomain.subscriptions.df.planId === 100
                    "
                  >
                    <div>
                      {{
                        'BILLING.SUBSCRIPTION.TRIAL.EXPIRES'
                          | translate : { date: selectedDomain.subscriptions.rmp.freeTrialUntil | date : 'dd.MM.yyyy' }
                      }}
                    </div>
                  </div>
                  <div
                    *ngIf="
                      selectedDomain.subscriptions.rmp.planId === 120 &&
                      selectedDomain.subscriptions.df.planId === 100 &&
                      !isTrialAvailable
                    "
                  >
                    <div>
                      {{ 'BILLING.SUBSCRIPTION.TRIAL.ENDED' | translate
                      }}{{ selectedDomain.subscriptions.rmp.freeTrialUntil | date : 'dd.MM.yyyy' }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxLayoutAlign="center stretch" class="subscription-plan-boxes">
        <div fxFlex="1 0 0">
          <app-subscription-plan-box
            [plan]="selectedPlans.rmp"
            [freeTrialIsAvailable]="isTrialAvailable"
            [selectedCycle]="selectedCycleType"
          ></app-subscription-plan-box>
        </div>
        <div fxFlex="1 0 0">
          <ng-container *ngIf="!isOnlyMarketplace(selectedDomain.ecomType)">
            <app-subscription-plan-box
              [plan]="selectedPlans.df"
              [freeTrialIsAvailable]="isTrialAvailable"
              [selectedCycle]="selectedCycleType"
            ></app-subscription-plan-box>
          </ng-container>
        </div>
      </div>
      <div class="pt-20">
        <app-faqs [faqs]="faqs" [title]="'Frequently Asked Questions'"></app-faqs>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #noStore>
  <div class="no-store" fxLayout="row wrap">
    <div class="title" fxFlex="100">
      {{ 'BILLING.SUBSCRIPTION.NO_STORE_MESSAGE' | translate }}
      <a (click)="addnewstore()" class="cursor-pointer">{{
        'BILLING.SUBSCRIPTION.ADD_NEW_STORE_MESSAGE' | translate
      }}</a>
    </div>
  </div>
</ng-template>

<ng-template #buttonProgress>
  <app-loading-spinner></app-loading-spinner>
</ng-template>

<ng-template #progress>
  <app-loading-spinner [centered]="true"></app-loading-spinner>
</ng-template>

<ng-template #error>
  <div>{{ 'BILLING.SUBSCRIPTION.SOME_ERROR_MESSAGE' | translate }}</div>
</ng-template>

<ng-template #mobileSubTable>
  <h2 class="centered-text">{{ 'BILLING.SUBSCRIPTION.CURRENT_SUBSCRIPTION_LABEL' | translate }}</h2>
  <div class="mobile-cards-container" *ngFor="let product of synceeProducts">
    <div *ngIf="checkPlanAvailable(selectedDomain.ecomType, product)" class="pb-8">
      <mat-card>
        <mat-card-title style="font-size: 18px !important">{{ synceeProductNames[product] }}</mat-card-title>
        <mat-card-content>
          <div id="plan">
            <div fxLayoutAlign="start center">
              <mat-form-field
                id="sm-selector"
                appearance="fill"
                class="input-without-padding"
                *ngIf="selectedPlans[product]?.productId"
              >
                <mat-select
                  [value]="selectedPlans[product].productId"
                  (valueChange)="planSelectionChange($event, product)"
                  disableOptionCentering
                  panelClass="mat-select-display-change"
                >
                  <mat-option *ngFor="let plan of plans[product]" [value]="plan.productId">
                    {{ plan.productName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-icon (click)="openCompareDialog(product)" class="cursor-pointer pl-8">info</mat-icon>
            </div>
            <div *ngIf="nextPlan && nextPlan[product]">
              <span (click)="upgradeToPlan(nextPlan[product], product)" class="cursor-pointer" style="font-size: 12px"
                >{{ 'BILLING.SUBSCRIPTION.UPGRADE_TO_LABEL' | translate }} {{ nextPlan[product].productName }}</span
              >
            </div>
          </div>
          <div *ngIf="this.availabledCouponEcomTypes.includes(selectedDomain.ecomType)">
            <button
              *ngIf="
                !discountToken && (!coupon || !coupon.data || !coupon.data[selectedPlans[product].productId]);
                else couponexist
              "
              mat-button
              class="syncee-blue-button"
              (click)="openCouponCodeDialog(product)"
              [disabled]="isCurrentPlanSelected(product) || isStarterPlan(product)"
            >
              {{ 'BILLING.SUBSCRIPTION.COUPON.COUPON_CODE' | translate }}
            </button>

            <ng-template #couponexist>
              <ng-container *ngIf="!isCurrentPlanSelected(product) && !isStarterPlan(product) && isCoupon(product)">
                <div class="coupon-activate-wrapper" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                  <div class="coupon-activated">
                    {{ 'BILLING.SUBSCRIPTION.COUPON.COUPON_CODE' | translate }}:
                    <span class="coupon-activated-text">{{ coupon.data[selectedPlans[product].productId].name }}</span>
                  </div>
                  <mat-icon *ngIf="!discountToken" (click)="removeCoupon(selectedPlans[product].productId)"
                    >close</mat-icon
                  >
                </div>
                <div>
                  ( -{{ coupon.data[selectedPlans[product].productId].amount }}
                  <span [ngSwitch]="coupon.data[selectedPlans[product].productId].type">
                    <span *ngSwitchCase="'percentage'">%</span>
                    <span *ngSwitchCase="'fixed'">USD</span> </span
                  >)
                </div>
              </ng-container>
            </ng-template>
          </div>
          <div class="sub-title">Cycle:</div>
          <div>
            <mat-slide-toggle
              [checked]="selectedCycleType == 1"
              class="mat-slide-fixed-color"
              (change)="cycleChanged($event)"
            ></mat-slide-toggle>
            <div>{{ 'BILLING.SUBSCRIPTION.MONTHLY_ANNUALLY_LABEL' | translate }}</div>
          </div>
          <div class="sub-title">{{ 'BILLING.SUBSCRIPTION.USAGE_LABEL' | translate }}:</div>
          <div>
            {{
              selectedPlans[product].productId == selectedDomain.subscriptions[product].planId &&
              currentCycleType === selectedCycleType
                ? formatMoney(selectedDomain.subscriptions[product].maxProductsNumber)
                : formatMoney(selectedPlans[product].planData.managedProductsLimit)
            }}
            {{ 'BILLING.SUBSCRIPTION.LIMIT' | translate }} (<span>{{
              selectedDomain.subscriptions[product].usedProductNumber
            }}</span>
            {{ 'BILLING.SUBSCRIPTION.USED_LABEL' | translate }})
          </div>

          <div class="sub-title">{{ 'BILLING.SUBSCRIPTION.YOUR_PRICE_LABEL' | translate }}:</div>
          <div *ngIf="isCoupon(product) && !isCurrentPlanSelected(product)">
            <div>
              <span
                fxLayoutAlign="end"
                *ngIf="
                  selectedPlans[product].productId != selectedDomain.subscriptions[product].planId ||
                  currentCycleType !== selectedCycleType
                "
              >
                <span class="original-price">
                  {{
                    selectedCycleType == 0
                      ? selectedPlans[product].productMonthlyPrice
                      : selectedPlans[product].productYearlyPrice
                  }}
                  USD
                </span>
              </span>
            </div>
          </div>
          <div>
            <ng-container *ngIf="isCurrentPlanSelected(product)">
              <div *ngIf="selectedDomain.subscriptions[product].coupon" fxLayoutAlign="end">
                <div class="align-end">
                  <div>
                    {{ 'BILLING.SUBSCRIPTION.COUPON.USED_COUPON' | translate }}:
                    <b>{{ selectedDomain.subscriptions[product].coupon.name }}</b>
                  </div>
                  <div>
                    {{
                      calculatePrice(
                        selectedDomain.subscriptions[product].coupon.type,
                        selectedDomain.subscriptions[product].coupon.amount,
                        selectedDomain.subscriptions[product]?.price
                      )
                    }}
                    USD
                  </div>
                </div>
              </div>
              <span *ngIf="!selectedDomain.subscriptions[product].coupon" fxLayoutAlign="end">
                {{ selectedDomain.subscriptions[product]?.price }} USD
              </span>
            </ng-container>

            <span fxLayoutAlign="end" *ngIf="!isCurrentPlanSelected(product)">
              (current: {{ selectedDomain.subscriptions[product].price }} USD)
              {{
                selectedCycleType == 0
                  ? getCalculatedPrice(
                      product,
                      selectedPlans[product].productId,
                      selectedPlans[product].productMonthlyPrice
                    )
                  : getCalculatedPrice(
                      product,
                      selectedPlans[product].productId,
                      selectedPlans[product].productYearlyPrice
                    )
              }}
              USD
            </span>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <mat-card>
    <mat-card-title style="font-size: 16px !important">
      <ng-container [ngSwitch]="selectedCycleType">
        <span *ngSwitchCase="0">
          {{ 'BILLING.SUBSCRIPTION.TOTAL_MONTHLY_PRICE_LABEL' | translate }}
        </span>
        <span *ngSwitchCase="1">
          {{ 'BILLING.SUBSCRIPTION.TOTAL_ANNUAL_PRICE_LABEL' | translate }}
        </span>
        <span fxLayoutAlign="end center">
          {{ countTotalPrice() }} USD
          <mat-icon
            [matTooltip]="'BILLING.SUBSCRIPTION.TOTAL_MONTHLY_PRICE_TOOLTIP' | translate"
            matTooltipPosition="below"
            class="ml-4"
            >help_outline</mat-icon
          >
        </span>
      </ng-container>
    </mat-card-title>
  </mat-card>
  <mat-card>
    <div>
      <ng-container [ngSwitch]="selectedDomain.paymentProvider.toLowerCase()">
        <div *ngSwitchCase="'shopify'">
          <div>{{ 'BILLING.SUBSCRIPTION.BILLED_BY_SHOPIFY' | translate }}</div>
          <div *ngIf="selectedDomain.subscribed">
            {{ 'BILLING.SUBSCRIPTION.SUBSCRIBED_UNTIL' | translate }}:
            {{ selectedDomain.subscribed | date : 'dd.MM.yyyy HH:mm' }}
          </div>
        </div>
        <div *ngSwitchCase="'wix'">
          <div>{{ 'BILLING.SUBSCRIPTION.BILLED_BY_WIX' | translate }}</div>
          <div *ngIf="selectedDomain.subscribed">
            {{ 'BILLING.SUBSCRIPTION.SUBSCRIBED_UNTIL' | translate }}:
            {{ selectedDomain.subscribed | date : 'dd.MM.yyyy HH:mm' }}
          </div>
        </div>
        <div *ngSwitchCase="'woocommerce'">
          <div>{{ 'BILLING.SUBSCRIPTION.BILLED_BY_WOOCOMMERCE' | translate }}</div>
          <div *ngIf="selectedDomain.subscribed">
            {{ 'BILLING.SUBSCRIPTION.SUBSCRIBED_UNTIL' | translate }}:
            {{ selectedDomain.subscribed | date : 'dd.MM.yyyy HH:mm' }}
          </div>
        </div>
        <div *ngSwitchDefault>
          <ng-container *ngIf="paymentProvider && paymentProvider.cardDetails">
            <ng-container *ngIf="paymentProvider.cardDetails.cardType">
              {{
                'BILLING.SUBSCRIPTION.CARD_DATA'
                  | translate
                    : {
                        cardType: paymentProvider.cardDetails.cardType,
                        last4: paymentProvider.cardDetails.last4,
                        exp_month: ('GENERAL.SORT_MONTHS.' + [paymentProvider.cardDetails.exp_month] | translate),
                        exp_year: paymentProvider.cardDetails.exp_year
                      }
              }}
            </ng-container>
            <ng-container *ngIf="paymentProvider.cardDetails.email">
              {{ 'BILLING.SUBSCRIPTION.PAYPAL_DATA' | translate : { email: paymentProvider.cardDetails.email } }}
            </ng-container>
            <a class="cursor-pointer" (click)="changeCardInformationDialog()">{{
              'BILLING.SUBSCRIPTION.EDIT_CARD' | translate
            }}</a>
            <div
              *ngIf="
                checkSubscriptionUntil(paymentProvider.cardDetails.subscribedUntil.rmp) ||
                checkSubscriptionUntil(paymentProvider.cardDetails.subscribedUntil.df)
              "
            >
              <ng-container *ngIf="selectedDomain.cycle === 'monthly'; else recurringAnnually">
                {{
                  'BILLING.SUBSCRIPTION.SUBSCRIBED_UNTIL_RECURRING_MONTHLY'
                    | translate : { date: getDate(paymentProvider.cardDetails.subscribedUntil.rmp) }
                }}
              </ng-container>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <div *ngIf="!checkSubscriptionUntil(selectedDomain.subscribed)">
        {{ 'BILLING.SUBSCRIPTION.NO_SUBSCRIPTION_MESSAGE' | translate }}
      </div>
    </div>
  </mat-card>
  <div class="px-16 pt-16">
    <app-accept-terms [checked]="termsChecked" (checkedChange)="handleTermsCheckboxChange($event)"></app-accept-terms>
  </div>
  <div fxLayoutAlign="center" class="pt-16 pb-16">
    <button
      [disabled]="isWixUninstalled || !isAllPlanAvailable"
      *ngIf="!disableButton; else buttonProgress"
      mat-button
      class="syncee-blue-button"
      (click)="handleSubscriptionButtonClicked()"
    >
      <ng-container [ngSwitch]="true">
        <span
          *ngSwitchCase="
            selectedDomain.subscriptions.rmp.planId !== 120 &&
            selectedDomain.subscriptions.df.planId !== 100 &&
            selectedPlans.rmp.productId === 120 &&
            selectedPlans.df.productId === 100
          "
        >
          {{ 'BILLING.SUBSCRIPTION.CANCEL_SUBSCRIPTION' | translate }}
        </span>
        <span *ngSwitchCase="isTrialAvailable && selectedCycleType === 0">
          {{ 'BILLING.SUBSCRIPTION.FREE_TRIAL' | translate }}
        </span>
        <span
          *ngSwitchCase="
            selectedDomain.subscriptions.rmp.planId === 120 &&
            selectedDomain.subscriptions.df.planId === 100 &&
            (!isTrialAvailable || selectedCycleType === 1)
          "
        >
          {{ 'BILLING.SUBSCRIPTION.SUBSCRIBE' | translate }}
        </span>
        <span *ngSwitchDefault>
          {{ 'BILLING.SUBSCRIPTION.CHANGE_SUBSCRIPTION_LABEL' | translate }}
        </span>
      </ng-container>
    </button>
  </div>
  <div *ngIf="showErrorTermsMessage" class="px-16 pb-16">
    <div class="wix-error-container">
      <div>{{ 'BILLING.SUBSCRIPTION.ACCEPT_TERMS_ERROR' | translate }}</div>
    </div>
  </div>
</ng-template>

<ng-template #planIsNotAvailable>
  <div fxLayoutAlign="end" class="plan-is-not-available">
    <mat-icon>warning_outlined</mat-icon>
    <div>
      {{ 'BILLING.SUBSCRIPTION.NOT_AVAILABLE_ANNUAL' | translate }}
    </div>
  </div>
</ng-template>

<ng-template #recurringAnnually>
  {{ 'BILLING.SUBSCRIPTION.SUBSCRIBED_UNTIL_RECURRING_ANNUALLY' | translate }}
</ng-template>

<ng-template #wixInfos>
  <div>{{ 'BILLING.SUBSCRIPTION.WIX_SUBSCRIPTION.BY_WIX' | translate }}</div>
  <div
    [innerHTML]="'BILLING.SUBSCRIPTION.WIX_SUBSCRIPTION.CHECK_AT_WIX' | translate : { storeId: selectedDomain.storeId }"
  ></div>
</ng-template>
