<div
  fxLayout="row"
  [fxLayoutAlign]="'space-between center'"
  fxLayoutGap="12px"
  fxLayoutGap.lt-lg="8px"
  class="container w-100-p"
>
  <ng-container [ngSwitch]="!!views && views.length > 0">
    <ng-container *ngSwitchCase="true">
      <ng-container *ngTemplateOutlet="viewsGroup"></ng-container>
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
        <ng-container *ngTemplateOutlet="searchBar"></ng-container>
        <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="false">
      <ng-container *ngTemplateOutlet="searchBar"></ng-container>
      <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #searchBar>
  <div class="search-bar-container" *ngIf="enableSearchbar">
    <app-searchbar-custom
      @fadeInOut
      *ngIf="permanentSearchbar ? true : isOpen"
      [debounceTime]="500"
      [placeholder]="'RETAILER_MANAGE_ORDERS.LISTING.FILTER_ROW.SEARCH' | translate"
      (searchTermChange)="searchTerm.next($event)"
    ></app-searchbar-custom>
  </div>
</ng-template>

<ng-template #actionButtons>
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="12px" [ngClass]="{ 'w-100-p': !enableSearchbar }">
    <mat-spinner *ngIf="!isOpen && loading" [diameter]="30"></mat-spinner>
    <button
      *ngIf="enableFilterButton"
      mat-icon-button
      class="syncee-square-icon-button open-button"
      (click)="filterGroupOpen.emit(); isOpen = !isOpen"
      [ngClass]="{ 'open-button-active': isOpen, 'active-filter': hasActiveFilter }"
    >
      <mat-icon class="s-20" *ngIf="!permanentSearchbar"> search </mat-icon>
      <mat-icon class="s-20"> filter_list </mat-icon>
    </button>
    <button
      *ngIf="enableExpandButton"
      mat-icon-button
      class="syncee-square-icon-button open-button"
      (click)="expandAllChange.emit()"
    >
      <mat-icon class="s-20"> unfold_more </mat-icon>
    </button>
    <app-index-table-sort
      *ngIf="!!sortArray?.length"
      [choices]="sortArray"
      (selectedSortChange)="sortChange.emit($event)"
    ></app-index-table-sort>
  </div>
</ng-template>

<ng-template #viewsGroup>
  <mat-chip-list multiple selectable>
    <mat-chip
      class="custom-chip typography-body-1"
      (click)="handleViewChange(null); clearAllClicked.emit()"
      [selected]="!selectedView"
    >
      {{ 'INDEX_FILTER.ALL' | translate }}
    </mat-chip>
    <mat-chip
      *ngFor="let view of views"
      class="custom-chip typography-body-1"
      (click)="handleViewChange(view)"
      [selected]="selectedView === view"
    >
      {{ view.translate ? (view.labelKey | translate) : view.labelKey }}
    </mat-chip>
  </mat-chip-list>
</ng-template>
