import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { RetailerCenterNavigation } from 'app/navigation/navigation-routes/retailer/retailer-center.navigation';
import { SupplierCenterNavigation } from 'app/navigation/navigation-routes/supplier/supplier-center.navigation';
import { EcomVO } from 'app/service/ecom/ecom.service';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { fuseAnimations } from '../../../@fuse/animations';
import { FuseConfigService } from '../../../@fuse/services/config.service';
import { AppState } from '../../app.state';
import { OnboardService } from '../../layout/components/onboard/onboard.service';
import { Action, AuthorizationEcomService } from '../../service/authorization-ecom/authorization-ecom.service';
import { CatalogService } from '../../service/catalog/catalog.service';
import { MarketplaceEcomService } from '../../service/marketplace/marketplace-ecom/marketplace-ecom.service';
import { RestService } from '../../service/rest/rest.service';
import { BreakPoint, ScreenManagerService } from '../../service/screen-manager/screen-manager.service';
import { TaskWatcherService } from '../../service/task/task-watcher.service';
import { TaskService } from '../../service/task/task.service';
import { AdminService } from '../../service/user/admin.service';
import { UserService } from '../../service/user/user.service';
import { DeleteModalVO } from '../../shared/components/dialogs/delete-modal/delete-modal-vo';
import { DeleteModalComponent } from '../../shared/components/dialogs/delete-modal/delete-modal.component';
import { DialogWithAnInputComponent } from '../../shared/components/dialogs/dialog-with-an-input/dialog-with-an-input.component';
import { retailerEcomsSelector, supplierEcomsSelector } from '../../store/ecom/ecom.selector';
import {
  Constants,
  ECOM_ECWID,
  ECOM_JUMPSELLER,
  ECOM_SHOPIFY,
  ECOM_SHOPRENTER,
  TaskTypes,
  USER_ROLES,
} from '../../utils/Constants';
import { CatalogFormVO } from '../../vo/catalog-form-vo';
import { RolesEnum, ROLE_TYPE } from '../../vo/roles/roles';
import { RetailerUpdateCatalogDialogComponent } from '../marketplace/retailer-catalogs/retailer-update-catalog-dialog.component';
import { NotificationService } from '../notification/notification.service';
import { SynceeReviewAutoProcessService } from '../syncee-review/service/syncee-review-auto-process.service';
import { AddTaskModalComponent } from './add-task-modal.component';
import { HistoryIndicatorData } from './history-indicator/history-indicator.component';
import { SourceChangerDialogComponent } from './source-changer-dialog/source-changer-dialog.component';
import { TaskDetailsMobilepopupComponent } from './task-details-mobilepopup/task-details-mobilepopup.component';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
  animations: fuseAnimations,
  providers: [SynceeReviewAutoProcessService],
})
export class TasksComponent implements OnInit, OnDestroy {
  public static SUPPLIER_TASK_LIST = SupplierCenterNavigation.CATALOG.TASK;
  public static DATAFEED_TASK_LIST = RetailerCenterNavigation.DATAFEED_MANAGER.PRODUCT_UPLOAD;
  public static EXPORT_TASK_LIST = RetailerCenterNavigation.DATAFEED_MANAGER.PRODUCT_EXPORT;
  public static VARIANTS_UPDATE_TASK_LIST = RetailerCenterNavigation.DATAFEED_MANAGER.VARIANTS_UPDATE;

  isLoading = true;
  ltLg: Observable<boolean>;

  constructor(
    private restService: RestService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private taskWatcherService: TaskWatcherService,
    private _userService: UserService,
    private taskService: TaskService,
    private catalogService: CatalogService,
    private onboardService: OnboardService,
    private marketplaceEcomService: MarketplaceEcomService,
    private translate: TranslateService,
    private fuseConfigService: FuseConfigService,
    private adminService: AdminService,
    private authorizationService: AuthorizationEcomService,
    private store: Store<AppState>,
    private screenManagerService: ScreenManagerService,
    private reviewAutoProcessService: SynceeReviewAutoProcessService
  ) {
    this._unsubscribeAll = new Subject();
    this.ltLg = this.getLtLg();
  }
  tasks: TaskVoExtended[] = [];
  displayedColumns = ['status', 'icons', 'name', 'history', 'details', 'date', 'nextTimeRun', 'menu'];
  roles = USER_ROLES;
  domains: EcomVO[] = [];
  columnDefinitions = [
    { def: 'status', showForSimpleUser: true },
    { def: 'icons', showForSimpleUser: true },
    { def: 'name', showForSimpleUser: true },
    { def: 'history', showForSimpleUser: true },
    { def: 'details', showForSimpleUser: true },
    { def: 'products', showForSimpleUser: true },
    { def: 'date', showForSimpleUser: true },
    { def: 'nextTimeRun', showForSimpleUser: true },
    { def: 'menu', showForSimpleUser: true },
  ];
  products: number[] = [];

  menus: any[] = [];
  toggleView = true;
  toolTipDisabled: boolean;
  userId: number = null;

  isAdmin = false;

  taskWatcherObsSubs: Subscription;

  taskTypes = new TaskTypes();

  getTasksURL: string;

  runTaskURL: string;
  addTaskURL: string;
  addTaskBtnTitle: string;

  pageTitle: string;
  navigateURL: string;

  queryParamsName: any[];
  taskListType: string;

  legendStyle: 'normal' | 'tooltip' | 'row' = 'normal';

  currentLang;
  allCatalogRejected = false;
  taskRole: ROLE_TYPE;

  public selectedEcom: EcomVO;

  private _unsubscribeAll: Subject<void>;
  public actualRole = '2';
  isTrialValid = false;
  forceReload = new Subject<void>();
  dataSource: MatTableDataSource<TaskVoExtended>;
  page = new BehaviorSubject(0);
  size = new BehaviorSubject(10);
  selectedEcomFilter = new BehaviorSubject<number>(null);

  private readonly SHOP_SOURCE_CONNECTION_TYPES = [
    Constants.SHOPIFY_CONNECTION_TYPE,
    Constants.JUMPSELLER_CONNECTION_TYPE,
    Constants.SHOPRENTER_CONNECTION_TYPE,
    Constants.WOOCOMMERCE_CONNECTION_TYPE,
    Constants.BIGCOMMERCE_CONNECTION_TYPE,
    Constants.WIX_CONNECTION_TYPE,
    Constants.UNAS_CONNECTION_TYPE,
    Constants.EKM_CONNECTION_TYPE,
    Constants.ECWID_CONNECTION_TYPE,
  ];

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (!!params.openQuickGuide && JSON.parse(params.openQuickGuide)) {
        if (this.onboardService.isInitialized) {
          this.onboardService.openOnboardDialog();
        } else {
          this.subscribeToOnboardingInit();
        }
      }
    });

    this.actualRole = localStorage.getItem('actualRole');
    this.currentLang = this.translate.currentLang;

    let menu = new MenuItem(this.translate.instant('TASKS.MENU_ITEMS_LABELS.HISTORY'), 'history', null, 'history');
    this.menus.push(menu);

    menu = new MenuItem(this.translate.instant('TASKS.MENU_ITEMS_LABELS.RENAME'), 'title', null, 'rename');
    this.menus.push(menu);

    if (
      this._userService.getCachedUser().role === USER_ROLES.GLOBAL_ADMIN &&
      this._userService.getActualRole() === '1'
    ) {
      menu = new MenuItem('Change handle', 'edit', null, 'change_handle');
      this.menus.push(menu);
    }

    menu = new MenuItem(this.translate.instant('TASKS.MENU_ITEMS_LABELS.DELETE'), 'delete', null, 'delete');
    this.menus.push(menu);

    menu = new MenuItem(
      this.translate.instant('TASKS.MENU_ITEMS_LABELS.SET_ACTIVE'),
      'change_circle',
      null,
      'active_inactive'
    );
    this.menus.push(menu);

    this.init();
    this.taskWatcherObsSubs = this.taskWatcherService.getTaskRunFinishedObservable().subscribe(() => {
      this.marketplaceEcomService.reloadUsage.emit();
      this.forceReload.next();
      this.reviewAutoProcessService.triggerDialogOpen();
    });
    this.displayedColumns = this.getDisplayedColumns();

    this.toggleView = localStorage.getItem('taskView') === 'true';
    this.toolTipDisabled = this.userService.getCachedUser().role !== this.roles.GLOBAL_ADMIN;

    this.subscribeToSelectedEcom();
    this.getData();
  }

  init(): void {
    const url = this.router.url.replace('/', '');
    this.queryParamsName = [];
    this.taskListType = url.split('?')[0];
    this.queryParamsName.push({ paramName: 'task_id', fieldName: 'taskId' });
    switch (this.taskListType) {
      case TasksComponent.SUPPLIER_TASK_LIST:
        this.taskWatcherService.startTaskWatcher();
        this.getTasksURL = TaskService.GET_IMPORT_TASKS_URL;
        this.navigateURL = 'mytasks/taskwizard';
        this.addTaskBtnTitle = this.translate.instant('RETAILER_IMPORT_TASKS.ADD_TASK');
        this.pageTitle = this.translate.instant('RETAILER_IMPORT_TASKS.PRODUCTS_UPLOAD');
        this.addTaskURL = TaskService.ADD_TASK;
        this.runTaskURL = TaskService.RUN_IMPORT_TASK;
        this.taskRole$ = RolesEnum.SUPPLIER;
        this.isAllCatalogRejected();
        break;
      case TasksComponent.DATAFEED_TASK_LIST:
        this.taskWatcherService.startTaskWatcher();
        this.getTasksURL = TaskService.GET_DF_IMPORT_TASKS_URL;
        this.navigateURL = 'retailer/import-tasks/retailer-task-wizard';
        this.addTaskBtnTitle = this.translate.instant('RETAILER_IMPORT_TASKS.ADD_IMPORT_TASK');
        this.pageTitle = this.translate.instant('RETAILER_IMPORT_TASKS.PRODUCTS_UPLOAD');
        this.addTaskURL = TaskService.ADD_DF_TASK;
        this.runTaskURL = TaskService.RUN_IMPORT_TASK;
        const menu = new MenuItem(this.translate.instant('TASKS.MENU_ITEMS_LABELS.UPDATE'), 'sync', null, 'update');
        this.menus.push(menu);
        this.taskRole$ = RolesEnum.RETAILER;
        break;
      case TasksComponent.EXPORT_TASK_LIST:
        this.taskWatcherService.startETaskFileWatcher();
        this.getTasksURL = TaskService.GET_EXPORT_TASKS_URL;
        this.navigateURL = 'retailer/export-tasks/retailer-export-task-wizard';
        this.runTaskURL = TaskService.RUN_EXPORT_TASK;
        this.addTaskURL = TaskService.ADD_EXPORT_TASK;
        this.legendStyle = 'row';
        this.addTaskBtnTitle = this.translate.instant('RETAILER_IMPORT_TASKS.ADD_EXPORT_TASK');
        this.pageTitle = this.translate.instant('RETAILER_IMPORT_TASKS.PRODUCTS_EXPORT');
        this.queryParamsName.push({ paramName: 'ecom_id', fieldName: 'ecomId' });
        this.taskRole$ = RolesEnum.RETAILER;
        break;
      case TasksComponent.VARIANTS_UPDATE_TASK_LIST:
        this.taskWatcherService.startUpdateVariantsTaskWatcher();
        this.getTasksURL = TaskService.GET_VARIANTS_UPDATE_TASKS_URL;
        this.navigateURL = 'retailer/variants-update-tasks/retailer-variants-update-task-wizard';
        this.addTaskBtnTitle = this.translate.instant('RETAILER_IMPORT_TASKS.ADD_VARIANTS_TASK');
        this.pageTitle = this.translate.instant('RETAILER_IMPORT_TASKS.VARIANTS_UPDATE');
        this.legendStyle = 'row';
        this.addTaskURL = TaskService.ADD_VU_TASK;
        this.runTaskURL = TaskService.RUN_VU_TASK;
        this.taskRole$ = RolesEnum.RETAILER;
        break;
    }
    this.initTheme();
    this.subscribeToEcoms(this.taskRole);
  }

  private set taskRole$(role: ROLE_TYPE) {
    this.taskRole = role;
  }

  private subscribeToSelectedEcom(): void {
    this.marketplaceEcomService.getEcomWithSubscriptionFromStore().subscribe((ecom) => {
      this.selectedEcom = ecom;
      this.isTrialValid = this.marketplaceEcomService.isFreeTrialAvailable(this.selectedEcom);
    });
  }

  private initTheme(): void {
    this.fuseConfigService.config = {
      layout: { title: this.pageTitle },
    };
  }

  initWatcher(): void {
    switch (this.taskListType) {
      case TasksComponent.SUPPLIER_TASK_LIST:
        this.taskWatcherService.startTaskWatcher();
        break;
      case TasksComponent.DATAFEED_TASK_LIST:
        this.taskWatcherService.startTaskWatcher();
        break;
      case TasksComponent.EXPORT_TASK_LIST:
        this.taskWatcherService.startETaskFileWatcher();
        break;
      case TasksComponent.VARIANTS_UPDATE_TASK_LIST:
        this.taskWatcherService.startUpdateVariantsTaskWatcher();
        break;
    }
  }

  isAllCatalogRejected(): void {
    this.catalogService.isAllCatalogRejected().subscribe((rejected) => {
      this.allCatalogRejected = rejected;
    });
  }

  getRejectedText(): string {
    return this.translate.instant('TASKS.REJECTED_TEXT');
  }

  menuItemClick(itemId: string, task): void {
    if (itemId === 'source_settings') {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.width = '70%';
      dialogConfig.data = { taskId: task.taskId };
      dialogConfig.panelClass = 'custom-modal-container';
      const dialogRef = this.dialog.open(SourceChangerDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(() => {});
    } else if (itemId === 'delete') {
      const param = {
        taskId: task.taskId,
      };
      let url = 'TaskService/delete';
      if (this.taskListType === TasksComponent.EXPORT_TASK_LIST) {
        url = 'ExportTaskService/delete';
      }

      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.width = '400px';
      dialogConfig.data = new DeleteModalVO('Delete task');
      dialogConfig.panelClass = 'custom-modal-container';
      const dialogRef = this.dialog.open(DeleteModalComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.restService.post(url, param).subscribe(
            () => {
              this.forceReload.next();
            },
            (error) => {
              // alert(error.errorMessage);
              console.log(error);
            }
          );
        }
      });
    } else if (itemId === 'history') {
      this.router.navigate(['/history'], { queryParams: { taskId: task.taskId } });
    } else if (itemId === 'rename') {
      const isExportTask = this.taskListType === TasksComponent.EXPORT_TASK_LIST;
      const url = isExportTask ? 'ExportTaskService/update' : 'TaskService/update';

      const dialogConfig = new MatDialogConfig();
      dialogConfig.width = '500px';
      dialogConfig.autoFocus = false;
      dialogConfig.panelClass = 'custom-modal-container';
      const dialogRef = this.dialog.open(DialogWithAnInputComponent, dialogConfig);

      dialogRef.componentInstance.text = this.translate.instant('TASKS.RENAME_MODAL.TEXT_TASK');
      dialogRef.componentInstance.placeholder = this.translate.instant('TASKS.RENAME_MODAL.PLACEHOLDER');
      dialogRef.componentInstance.btnCancel = this.translate.instant('TASKS.RENAME_MODAL.CANCEL');
      dialogRef.componentInstance.btnOk = this.translate.instant('TASKS.RENAME_MODAL.SAVE');

      dialogRef.afterClosed().subscribe((newName) => {
        if (newName) {
          const taskWithName = task;
          taskWithName.name = newName;
          this.taskService.updateTask(url, taskWithName).subscribe(
            () => {
              if (isExportTask) {
                this.forceReload.next();
                this.notificationService.success('Successful name change!');
              } else {
                const catalog = new CatalogFormVO();
                catalog.id = taskWithName.taskId;
                catalog.name = taskWithName.name;
                catalog.tags = [];

                this.catalogService.updateCatalog(catalog).subscribe(
                  () => {
                    if (task.taskType === 'public') {
                      this.forceReload.next();
                      this.notificationService.success('Succchangeessful name change!');
                    } else {
                      this.catalogService.updateRCatalog('RetailerCatalogService/updateByTask', taskWithName).subscribe(
                        () => {
                          this.forceReload.next();
                          this.notificationService.success('Successful name change!');
                        },
                        (error) => {
                          console.log(error);
                        }
                      );
                    }
                  },
                  (error) => {
                    console.log(error);
                  }
                );
              }
            },
            (error) => {
              console.log(error);
            }
          );
        }
      });
    }
    if (itemId === 'update') {
      event.stopPropagation();
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;

      dialogConfig.width = '90%';
      dialogConfig.maxHeight = '95vh';

      dialogConfig.data = {
        ecomType: task.ecomType,
        isRCatalog: false,
      };
      dialogConfig.panelClass = 'custom-modal-container';
      const dialogRef = this.dialog.open(RetailerUpdateCatalogDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((updateFields) => {
        if (!updateFields || updateFields.length < 1) {
          return;
        }
        this.runClicked(task, updateFields);
      });
    }
    if (itemId === 'details') {
      this.dialog.open(TaskDetailsMobilepopupComponent, {
        width: '90vw',
        maxHeight: '90vh',
        autoFocus: false,
        data: task,
      });
    }
    if (itemId === 'active_inactive') {
      this.updateTask(task, !(task.isActive === '1'));
    }
    if (itemId === 'change_handle') {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.width = '500px';
      dialogConfig.autoFocus = false;
      dialogConfig.panelClass = 'custom-modal-container';
      const dialogRef = this.dialog.open(DialogWithAnInputComponent, dialogConfig);

      dialogRef.componentInstance.text = 'Change handle';
      dialogRef.componentInstance.placeholder = 'New SEO URL';
      dialogRef.componentInstance.btnCancel = this.translate.instant('TASKS.RENAME_MODAL.CANCEL');
      dialogRef.componentInstance.btnOk = this.translate.instant('TASKS.RENAME_MODAL.SAVE');
      this.restService.get(`CatalogService/getCatalogById?id=${task.taskId}`).subscribe((response) => {
        const seoUrl = response.getFirstData().seoUrl;
        dialogRef.componentInstance.response = seoUrl ? seoUrl : '';
      });

      dialogRef.afterClosed().subscribe((newURL) => {
        if (newURL) {
          this.adminService.updatePmsCatalogSeoUrl(task.taskId, newURL).subscribe(() => {
            this.notificationService.success('Successful SEO URL change!');
          });
        }
      });
    }
  }

  fetcher(): Observable<TaskVoExtended[]> {
    return this.taskService.getUserTasks(this.getTasksURL).pipe(
      map((tasks) => this.filterTaskWithoutEcomId(tasks)),
      tap((tasks) => tasks.forEach((task) => this.handleTask(task))),
      tap((tasks) => (this.tasks = tasks))
      // map((tasks) => this.mapTaskVosToCustomTable(tasks))
    );
  }

  private handleTask(task: TaskVoExtended): void {
    this.initMenusByTask(task);
    task.progressbar = this.getProgressForTask(task);
    this.initWatcherIfNecessary(task);
    this.setTaskDefaults(task);
    this.initByTaskListType(task);
  }

  private initByTaskListType(task: TaskVoExtended): void {
    switch (this.taskListType) {
      case TasksComponent.DATAFEED_TASK_LIST:
        switch (task.taskType) {
          case 'shopify_df':
            task.destType = 'SHOPIFY';
            task.ecomType = 'shopify';
            break;
          case 'jumpseller_df':
            task.destType = 'JUMPSELLER';
            task.ecomType = 'jumpseller';
            break;
          case 'shoprenter_df':
            task.destType = 'SHOPRENTER';
            task.ecomType = 'shoprenter';
            break;
          case 'ecwid_df':
            task.destType = 'ECWID';
            break;
        }
        break;
      case TasksComponent.SUPPLIER_TASK_LIST:
        task.destType = 'SYNCEE';
        break;
      case TasksComponent.EXPORT_TASK_LIST:
        task.taskBaseType = 'export';
        task.sourceType = task.destinationType;
        task.progressbar = [
          {
            label: this.translate.instant('TASKS.PROGRESS_BAR.PRODUCTS_IN_THE_FILE'),
            value: task.uploaded,
            color: 'green',
          },
        ];
        break;
      case TasksComponent.VARIANTS_UPDATE_TASK_LIST:
        switch (task.taskType) {
          case 'shopify_uv':
            task.destType = 'SHOPIFY';
            break;
          case 'jumpseller_uv':
            task.destType = 'JUMPSELLER';
            break;
          case 'shoprenter_uv':
            task.destType = 'SHOPRENTER';
            break;
        }
        task.taskBaseType = 'import';
        task.progressbar = [
          {
            label: this.translate.instant('TASKS.PROGRESS_BAR.UPDATED_VARIANTS_NUMBER'),
            value: task.updated,
            color: 'dodgerblue',
          },
        ];
        break;
    }
  }

  private setTaskDefaults(task: TaskVoExtended): void {
    task.taskBaseType = 'import';
    task.destType = 'SHOPIFY';
  }

  private initWatcherIfNecessary(task: TaskVoExtended): void {
    if (task.isStillRunning) {
      this.taskWatcherService.addRunnedTaskToWatcher(task.taskId);
    }
  }

  private getProgressForTask(task: TaskVoExtended): TaskProgress[] {
    return [
      {
        label: this.translate.instant('TASKS.PROGRESS_BAR.UPDATED'),
        value: task.updated,
        color: 'var(--app-syncee-primary-100)',
      },
      {
        label: this.translate.instant('TASKS.PROGRESS_BAR.NEW'),
        value: task.uploaded,
        color: 'var(--app-light-green-100)',
      },
      {
        label: this.translate.instant('TASKS.PROGRESS_BAR.DELETED'),
        value: task.deleted,
        color: 'var(--app-red-200)',
      },
      {
        label: this.translate.instant('TASKS.PROGRESS_BAR.NOT_MODIFIED'),
        value: task.notModified,
        color: 'var(--app-syncee-grey-200)',
        legendMarker: 'donut',
      },
    ];
  }

  private filterTaskWithoutEcomId(tasks: TaskVoExtended[]): TaskVoExtended[] {
    // OLD_TODO: kivettem azokat a task-okat amikhez valami oknál fogva nincs rcatalogID.
    // Elvileg ilyen nem lehet, de ha mégis, kezelni kell valahogy :) - Zsu
    return this.taskListType !== TasksComponent.SUPPLIER_TASK_LIST ? tasks.filter((item) => !!item.ecomId) : tasks;
  }

  initMenusByTask(task): void {
    if (task.sourceType === 'UPLOAD' && !this.menus.some((menu) => menu.id === 'source_settings')) {
      const menu = new MenuItem(
        this.translate.instant('TASKS.MENU_ITEMS_LABELS.SOURCE_SETTINGS'),
        'settings',
        null,
        'source_settings'
      );
      this.menus.push(menu);
    }
  }

  applyFilter(ecomId: number): void {
    this.selectedEcomFilter.next(ecomId);
  }

  openDialog(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.width = '600px';

    switch (this.taskListType) {
      case TasksComponent.DATAFEED_TASK_LIST:
        dialogConfig.data = {
          ecomId: true,
          allTasks: this.tasks,
        };
        break;
      case TasksComponent.EXPORT_TASK_LIST:
        dialogConfig.data = {
          ecomId: true,
          isExportTask: true,
          allTasks: this.tasks,
        };
        break;
      case TasksComponent.VARIANTS_UPDATE_TASK_LIST:
        dialogConfig.data = {
          ecomId: true,
          isVUTask: true,
          allTasks: this.tasks,
        };
        break;
    }

    const dialogRef = this.dialog.open(AddTaskModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      let params = null;

      // NEM VOLT ITT MAR IDEGEM SWITCH CASE NELKUL MEGOLDANI!!!!
      let taskType = '';
      switch (this.taskListType) {
        case TasksComponent.SUPPLIER_TASK_LIST:
          params = { name: result.name, taskType: 'public' };
          break;
        case TasksComponent.DATAFEED_TASK_LIST:
          switch (result.ecomType) {
            case ECOM_SHOPIFY:
              taskType = 'shopify_df';
              this.navigateURL = 'retailer/import-tasks/retailer-task-wizard';
              break;
            case ECOM_JUMPSELLER:
              taskType = 'jumpseller_df';
              this.navigateURL = 'retailer/import-tasks/jumpseller-df-task-wizard';
              break;
            case ECOM_SHOPRENTER:
              taskType = 'shoprenter_df';
              this.navigateURL = 'retailer/import-tasks/shoprenter-df-task-wizard';
              break;
            case ECOM_ECWID:
              taskType = 'ecwid_df';
              this.navigateURL = 'retailer/import-tasks/ecwid-df-task-wizard';
              break;
          }
          params = { name: result.name, ecomId: result.domain, taskType: taskType };
          break;
        case TasksComponent.EXPORT_TASK_LIST:
          if (result.template !== null) {
            result.fileType = null;
          }
          params = {
            name: result.name,
            ecomId: result.domain,
            outputType: result.fileType,
            settings: { fileType: result.fileType },
            fileSettings: result.fileType === 'CSV' ? result.csvSettings : null,
            template: result.template,
          };
          break;
        case TasksComponent.VARIANTS_UPDATE_TASK_LIST:
          switch (result.ecomType) {
            case ECOM_SHOPIFY:
              taskType = 'shopify_uv';
              this.navigateURL = '/retailer/variants-update-tasks/retailer-variants-update-task-wizard';
              break;
            case ECOM_JUMPSELLER:
              taskType = 'jumpseller_uv';
              this.navigateURL = '/retailer-jumpseller-vu-task-wizard';
              break;
            case ECOM_SHOPRENTER:
              this.navigateURL = '';
              break;
          }
          params = { name: result.name, ecomId: result.domain, taskType: taskType };
          break;
      }

      this.taskService.addTask(this.addTaskURL, params).subscribe(
        (res) => {
          if (!res.isSuccess()) {
            console.log('Error occurred at saving data');
          }

          res = res.getFirstData();
          let queryParams = null;
          // NEM VOLT ITT MAR IDEGEM SWITCH CASE NELKUL MEGOLDANI!!!!
          switch (this.taskListType) {
            case TasksComponent.SUPPLIER_TASK_LIST:
              queryParams = { task_id: res.taskId, task_name: res.name };
              break;
            case TasksComponent.DATAFEED_TASK_LIST:
              queryParams = {
                task_id: res.task.taskId,
                task_name: res.task.name,
                rc_id: res.rcatalog.id,
                ecom_id: res.rcatalog.ecomId,
              };
              break;
            case TasksComponent.EXPORT_TASK_LIST:
              queryParams = {
                task_id: res.taskId,
                ecom_id: result.domain,
                task_name: result.name,
              };
              break;
            case TasksComponent.VARIANTS_UPDATE_TASK_LIST:
              queryParams = {
                task_id: res.task.taskId,
                task_name: res.task.name,
                rc_id: res.rcatalog.id,
              };
              break;
          }

          this.router.navigate([this.navigateURL], { queryParams: queryParams });
        },
        (error) => console.log(error.errorMessage)
      );
    });
  }

  taskClicked(task): void {
    const queryParams = {};
    this.queryParamsName.forEach((qp) => {
      queryParams[qp.paramName] = task[qp.fieldName];
    });
    queryParams['isUpdate'] = true;
    let navigateUrl = this.navigateURL;
    switch (task.taskType) {
      case 'shopify_df':
        navigateUrl = RetailerCenterNavigation.DATAFEED_MANAGER.PRODUCT_UPLOAD_WIZARD_RETAILER_TASK;
        break;
      case 'jumpseller_df':
        navigateUrl = RetailerCenterNavigation.DATAFEED_MANAGER.PRODUCT_UPLOAD_WIZARD_JUMPSELLER;
        break;
      case 'shoprenter_df':
        navigateUrl = RetailerCenterNavigation.DATAFEED_MANAGER.PRODUCT_UPLOAD_WIZARD_SHOPRENTER;
        break;
      case 'ecwid_df':
        navigateUrl = RetailerCenterNavigation.DATAFEED_MANAGER.PRODUCT_UPLOAD_WIZARD_ECWID;
        break;
      case 'shopify_uv':
        navigateUrl = RetailerCenterNavigation.DATAFEED_MANAGER.VARIANTS_UPDATE_WIZARD;
        break;
      case 'jumpseller_uv':
        navigateUrl = RetailerCenterNavigation.DATAFEED_MANAGER.VARIANTS_UPDATE_WIZARD_JUMPSELLER;
        break;
    }
    this.router.navigate([navigateUrl], { queryParams: queryParams });
  }

  runClicked(task: TaskVO, updateFields?): void {
    if (this.taskListType === TasksComponent.SUPPLIER_TASK_LIST) {
      this.runTask(task, updateFields);
      return;
    }
    this.marketplaceEcomService
      .getDomainByEcomId(
        this.SHOP_SOURCE_CONNECTION_TYPES.includes(task.sourceType) ? task.destinationEcomId : task.ecomId
      )
      .subscribe((ecomVO) => {
        if (this.checkSyncCatalogPermission(ecomVO)) {
          this.runTask(task, updateFields);
          return;
        }

        this.handleNotPermittedTaskSync(this.taskListType);
      });
  }

  private handleNotPermittedTaskSync(taskListType: string): void {
    switch (taskListType) {
      case TasksComponent.EXPORT_TASK_LIST:
        this.authorizationService.openSubscribeDialog(
          'PLAN_UPSELL.SPECIAL_DESCRIPTION.PRODUCT_EXPORT',
          null,
          'datafeed'
        );
        break;
      case TasksComponent.DATAFEED_TASK_LIST:
        this.authorizationService.openSubscribeDialog(
          'PLAN_UPSELL.SPECIAL_DESCRIPTION.PRODUCT_UPLOAD',
          null,
          'datafeed'
        );
        break;
      case TasksComponent.VARIANTS_UPDATE_TASK_LIST:
        this.authorizationService.openSubscribeDialog(
          'PLAN_UPSELL.SPECIAL_DESCRIPTION.VARIANTS_UPDATE',
          null,
          'datafeed'
        );
        break;
    }
  }

  private checkSyncCatalogPermission(ecom: EcomVO): boolean {
    return this.authorizationService.hasPermission(Action.SYNC_CATALOG_DATAFEED, ecom);
  }

  runTask(task: TaskVO, updateFields?): void {
    task.isStillRunning = true;
    this.disableEnableEcomTasks(task.ecomId);
    this.initWatcher();
    this.taskService.runTask(this.runTaskURL, task.taskId, updateFields).subscribe(
      () => {
        this.notificationService.success(this.translate.instant('TASKS.TASK_STARTED'));
        this.taskWatcherService.addRunnedTaskToWatcher(task.taskId);
      },
      (error) => {
        this.disableEnableEcomTasks(task.ecomId, false);
        console.log(error);
        task.isStillRunning = false;
      }
    );
  }

  disableEnableEcomTasks(ecomId: number, disable = true): void {
    if (!ecomId) {
      return;
    }
    this.tasks.forEach((task) => {
      if (task.ecomId === ecomId) {
        task.isDisabled = disable;
      }
    });
  }

  updateTask(task, taskIActive: boolean): void {
    const taskId = task.taskId;
    const param = {
      taskId: taskId,
      isActive: taskIActive,
    };
    if (this.taskListType === TasksComponent.EXPORT_TASK_LIST) {
      param['export'] = true;
    }
    console.log(param);
    this.restService.post('TaskService/setActive', param).subscribe(
      () => {
        const temp: string = +taskIActive
          ? this.translate.instant('TASKS.ACTIVE')
          : this.translate.instant('TASKS.INACTIVE');
        this.notificationService.success(this.translate.instant('TASKS.SET_STATUS', { status: temp }));
        task.isActive = taskIActive ? '1' : '0';
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getDisplayedColumns(): string[] {
    // console.log('szar te senki');
    const isAdmin = this._userService.getCachedUser().role === USER_ROLES.GLOBAL_ADMIN;
    return this.columnDefinitions.filter((cd) => isAdmin || cd.showForSimpleUser).map((cd) => cd.def);
  }

  showHistory(taskId: number): void {
    const queryParams = { taskId: taskId };
    switch (this.taskListType) {
      case TasksComponent.EXPORT_TASK_LIST:
        queryParams['isExportTask'] = true;
        break;
      case TasksComponent.VARIANTS_UPDATE_TASK_LIST:
        queryParams['isVariantsUTask'] = true;
        break;
    }
    this.router.navigate(['/history'], { queryParams: queryParams });
  }

  toggleViewClick(param: boolean): void {
    this.toggleView = param;
    localStorage.setItem('taskView', param.valueOf().toString());
  }

  getDomainName(ecomId): string {
    const domain = this.domains.find((elem) => elem.id.toString() === ecomId.toString());
    return this.domains.length && !!domain ? domain.domain : null;
  }

  get userService(): UserService {
    return this._userService;
  }

  set userService(value: UserService) {
    this._userService = value;
  }

  ngOnDestroy(): void {
    this.taskWatcherService.stopWatcher();
    if (!!this.taskWatcherObsSubs) {
      this.taskWatcherObsSubs.unsubscribe();
    }
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  openVariantsLimitHelp(event): void {
    event.stopPropagation();
    window.open('https://help.syncee.com/en/articles/8953547-shopify-variant-creation-api-limits', '_blank');
  }

  private subscribeToOnboardingInit(): void {
    this.onboardService.initialized.pipe(takeUntil(this._unsubscribeAll), take(1)).subscribe(() => {
      this.onboardService.openOnboardDialog();
    });
  }

  private subscribeToEcoms(role: ROLE_TYPE): void {
    this.store
      .select(role === RolesEnum.RETAILER ? retailerEcomsSelector : supplierEcomsSelector)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((ecoms) => (this.domains = ecoms));
  }

  handlePreviousClicked(): void {
    this.page.next(this.page.value - 1);
  }

  handleNextClicked(): void {
    this.page.next(this.page.value + 1);
  }

  handleSizeChanged(number: number): void {
    this.size.next(number);
    this.page.next(0);
  }

  private getData(): void {
    combineLatest([
      this.forceReload.pipe(switchMap(this.fetcher.bind(this))),
      this.page,
      this.size,
      this.selectedEcomFilter,
    ])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(([tasks, page, size, ecomId]) => {
        const tasksFiltered = !!ecomId ? tasks.filter((task) => task.ecomId === ecomId) : tasks;
        this.dataSource = new MatTableDataSource(tasksFiltered.slice(page * size, (page + 1) * size));
        this.isLoading = false;
      });
    this.forceReload.next();
  }

  private getLtLg(): Observable<boolean> {
    return this.screenManagerService
      .observeBreakpoint(BreakPoint.lg)
      .pipe(this.screenManagerService.stateMatchesOperator());
  }
}

export class TaskVO {
  taskId: number;
  rcatalogId: number;
  isActive: '1' | '0';
  name: string;
  userId: string;
  uploaded: number;
  updated: number;
  deleted: number;
  notModified: number;
  status: string;
  date = '';
  nextTimeRun = '';
  remaining: number;
  isDisabled = false;
  isStillRunning = false;
  ecomId: number;
  sum = 0;
  resultCount: number;
  sourceType?: string;
  taskType?: string;
  destinationEcomId: number;
}

export interface TaskVoExtended extends TaskVO {
  progressbar?: TaskProgress[];
  taskBaseType?: TaskBaseType;
  destType?: string;
  sourceType?: string;
  taskType?: string;
  ecomType?: string;
  destinationType?: any;
}

type TaskBaseType = 'import' | 'export' | 'variantsUpdate';

// tslint:disable-next-line:no-empty-interface
interface TaskProgress extends HistoryIndicatorData {}

export class MenuItem {
  private _icon: string;
  private _label: string;
  private _method: (id: number) => void;
  private _id: string;

  constructor(label?: string, icon?: string, method?: (id: number) => void, id?: string) {
    this.label = label;
    this.icon = icon;
    this.method = method;
    this.id = id;
  }

  get icon(): string {
    return this._icon;
  }

  set icon(value: string) {
    this._icon = value;
  }

  get label(): string {
    return this._label;
  }

  set label(value: string) {
    this._label = value;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get method(): (id: number) => void {
    return this._method;
  }

  set method(value: (id: number) => void) {
    this._method = value;
  }
}
