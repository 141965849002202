import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(private title: Title, private meta: Meta) {}

  setCanonicalURL(url?: string): void {
    const link: HTMLLinkElement = document.querySelector("link[rel='canonical']") || document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', url || window.location.href);

    if (!document.head.contains(link)) {
      document.head.appendChild(link);
    }
  }

  updateTitle(newTitle: string): void {
    this.title.setTitle(newTitle);
  }

  updateMetaTags(metaTags: { name: string; content: string }[]): void {
    metaTags.forEach((metaTag) => {
      this.meta.updateTag(metaTag);
    });
  }
}
