<div class="w-100p" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="32px">
  <app-alert-multi
    class="w-100p"
    *ngIf="!(hasApprovedCatalogs$ | async)"
    type="warning"
    [messages]="['DASHBOARD.UNDER_REVIEW' | translate]"
  ></app-alert-multi>
  <app-supplier-numeric-card-list
    class="w-100p"
    [dateFrom]="dateFrom"
    [dateTo]="dateTo"
    [hidePercentage]="!dateFrom && !dateTo"
  ></app-supplier-numeric-card-list>
  <app-supplier-orders-chart
    class="w-100p"
    *ngIf="dataset.data.length > 0; else skeletonchartsalesoverview"
    [datasetsChanged]="datasetsChanged"
    [datasets]="[dataset]"
    [labels]="labels"
    [statisticTypes]="statisticTypes"
    [dateFrom]="dateFrom"
    [dateTo]="dateTo"
    [anyOrdersReceived]="anyOrdersReceived"
    [barChartOptions]="barChartOptions"
    (statisticTypeChange)="handleStatisticTypeChange($event)"
  >
  </app-supplier-orders-chart>
  <div class="w-100p gap-lg" fxLayout="row wrap" fxLayoutAlign="stretch stretch">
    <mat-card class="w-100p p-40" fxFlex="1 0 600px" fxFlex.lt-lg="1 0 0">
      <app-supplier-top-selling-products
        class="w-100p"
        [title]="'Top Products by Amount Sold'"
        [sortBy]="'productsSold,desc'"
        [dateFrom]="dateFrom"
        [dateTo]="dateTo"
      ></app-supplier-top-selling-products>
    </mat-card>
    <mat-card class="w-100p p-40" fxFlex="1 0 600px" fxFlex.lt-lg="1 0 0">
      <app-supplier-top-selling-products
        class="w-100p"
        [title]="'Top Products by Revenue'"
        [sortBy]="'revenue,desc'"
        [dateFrom]="dateFrom"
        [dateTo]="dateTo"
      ></app-supplier-top-selling-products>
    </mat-card>
  </div>
  <div class="w-100p gap-lg" fxLayout="row wrap" fxLayout.lt-lg="column" fxLayoutAlign="stretch">
    <mat-card class="w-100p p-40" fxFlex="2 0 600px" fxFlex.lt-lg="1 0 0">
      <app-top-retailers class="w-100p" [dateFrom]="dateFrom" [dateTo]="dateTo"></app-top-retailers>
    </mat-card>
    <app-overview
      *ngIf="overViewRecords; else skeletonoverview"
      [role]="RolesEnum.SUPPLIER"
      [currency]="currency$ | async"
      [overViewRecords]="overViewRecords"
      class="w-100p"
      fxFlex="1 0 400px"
      fxFlex.lt-lg="1 0 0"
    ></app-overview>
  </div>
</div>

<ng-template #noEcomCard>
  <app-no-ecom-card subTitleKey="DASHBOARD_NEW.NO_ECOM.DESCRIPTION"></app-no-ecom-card>
</ng-template>

<ng-template #topRightTemplate>
  <div fxLayout="row">
    <app-date-selector-button fxFlex="1 0 0" [(dateFrom)]="dateFrom" [(dateTo)]="dateTo"></app-date-selector-button>
  </div>
</ng-template>

<ng-template #skeletonchartsalesoverview>
  <app-skeleton-dashboard-sales-overview class="w-100p"></app-skeleton-dashboard-sales-overview>
</ng-template>

<ng-template #skeletonoverview>
  <app-skeleton-dashboard-box
    fxFlex="1 0 400px"
    title="{{ 'DASHBOARD.OVERVIEW' | translate }}"
  ></app-skeleton-dashboard-box>
</ng-template>
